import React from "react";
import PropTypes from "prop-types";

import Calendar from "@components/Calendar";

import InputWrapper from "./InputWrapper";

const InputDate = props => {
  let { name, value, placeholder, onChange, disabled, minDate, maxDate } =
    props;

  if (minDate && minDate !== null) {
    const startDate = minDate;
    startDate.setMonth(minDate.getMonth());
    startDate.setFullYear(minDate.getFullYear());

    props = { ...props, minDate: startDate };
  }

  if (maxDate && maxDate !== null) {
    const endDate = maxDate;
    endDate.setMonth(maxDate.getMonth());
    endDate.setFullYear(maxDate.getFullYear());

    props = { ...props, maxDate: endDate };
  }

  return (
    <InputWrapper {...props}>
      <Calendar
        curved
        showIcon
        mt={2}
        width={1}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        dateFormat="yy-mm-dd"
        disabled={disabled}
        {...props}
      />
    </InputWrapper>
  );
};

InputDate.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  formikProps: PropTypes.object,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
};

export default InputDate;
