import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { default as createSubscriptionRequestService } from "@app/services/subscription/createSubscriptionRequest";

import { useToast } from "@hooks/useToast";

import { loadActiveSubscriptionData, loadServicesData } from "@utils/common";

const useSubscriptionRequest = userInfo => {
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [activePackage, setActivePackage] = useState(null);
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setIsLoading(true);

        const [currentPackage, packagesList] = await Promise.all([
          loadActiveSubscriptionData(userInfo),
          loadServicesData(userInfo.customer.customer_id),
        ]);

        setActivePackage(currentPackage);
        setSubscriptionPackages(packagesList);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [messages.exception_error_message, showErrorToast, userInfo]);

  const createSubscriptionRequest = async (payload, onComplete) => {
    try {
      setIsLoading(true);

      await createSubscriptionRequestService(payload);

      setActivePackage(null);

      showSuccessToast(messages.message_subscription_request_created);

      onComplete?.();
    } catch (e) {
      showErrorToast(messages.error_try_later);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    activePackage,
    subscriptionPackages,
    createSubscriptionRequest,
  };
};

useSubscriptionRequest.propTypes = {
  userInfo: PropTypes.object,
};

export { useSubscriptionRequest };
