import { BUNDLE, ANALYSIS } from "@utils/constant";

const preparePayload = values => {
  const {
    service_type,
    data_source,
    analysis_type,
    child,
    add_on_products,
    ...otherProperties
  } = values;

  const payload = {
    ...otherProperties,
    add_on_products: add_on_products.map(({ code }) => code),
  };

  if (service_type === BUNDLE) {
    payload.child = child.map(({ code }) => code);
  } else if (service_type === ANALYSIS) {
    payload.data_source = data_source.map(source => source);
    payload.analysis_type = analysis_type;
  } else {
    payload.analysis_type = "manual";
  }

  return payload;
};

export { preparePayload };
