import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import Card from "@components/Card";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import GDPRInformationMessage from "@components/privacyPolicies/GDPRInformationMessage";
import PrivacyPolicyFooter from "@components/PrivacyPolicyFooter";

import { useToast } from "@hooks/useToast";

import { CandidateLoginHeader } from "./components";
import {
  useCandidateLoginSharedData,
  useCandidateLoginStepper,
} from "./context";

const CandidateLogin = () => {
  const { messages } = useIntl();
  const { candidate_id } = useParams();
  const {
    handleShareCandidateId,
    handleShareErrorHandler,
    handleShareSuccessHandler,
  } = useCandidateLoginSharedData();
  const { currentItem } = useCandidateLoginStepper();
  const { showSuccessToast, showErrorToast } = useToast();

  const handleSuccessLogin = useCallback(() => {
    showSuccessToast(messages.label_success);
  }, [messages.label_success, showSuccessToast]);

  const handleError = useCallback(
    error => {
      showErrorToast(error);
    },
    [showErrorToast]
  );

  useEffect(() => {
    handleShareCandidateId(candidate_id);
    handleShareErrorHandler(handleError);
    handleShareSuccessHandler(handleSuccessLogin);
  }, [
    candidate_id,
    handleError,
    handleShareCandidateId,
    handleShareErrorHandler,
    handleShareSuccessHandler,
    handleSuccessLogin,
  ]);

  return (
    <FullSizeDialog header={<CandidateLoginHeader />}>
      <Div
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Div
          display="flex"
          flexGrow={1}
          width={1}
          alignItems="center"
          justifyContent="center"
        >
          <Card width={1} maxWidth="464px">
            <Div
              px={[3, 3, 4, 4]}
              py="24px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gridGap={["24px", "24px", 4, 4]}
            >
              {currentItem}
            </Div>
          </Card>
        </Div>

        <Div display="flex" flexDirection="column">
          <GDPRInformationMessage />
          <PrivacyPolicyFooter />
        </Div>
      </Div>
    </FullSizeDialog>
  );
};

export default CandidateLogin;
