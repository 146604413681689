import {
  SVLANG,
  ROUTES,
  LOGIN,
  REGISTER_ACCOUNT,
  REGISTER_WITH_BANKID,
  ORDERS_DETAILS,
  CHECKOUT,
  ORDER_CONFIRMATION,
  SUBSCRIPTION_ORDER_CONFIRMATION,
  ORDER_RESULTS,
  SEARCH,
  RESET_PASSWORD,
  SET_PASSWORD,
  FORGOT_PASSWORD,
  HOME,
  USER_PROFILE,
  CUSTOMER_DETAILS,
  CUSTOMER_ORDERS,
  CUSTOMER_CREDITS,
  CUSTOMER_USERS,
  CUSTOMER_ANALYSIS,
  CUSTOMER_PRODUCTS,
  REGISTRATION_SUCCESSFUL,
  PAGE_NOT_FOUND,
  ADMIN_CUSTOMERS,
  ASSIGNMENT_DETAILS,
  ADMIN_ORDER_DETAILS,
  ADMIN_ASSIGNMENTS,
  ADMIN_ORDERS,
  ADMIN_ANALYSIS_LIST,
  ADMIN_USERS,
  ADMIN_ADMINS_LIST,
  ADMIN_CUSTOMERS_LIST,
  ORDERS,
  ANALYSIS_API,
  SUB_USERS,
  COMPANY_INFO,
  CREATE_BILLING_ACCOUNT,
  BILLING_ACCOUNT_INFO,
  ADMIN_COMPLETE_REGISTRATION,
  USER_COMPLETE_REGISTRATION,
  ADMIN_FORGOT_PASSWORD_SUCCESSFUL,
  USER_FORGOT_PASSWORD_SUCCESSFUL,
  ADMIN_REGISTRATION_COMPLETED,
  USER_REGISTRATION_COMPLETED,
  ADMIN_CREATE_CUSTOMER,
  ADMIN_MY_PROFILE,
  USER_CREDITS,
  SUBSCRIPTION_CHECKOUT,
  ACCESS_DENIED,
  UPGRADE_SUBSCRIPTION,
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_ORDERS,
  SUBSCRIPTION_ORDERS_MY_ACCOUNTS,
  SUBSCRIPTIONS,
  SUBSCRIPTION_LIST,
  ANALYS_DETAILS,
  REPORT_PDF,
  CANDIDATE,
  WATCH_LIST,
  ADMIN_CREATE_WATCHLIST,
  ADMIN_WATCHLIST,
  ADMIN_BILLING_ACCOUNT_INFO,
  ADMIN_WATCH_LIST_DETAIL,
  ADMIN_CUSTOMER_ACTIVITY_LOG,
  ADMIN_ACTIVITY_LOG_DETAILS,
  ADMIN_WATCH_LIST_OBJECT_DETAIL,
  ADMIN_CREATE_SUBSCRIPTION,
  ADMIN_CREATE_SERVICE,
  ADMIN_PRODUCTS_LIST,
  ADMIN_UPDATE_SUBSCRIPTION,
  ADMIN_PRODUCTS_SUBSCRIPTION_LIST,
  ADMIN_UPDATE_SERVICE_PRODUCT,
  ADMIN_UPDATE_SERVICE_PRODUCT_FORM_CONFIGURATION,
  ADMIN_UPDATE_SERVICE_PRODUCT_RULES,
  BILLING_ACCOUNTS_LIST,
  ADMIN_BILLING_ACCOUNTS_LIST,
  ADMIN_CREATE_BILLING_ACCOUNT,
  INTEGRATIONS_API_LIST,
  ADMIN_INTEGRATIONS_API_LIST,
  ADMIN_COUPONS_LIST,
  ADMIN_CREATE_COUPON,
  ADMIN_EDIT_COUPON,
  ERROR_404,
  ADMIN_CASES_LIST,
  ADMIN_EMAIL_LOG,
  ADMIN_EMAIL_LOG_DETAILS,
  ADMIN_CASE_DETAILS,
  ADMIN_CASE_SOURCE_DATA,
  ADMIN_CASE_REPORT,
  ADMIN_BILLING_ACCOUNT_ORDERS,
  ADMIN_CASE_CRIMES,
  ADMIN_CASE_EMAIL_THREAD,
  ADMIN_CASE_COMMENTS,
  ADMIN_PERMISSION_LIST,
  ADMIN_EMAIL_ACCOUNTS_LIST,
  ADMIN_COURTS_LIST,
  ADMIN_CRIMES_LIST,
  ADMIN_ACTIVITY_LOG_LIST,
  ADMIN_EMAIL_TEMPLATE_LIST,
  ADMIN_ROLES_LIST,
  ADMIN_CUSTOMER_COMMENTS,
  WIZARD_SEARCH,
  WIZARD_SELECT_SERVICES,
  WIZARD_CONFIRM_ORDER,
  WIZARD_ORDER_COMPLETED,
  ADMIN_WIZARD_SELECT_CUSTOMER,
  ADMIN_CASE_ACTIVITY_LOG,
  ADMIN_RULES_LIST,
  ADMIN_BLOCKED_CANDIDATES_LIST,
  ADMIN_UN_BILLED_SERVICES,
  ADMIN_UN_BILLED_SUBSCRIPTIONS,
  CANDIDATE_LOGIN,
} from "@utils/constant";
import Search from "@pages/user/search/Search";
import AdminLogin from "@pages/admin/AdminLogin";
import UserLogin from "@pages/user/UserLogin";
import AdminForgotPassword from "@pages/admin/AdminForgotPassword";
import UserForgotPassword from "@pages/user/UserForgotPassword";
import CandidateConsent from "@pages/candidate/CandidateConsent";
import { CandidateLogin } from "@pages/candidate";

import ResetPassword from "@pages/auth/resetPassword/ResetPassword";
import SetPassword from "@pages/auth/setPassword/SetPassword";
import PageNotFound from "@pages/shared/PageNotFound";
import CustomerDetails from "@pages/admin/customers/Details";
import BankRegister from "@pages/auth/registerAccount/bank/BankRegister";
import CustomerList from "@pages/admin/customers/list";
import UsersList from "@pages/admin/users/list";
import OrdersList from "@pages/admin/orders/list";
import AssignmentsList from "@pages/admin/assignments/list";
import AdminOrderDetails from "@pages/admin/orderDetails/AdminOrderDetails";
import AssignmentDetails from "@pages/admin/assignments/Details";
import OrderDetails from "@pages/user/orderDetails/UserOrderDetails";
import CompanyInfo from "@pages/user/userProfile/company/CompanyInfo";
import CustomerMyProfile from "@pages/user/myProfile/CustomerMyProfile";
import SubscriptionOrderConfirmation from "@pages/user/guest/SubscriptionOrderConfirmation";
import RegistrationSuccessful from "@pages/shared/RegistrationSuccessful";
import UserRegistrationCompleted from "@pages/user/UserRegistrationCompleted";
import AdminRegistrationCompleted from "@pages/admin/AdminRegistrationCompleted";
import UserForgotPasswordSuccessful from "@pages/user/UserForgotPasswordSuccessful";
import AdminForgotPasswordSuccessful from "@pages/admin/AdminForgotPasswordSuccessful";
import UserCompleteRegistration from "@pages/user/UserCompleteRegistration";
import AdminCompleteRegistration from "@pages/admin/AdminCompleteRegistration";
import SubUsers from "@pages/user/SubUsers";
import Report from "@pages/user/Report/Report";
import ReportPagePdfContent from "@components/Report/LegacyReport/ReportPagePdfContent";
import CreateCustomer from "@pages/admin/customers/CreateCustomer";
import CustomerOrdersList from "@pages/user/orders/list";
import CustomerWatchList from "@pages/user/watchlist/list";
import BillingAccountInfo from "@pages/user/billingAccountInfo/BillingAccountInfo";
import Credits from "@pages/user/userProfile/Credits";
import SubscriptionCheckout from "@pages/user/subscription/Checkout";
import AccessDenied from "@pages/user/subscription/accessDenied";
import UpgradeSubscription from "@pages/user/ManageSubscription/UpgradeSubscription";
import SubscriptionDetails from "@pages/admin/subscriptions/SubscriptionDetails";
import Subscription from "@pages/user/userProfile/subscription/Subscription";
import SubscriptionList from "@pages/admin/subscriptions/SubscriptionList";
import AnalysisList from "@pages/user/analysis/list";
import AdminAnalysisList from "@pages/admin/analysis/list";
import AdminAnalysDetails from "@pages/admin/analys/AdminAnalysDetails";
import CustomerCreateWatchList from "@pages/user/watchlist/CreateWatchlist";
import WatchListObjectOrderDetails from "@pages/shared/orderDetails/WatchListObjectOrderDetails";
import WatchlistDetail from "@pages/user/watchlist/WatchlistDetail";
import CreateSubscription from "@pages/admin/subscriptions/CreateSubscription";
import CreateService from "@pages/admin/services/CreateService";
import SubscriptionPackageList from "@pages/admin/products/tabs/SubscriptionPackageList";
import AdminProductsList from "@pages/admin/products/List";
import UpdateSubscription from "@pages/admin/subscriptions/UpdateSubscription";
import UpdateService from "@pages/admin/services/UpdateService";
import ExternalRedirectComponent from "../pages/auth/redirect/redirect";

import CreateBillingAccount from "@pages/user/createBillingAccount/CreateBillingAccount";
import UnBilledAccounts from "@pages/admin/billings/UnBilledAccounts";
import BillingAccounts from "@pages/admin/billings/BillingAccounts";
import Invoices from "@pages/admin/billings/Invoices";
import Error404 from "@pages/admin/error404/error404";

import AdminCreateCoupon from "@pages/admin/coupons/CreateCoupon";
import AdminUpdateCoupon from "@pages/admin/coupons/UpdateCoupon";
import AdminCasesList from "@pages/admin/cases/List";
import AdminCaseDetails from "@pages/admin/caseDetails/CaseDetails";
import CrimesDialog from "@pages/admin/caseDetails/crimes/CrimesDialog";
import EmailsDialog from "@pages/admin/caseDetails/emails/EmailsDialog";
import UpdateEmailLog from "@pages/admin/cases/tabs/components/UpdateEmailLog";

import AdminEmailAccountsList from "@pages/admin/configrations/EmailsList";
import AdminRolesList from "@pages/admin/configrations/UserRightsList";
import AdminConfigurationCasesList from "@pages/admin/configrations/CasesList";

import CreateOrderWizard from "@pages/shared/CreateOrderWizard/CreateOrderWizard";
import Checkout from "@pages/shared/CreateOrderWizard/Checkout";
import OrderConfirmation from "@pages/shared/CreateOrderWizard/OrderConfirmation";

import ActivityLogDetails from "@pages/admin/activityLogs/Details";
import AdminBillingAccountInfo from "@pages/admin/billings/AdminBillingAccountInfo";
import AdminBlockedCandidatesList from "@pages/admin/blockedCandidates/list";
import AdminMyProfile from "@pages/admin/myProfile/AdminMyProfile";

export const RoutesList = [
  {
    name: HOME,
    url: ROUTES.HOME.URL,
    title: "HOME",
    component: [UserLogin],
  },
  {
    name: LOGIN,
    url: ROUTES.LOGIN.URL,
    title: SVLANG.login,
    component: [UserLogin],
  },
  {
    name: FORGOT_PASSWORD,
    url: ROUTES.FORGOT_PASSWORD.URL,
    title: SVLANG.title_forgot_password,
    component: [UserForgotPassword],
  },
  {
    name: CANDIDATE,
    url: ROUTES.CANDIDATE.URL,
    title: SVLANG.title_candidate,
    component: [CandidateConsent],
  },
  {
    name: CANDIDATE_LOGIN,
    url: ROUTES.CANDIDATE_LOGIN.URL,
    title: SVLANG.login,
    component: [CandidateLogin],
  },
  {
    name: RESET_PASSWORD,
    url: ROUTES.RESET_PASSWORD.URL,
    title: SVLANG.label_set_password,
    component: [ResetPassword],
  },
  {
    name: SET_PASSWORD,
    url: ROUTES.SET_PASSWORD.URL,
    title: SVLANG.label_set_password,
    component: [SetPassword],
  },
  {
    name: REGISTER_ACCOUNT,
    url: ROUTES.REGISTER_ACCOUNT.URL,
    title: SVLANG.title_registration,
    component: [ExternalRedirectComponent],
  },
  {
    name: SEARCH,
    url: ROUTES.SEARCH.URL,
    title: SVLANG.search_text,
    component: [Search],
    showSidemenu: true,
    authenticatedUser: true,
  },
  {
    name: USER_PROFILE,
    url: ROUTES.USER_PROFILE.URL,
    title: SVLANG.title_my_profile,
    component: [CustomerMyProfile],
    authenticatedUser: true,
  },
  {
    name: LOGIN,
    url: ROUTES.ADMIN_LOGIN.URL,
    title: SVLANG.login,
    component: [AdminLogin],
    isAdmin: ROUTES.ADMIN_LOGIN.IS_ADMIN,
  },
  {
    name: FORGOT_PASSWORD,
    url: ROUTES.ADMIN_FORGOT_PASSWORD.URL,
    title: SVLANG.title_forgot_password,
    component: [AdminForgotPassword],
    isAdmin: ROUTES.ADMIN_RESET_PASSWORD.IS_ADMIN,
  },
  {
    name: RESET_PASSWORD,
    url: ROUTES.ADMIN_RESET_PASSWORD.URL,
    title: SVLANG.label_set_password,
    component: [ResetPassword],
    isAdmin: ROUTES.ADMIN_RESET_PASSWORD.IS_ADMIN,
  },
  {
    name: REGISTRATION_SUCCESSFUL,
    url: ROUTES.REGISTRATION_SUCCESSFUL.URL,
    title: SVLANG.title_registration,
    component: [RegistrationSuccessful],
  },
  {
    name: CUSTOMER_DETAILS,
    url: ROUTES.CUSTOMER_DETAILS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_DETAILS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_ORDERS,
    url: ROUTES.CUSTOMER_ORDERS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_ORDERS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_USERS,
    url: ROUTES.CUSTOMER_USERS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_USERS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_CREDITS,
    url: ROUTES.CUSTOMER_CREDITS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_CREDITS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_ANALYSIS,
    url: ROUTES.CUSTOMER_ANALYSIS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_ANALYSIS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_PRODUCTS,
    url: ROUTES.CUSTOMER_PRODUCTS.URL,
    title: SVLANG.label_products,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_PRODUCTS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CUSTOMER_COMMENTS,
    url: ROUTES.ADMIN_CUSTOMER_COMMENTS.URL,
    title: SVLANG.label_comments,
    component: [CustomerDetails],
    isAdmin: ROUTES.ADMIN_CUSTOMER_COMMENTS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: REGISTER_WITH_BANKID,
    url: ROUTES.REGISTER_WITH_BANKID.URL,
    title: SVLANG.title_customers,
    component: [BankRegister],
  },
  {
    name: ASSIGNMENT_DETAILS,
    url: ROUTES.ASSIGNMENT_DETAILS.URL,
    title: SVLANG.title_assignments,
    component: [AssignmentDetails],
    isAdmin: ROUTES.ASSIGNMENT_DETAILS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: PAGE_NOT_FOUND,
    url: ROUTES.PAGE_NOT_FOUND.URL,
    title: SVLANG.title_pagenotfound,
    component: [PageNotFound],
  },
  {
    name: ADMIN_CUSTOMERS,
    url: ROUTES.ADMIN_CUSTOMERS.URL,
    title: SVLANG.title_customers,
    component: [CustomerList],
    isAdmin: ROUTES.ADMIN_CUSTOMERS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CREATE_CUSTOMER,
    url: ROUTES.ADMIN_CREATE_CUSTOMER.URL,
    title: SVLANG.title_customers,
    component: [CreateCustomer],
    isAdmin: ROUTES.ADMIN_CREATE_CUSTOMER.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_USERS,
    url: ROUTES.ADMIN_USERS_LIST.URL,
    title: SVLANG.title_users,
    component: [UsersList],
    isAdmin: ROUTES.ADMIN_USERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ADMINS_LIST,
    url: ROUTES.ADMIN_ADMINS_LIST.URL,
    title: SVLANG.title_users,
    component: [UsersList],
    isAdmin: ROUTES.ADMIN_ADMINS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CUSTOMERS_LIST,
    url: ROUTES.ADMIN_CUSTOMERS_LIST.URL,
    title: SVLANG.title_users,
    component: [UsersList],
    isAdmin: ROUTES.ADMIN_CUSTOMERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ORDERS,
    url: ROUTES.ADMIN_ORDERS_LIST.URL,
    title: SVLANG.title_orders,
    component: [OrdersList],
    isAdmin: ROUTES.ADMIN_ORDERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ASSIGNMENTS,
    url: ROUTES.ADMIN_ASSIGNMENTS_LIST.URL,
    title: SVLANG.title_assignments,
    component: [AssignmentsList],
    isAdmin: ROUTES.ADMIN_ASSIGNMENTS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ORDER_DETAILS,
    url: ROUTES.ADMIN_ORDER_DETAILS.URL,
    title: SVLANG.title_orders,
    component: [AdminOrderDetails],
    isAdmin: ROUTES.ADMIN_ORDER_DETAILS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ORDERS_DETAILS,
    url: ROUTES.ORDERS_DETAILS.URL,
    title: SVLANG.title_orders,
    component: [OrderDetails],
    authenticatedUser: true,
  },
  {
    name: COMPANY_INFO,
    url: ROUTES.COMPANY_INFO.URL,
    title: SVLANG.label_company_info,
    component: [CompanyInfo],
    authenticatedUser: true,
  },
  {
    name: CREATE_BILLING_ACCOUNT,
    url: ROUTES.CREATE_BILLING_ACCOUNT.URL,
    title: SVLANG.title_create_billing_account,
    component: [CreateBillingAccount],
    authenticatedUser: true,
  },
  {
    name: BILLING_ACCOUNT_INFO,
    url: ROUTES.BILLING_ACCOUNT_INFO.URL,
    title: SVLANG.title_billing_account_info,
    component: [BillingAccountInfo],
    authenticatedUser: true,
  },
  {
    name: SUB_USERS,
    url: ROUTES.SUB_USERS_LIST.URL,
    title: SVLANG.title_user_info,
    component: [SubUsers],
    authenticatedUser: true,
  },
  {
    name: ORDERS,
    url: ROUTES.ORDERS_LIST.URL,
    title: SVLANG.title_orders,
    component: [CustomerOrdersList],
    authenticatedUser: true,
  },
  {
    name: WATCH_LIST,
    url: ROUTES.USER_WATCH_LIST.URL,
    title: SVLANG.menu_watchlist,
    component: [CustomerWatchList],
    authenticatedUser: true,
  },
  {
    name: WATCH_LIST,
    url: ROUTES.NEW_WATCH_LIST.URL,
    title: SVLANG.menu_watchlist,
    component: [CustomerCreateWatchList],
    authenticatedUser: true,
  },
  {
    name: WATCH_LIST,
    url: ROUTES.WATCH_LIST_DETAILS.URL,
    title: SVLANG.menu_watchlist,
    component: [WatchlistDetail],
    authenticatedUser: true,
  },
  {
    name: CHECKOUT,
    url: ROUTES.CHECKOUT.URL,
    title: SVLANG.title_checkout,
    component: [Checkout],
  },
  {
    name: SUBSCRIPTION_CHECKOUT,
    url: ROUTES.SUBSCRIPTION_CHECKOUT.URL,
    title: SVLANG.title_checkout,
    component: [SubscriptionCheckout],
  },
  {
    name: ORDER_CONFIRMATION,
    url: ROUTES.ORDER_CONFIRMATION.URL,
    title: SVLANG.title_order_confirmation,
    component: [OrderConfirmation],
  },
  {
    name: SUBSCRIPTION_ORDER_CONFIRMATION,
    url: ROUTES.SUBSCRIPTION_ORDER_CONFIRMATION.URL,
    title: SVLANG.title_subscription_order_confirmation,
    component: [SubscriptionOrderConfirmation],
  },
  {
    name: USER_REGISTRATION_COMPLETED,
    url: ROUTES.USER_REGISTRATION_COMPLETED.URL,
    title: SVLANG.title_registration_completed,
    component: [UserRegistrationCompleted],
  },
  {
    name: ADMIN_REGISTRATION_COMPLETED,
    url: ROUTES.ADMIN_REGISTRATION_COMPLETED.URL,
    title: SVLANG.title_registration_completed,
    component: [AdminRegistrationCompleted],
    isAdmin: ROUTES.ADMIN_REGISTRATION_COMPLETED.IS_ADMIN,
  },
  {
    name: USER_FORGOT_PASSWORD_SUCCESSFUL,
    url: ROUTES.USER_FORGOT_PASSWORD_SUCCESSFUL.URL,
    title: SVLANG.title_forgot_password_successful,
    component: [UserForgotPasswordSuccessful],
  },
  {
    name: ADMIN_FORGOT_PASSWORD_SUCCESSFUL,
    url: ROUTES.ADMIN_FORGOT_PASSWORD_SUCCESSFUL.URL,
    title: SVLANG.title_forgot_password_successful,
    component: [AdminForgotPasswordSuccessful],
    isAdmin: ROUTES.ADMIN_FORGOT_PASSWORD_SUCCESSFUL.IS_ADMIN,
  },
  {
    name: USER_COMPLETE_REGISTRATION,
    url: ROUTES.USER_COMPLETE_REGISTRATION.URL,
    title: SVLANG.title_complete_registration,
    component: [UserCompleteRegistration],
  },
  {
    name: ADMIN_COMPLETE_REGISTRATION,
    url: ROUTES.ADMIN_COMPLETE_REGISTRATION.URL,
    title: SVLANG.title_complete_registration,
    component: [AdminCompleteRegistration],
    isAdmin: ROUTES.ADMIN_COMPLETE_REGISTRATION.IS_ADMIN,
  },
  {
    name: ORDER_RESULTS,
    url: ROUTES.ORDER_RESULTS.URL,
    title: SVLANG.title_report,
    component: [Report],
  },
  {
    name: REPORT_PDF,
    url: ROUTES.REPORT_PDF.URL,
    title: SVLANG.title_report,
    component: [ReportPagePdfContent],
  },
  {
    name: ADMIN_MY_PROFILE,
    url: ROUTES.ADMIN_MY_PROFILE.URL,
    title: SVLANG.title_my_profile,
    component: [AdminMyProfile],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_MY_PROFILE.IS_ADMIN,
  },
  {
    name: USER_CREDITS,
    url: ROUTES.USER_CREDITS.URL,
    title: SVLANG.title_credits,
    component: [Credits],
    authenticatedUser: true,
  },
  {
    name: ACCESS_DENIED,
    url: ROUTES.ACCESSDENIED.URL,
    title: SVLANG.title_credits,
    component: [AccessDenied],
    authenticatedUser: false,
  },
  {
    name: UPGRADE_SUBSCRIPTION,
    url: ROUTES.UPGRADE_SUBSCRIPTION.URL,
    title: SVLANG.title_credits,
    component: [UpgradeSubscription],
    authenticatedUser: false,
  },
  {
    name: SUBSCRIPTION_DETAILS,
    url: ROUTES.SUBSCRIPTION_DETAILS.URL,
    title: SVLANG.title_credits,
    component: [SubscriptionDetails],
    isAdmin: ROUTES.ADMIN_USERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: SUBSCRIPTION_ORDERS_MY_ACCOUNTS,
    url: ROUTES.SUBSCRIPTION_ORDERS_MY_ACCOUNTS.URL,
    title: SVLANG.title_credits,
    component: [Subscription],
    authenticatedUser: true,
  },
  {
    name: SUBSCRIPTION_ORDERS,
    url: ROUTES.SUBSCRIPTION_ORDERS.URL,
    title: SVLANG.title_credits,
    component: [SubscriptionDetails],
    isAdmin: ROUTES.ADMIN_USERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: SUBSCRIPTIONS,
    url: ROUTES.SUBSCRIPTION.URL,
    title: SVLANG.title_credits,
    component: [Subscription],
    authenticatedUser: true,
  },
  {
    name: SUBSCRIPTION_LIST,
    url: ROUTES.SUBSCRIPTION_LIST.URL,
    title: SVLANG.title_credits,
    component: [SubscriptionList],
    isAdmin: ROUTES.ADMIN_USERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ANALYSIS_API,
    url: ROUTES.ALLANALYSIS_LIST.URL,
    title: SVLANG.title_credits,
    component: [AnalysisList],
    authenticatedUser: true,
  },
  {
    name: ANALYS_DETAILS,
    url: ROUTES.ANALYS_DETAILS.URL,
    title: SVLANG.analys_details,
    component: [AdminAnalysDetails],
    isAdmin: ROUTES.ADMIN_USERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ANALYSIS_LIST,
    url: ROUTES.ADMIN_ANALYSIS_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminAnalysisList],
    isAdmin: ROUTES.ADMIN_ANALYSIS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ORDERS_DETAILS,
    url: ROUTES.WATCH_LIST_OBJECT_DETAIL.URL,
    title: SVLANG.title_orders,
    component: [WatchListObjectOrderDetails],
    authenticatedUser: true,
  },
  {
    name: ADMIN_CREATE_WATCHLIST,
    url: ROUTES.ADMIN_CREATE_WATCH_LIST.URL,
    title: SVLANG.menu_watchlist,
    component: [CustomerCreateWatchList],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_CREATE_WATCH_LIST.IS_ADMIN,
  },
  {
    name: ADMIN_WATCHLIST,
    url: ROUTES.ADMIN_WATCHLIST.URL,
    title: SVLANG.menu_watchlist,
    component: [CustomerDetails],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_WATCHLIST.IS_ADMIN,
  },
  {
    name: ADMIN_BILLING_ACCOUNT_INFO,
    url: ROUTES.ADMIN_BILLING_ACCOUNT_INFO.URL,
    title: SVLANG.title_billing_account_info,
    component: [AdminBillingAccountInfo],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_BILLING_ACCOUNT_INFO.IS_ADMIN,
  },
  {
    name: ADMIN_BILLING_ACCOUNT_ORDERS,
    url: ROUTES.ADMIN_BILLING_ACCOUNT_ORDERS.URL,
    title: SVLANG.title_billing_account_info,
    component: [AdminBillingAccountInfo],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_BILLING_ACCOUNT_ORDERS.IS_ADMIN,
  },
  {
    name: ADMIN_WATCH_LIST_DETAIL,
    url: ROUTES.ADMIN_WATCH_LIST_DETAIL.URL,
    title: SVLANG.menu_watchlist,
    component: [WatchlistDetail],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_WATCH_LIST_DETAIL.IS_ADMIN,
  },
  {
    name: ADMIN_CUSTOMER_ACTIVITY_LOG,
    url: ROUTES.ADMIN_CUSTOMER_ACTIVITY_LOG.URL,
    title: SVLANG.activity_log,
    component: [CustomerDetails],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_CUSTOMER_ACTIVITY_LOG.IS_ADMIN,
  },
  {
    name: ADMIN_ACTIVITY_LOG_DETAILS,
    url: ROUTES.ADMIN_ACTIVITY_LOG_DETAILS.URL,
    title: SVLANG.activity_log,
    component: [ActivityLogDetails],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_ACTIVITY_LOG_DETAILS.IS_ADMIN,
  },
  {
    name: ADMIN_WATCH_LIST_OBJECT_DETAIL,
    url: ROUTES.ADMIN_WATCH_LIST_OBJECT_DETAIL.URL,
    title: SVLANG.title_orders,
    component: [WatchListObjectOrderDetails],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_WATCH_LIST_OBJECT_DETAIL.IS_ADMIN,
  },
  {
    name: ADMIN_CREATE_SUBSCRIPTION,
    url: ROUTES.ADMIN_CREATE_SUBSCRIPTION.URL,
    title: SVLANG.title_orders,
    component: [CreateSubscription],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_CREATE_SUBSCRIPTION.IS_ADMIN,
  },
  {
    name: ADMIN_CREATE_SERVICE,
    url: ROUTES.ADMIN_CREATE_SERVICE.URL,
    title: SVLANG.title_orders,
    component: [CreateService],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_CREATE_SERVICE.IS_ADMIN,
  },
  {
    name: ADMIN_PRODUCTS_LIST,
    url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    title: SVLANG.title_orders,
    component: [AdminProductsList],
    isAdmin: ROUTES.ADMIN_PRODUCTS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_PRODUCTS_SUBSCRIPTION_LIST,
    url: ROUTES.ADMIN_PRODUCTS_SUBSCRIPTION_LIST.URL,
    title: SVLANG.title_orders,
    component: [SubscriptionPackageList],
    isAdmin: ROUTES.ADMIN_PRODUCTS_SUBSCRIPTION_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_UPDATE_SUBSCRIPTION,
    url: ROUTES.ADMIN_UPDATE_SUBSCRIPTION.URL,
    title: SVLANG.title_orders,
    component: [UpdateSubscription],
    isAdmin: ROUTES.ADMIN_UPDATE_SUBSCRIPTION.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_UPDATE_SERVICE_PRODUCT,
    url: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT.URL,
    title: SVLANG.title_orders,
    component: [UpdateService],
    isAdmin: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_UPDATE_SERVICE_PRODUCT_FORM_CONFIGURATION,
    url: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT_FORM_CONFIGURATION.URL,
    title: SVLANG.title_orders,
    component: [UpdateService],
    isAdmin: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT_FORM_CONFIGURATION.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_UPDATE_SERVICE_PRODUCT_RULES,
    url: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT_RULES.URL,
    title: SVLANG.title_orders,
    component: [UpdateService],
    isAdmin: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT_RULES.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: BILLING_ACCOUNTS_LIST,
    url: ROUTES.BILLING_ACCOUNTS_LIST.URL,
    title: SVLANG.label_company_info,
    component: [CompanyInfo],
    authenticatedUser: true,
  },
  {
    name: ADMIN_BILLING_ACCOUNTS_LIST,
    url: ROUTES.ADMIN_BILLING_ACCOUNTS_LIST.URL,
    title: SVLANG.title_orders,
    component: [CustomerDetails],
    isAdmin: ROUTES.ADMIN_BILLING_ACCOUNTS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CREATE_BILLING_ACCOUNT,
    url: ROUTES.ADMIN_CREATE_BILLING_ACCOUNT.URL,
    title: SVLANG.title_create_billing_account,
    component: [CreateBillingAccount],
    isAdmin: true,
    authenticatedUser: true,
  },
  {
    name: INTEGRATIONS_API_LIST,
    url: ROUTES.INTEGRATIONS_API_LIST.URL,
    title: SVLANG.header_api,
    component: [CompanyInfo],
    showSidemenu: true,
    authenticatedUser: true,
  },
  {
    name: ADMIN_INTEGRATIONS_API_LIST,
    url: ROUTES.ADMIN_INTEGRATIONS_API_LIST.URL,
    title: SVLANG.header_api,
    component: [CustomerDetails],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_INTEGRATIONS_API_LIST.IS_ADMIN,
  },
  {
    name: "ADMIN_INVOICE_ACCOUNTS",
    url: ROUTES.ADMIN_INVOICE_ACCOUNTS.URL,
    title: SVLANG.title_billing,
    component: [Invoices],
    isAdmin: ROUTES.ADMIN_INVOICE_ACCOUNTS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: "ADMIN_BILLING_ACCOUNTS",
    url: ROUTES.ADMIN_BILLING_ACCOUNTS.URL,
    title: SVLANG.title_billing,
    component: [BillingAccounts],
    isAdmin: ROUTES.ADMIN_BILLING_ACCOUNTS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_COUPONS_LIST,
    url: ROUTES.ADMIN_COUPONS_LIST.URL,
    title: SVLANG.title_coupons,
    component: [AdminProductsList],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_COUPONS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CREATE_COUPON,
    url: ROUTES.ADMIN_CREATE_COUPON.URL,
    title: SVLANG.title_create_coupon,
    component: [AdminCreateCoupon],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CREATE_COUPON.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_EDIT_COUPON,
    url: ROUTES.ADMIN_EDIT_COUPON.URL,
    title: SVLANG.title_edit_coupon,
    component: [AdminUpdateCoupon],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_EDIT_COUPON.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ERROR_404,
    url: ROUTES.ERROR_404.URL,
    title: SVLANG.title_create_billing_account,
    component: [Error404],
  },
  {
    name: ADMIN_CASES_LIST,
    url: ROUTES.ADMIN_CASES_LIST.URL,
    title: SVLANG.title_cases,
    component: [AdminCasesList],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CASES_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_EMAIL_LOG,
    url: ROUTES.ADMIN_EMAIL_LOG.URL,
    title: SVLANG.title_email_log,
    component: [AdminCasesList],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_EMAIL_LOG.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_EMAIL_LOG_DETAILS,
    url: ROUTES.ADMIN_EMAIL_LOG_DETAILS.URL,
    title: SVLANG.title_email_log,
    component: [UpdateEmailLog],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_EMAIL_LOG_DETAILS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CASE_DETAILS,
    url: ROUTES.ADMIN_CASE_DETAILS.URL,
    title: SVLANG.title_email_log,
    component: [AdminCaseDetails],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CASE_DETAILS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CASE_SOURCE_DATA,
    url: ROUTES.ADMIN_CASE_SOURCE_DATA.URL,
    title: SVLANG.title_email_log,
    component: [AdminCaseDetails],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CASE_SOURCE_DATA.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CASE_COMMENTS,
    url: ROUTES.ADMIN_CASE_COMMENTS.URL,
    title: SVLANG.title_report,
    component: [AdminCaseDetails],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CASE_COMMENTS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CASE_ACTIVITY_LOG,
    url: ROUTES.ADMIN_CASE_ACTIVITY_LOG.URL,
    title: SVLANG.title_report,
    component: [AdminCaseDetails],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CASE_ACTIVITY_LOG.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CASE_REPORT,
    url: ROUTES.ADMIN_CASE_REPORT.URL,
    title: SVLANG.title_report,
    component: [AdminCaseDetails],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CASE_REPORT.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CASE_CRIMES,
    url: ROUTES.ADMIN_CASE_CRIMES.URL,
    title: SVLANG.title_crimes,
    component: [CrimesDialog],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CASE_CRIMES.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CASE_EMAIL_THREAD,
    url: ROUTES.ADMIN_CASE_EMAIL_THREAD.URL,
    title: SVLANG.title_crimes,
    component: [EmailsDialog],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CASE_EMAIL_THREAD.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_PERMISSION_LIST,
    url: ROUTES.ADMIN_PERMISSION_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminRolesList],
    isAdmin: ROUTES.ADMIN_PERMISSION_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_EMAIL_ACCOUNTS_LIST,
    url: ROUTES.ADMIN_EMAIL_ACCOUNTS_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminEmailAccountsList],
    isAdmin: ROUTES.ADMIN_EMAIL_ACCOUNTS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_COURTS_LIST,
    url: ROUTES.ADMIN_COURTS_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminConfigurationCasesList],
    isAdmin: ROUTES.ADMIN_COURTS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CRIMES_LIST,
    url: ROUTES.ADMIN_CRIMES_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminConfigurationCasesList],
    isAdmin: ROUTES.ADMIN_CRIMES_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ACTIVITY_LOG_LIST,
    url: ROUTES.ADMIN_ACTIVITY_LOG_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminConfigurationCasesList],
    isAdmin: ROUTES.ADMIN_ACTIVITY_LOG_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_EMAIL_TEMPLATE_LIST,
    url: ROUTES.ADMIN_EMAIL_TEMPLATE_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminEmailAccountsList],
    isAdmin: ROUTES.ADMIN_EMAIL_TEMPLATE_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ROLES_LIST,
    url: ROUTES.ADMIN_ROLES_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminRolesList],
    isAdmin: ROUTES.ADMIN_ROLES_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: WIZARD_SEARCH,
    url: ROUTES.WIZARD_SEARCH.URL,
    title: SVLANG.search_text,
    component: [CreateOrderWizard],
    authenticatedUser: true,
  },
  {
    name: WIZARD_SELECT_SERVICES,
    url: ROUTES.WIZARD_SELECT_SERVICES.URL,
    title: SVLANG.title_select_services,
    component: [CreateOrderWizard],
    authenticatedUser: true,
  },
  {
    name: WIZARD_CONFIRM_ORDER,
    url: ROUTES.WIZARD_CONFIRM_ORDER.URL,
    title: SVLANG.label_confirm_order,
    component: [CreateOrderWizard],
    authenticatedUser: true,
  },
  {
    name: WIZARD_ORDER_COMPLETED,
    url: ROUTES.WIZARD_ORDER_COMPLETED.URL,
    title: SVLANG.label_order_completed,
    component: [CreateOrderWizard],
    authenticatedUser: true,
  },
  {
    name: ADMIN_WIZARD_SELECT_CUSTOMER,
    url: ROUTES.ADMIN_WIZARD_SELECT_CUSTOMER.URL,
    title: SVLANG.label_choose_customer,
    component: [CreateOrderWizard],
    isAdmin: true,
    authenticatedUser: true,
  },
  {
    name: ADMIN_RULES_LIST,
    url: ROUTES.ADMIN_RULES_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminConfigurationCasesList],
    isAdmin: ROUTES.ADMIN_RULES_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_BLOCKED_CANDIDATES_LIST,
    url: ROUTES.ADMIN_BLOCKED_CANDIDATES_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminBlockedCandidatesList],
    isAdmin: ROUTES.ADMIN_BLOCKED_CANDIDATES_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_UN_BILLED_SERVICES,
    url: ROUTES.ADMIN_UN_BILLED_SERVICES.URL,
    title: SVLANG.label_services,
    component: [UnBilledAccounts],
    isAdmin: ROUTES.ADMIN_UN_BILLED_SERVICES.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_UN_BILLED_SUBSCRIPTIONS,
    url: ROUTES.ADMIN_UN_BILLED_SUBSCRIPTIONS.URL,
    title: SVLANG.title_subscriptions,
    component: [UnBilledAccounts],
    isAdmin: ROUTES.ADMIN_UN_BILLED_SUBSCRIPTIONS.IS_ADMIN,
    authenticatedUser: true,
  },
];

export default RoutesList;
