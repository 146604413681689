import Axios from "@app/api/axios";
import { BLOCKED_CANDIDATES } from "@utils/constant";

export const deleteBlockedCandidateService = ({ ssn }) => {
    
  return Axios.delete(`${BLOCKED_CANDIDATES}/${ssn}`).then(
    (response) => response,
  );
};

export default deleteBlockedCandidateService;
