import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { useFormik } from "formik";

import customerService from "@app/services/customers/customerService";
import { fetchAllProducts } from "@app/services/services/fetchProductById";
import createSubscription from "@app/services/subscription/createSubscription";
import userService from "@app/services/users/userService";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Checkbox from "@components/Checkbox";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import { H5 } from "@components/Heading";
import { InputAutocomplete, InputDate, InputDropdown } from "@components/Input";
import InputText from "@components/InputText";
import ProgressSpinner from "@components/ProgressSpinner";
import { TextMediumWeight } from "@components/Text";

import { useToast } from "@hooks/useToast";

import BillingInformation from "@pages/shared/CreateOrderWizard/orderSummary/BillingInformation";
import CardWrapper from "@pages/admin/users/components/CardWrapper";

import { SPECIAL_PACKAGE, SUBSCRIPTION_STATUS } from "@utils/constant";
import { PRODUCT_TYPE, USER_TYPE } from "@utils/enum";

import { CreateCouponSchema } from "./validation";

const INITIAL_VALUES = {
  customer: null,
  user: null,
  package: null,
  price: null,
  availableCredits: null,
  totalCredits: null,
  status: null,
  renewal: null,
  renewalDate: null,
  email_notification: true,
  billing_account_id: null,
  activationDate: null,
};

const SUBSCRIPTION_STATUS_OPTIONS = [
  {
    label: <FormattedMessage id="active" />,
    value: SUBSCRIPTION_STATUS.ACTIVE,
  },
  {
    label: <FormattedMessage id="stopped_label" />,
    value: SUBSCRIPTION_STATUS.STOPPED,
  },
  {
    label: <FormattedMessage id="terminated_label" />,
    value: SUBSCRIPTION_STATUS.TERMINATED,
  },
];

const RENEWAL_OPTIONS = [
  {
    label: <FormattedMessage id="yes_label" />,
    value: true,
  },
  {
    label: <FormattedMessage id="no_label" />,
    value: false,
  },
];

const CreateSubscription = ({ onClose }) => {
  const { messages } = useIntl();
  const { email = "" } = useSelector(state => state.authReducer.userInfo) ?? {};
  const { showErrorToast, showSuccessToast } = useToast();

  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const createSubscriptionByAdmin = useCallback(
    async values => {
      try {
        setIsLoading(true);

        const {
          customer: { id: customerId } = {},
          package: { name: packageName, id: productId } = {},
          user: { id: userId } = {},
          availableCredits,
          totalCredits,
          price,
          email_notification,
          renewal,
          status,
          renewalDate,
          activationDate,
          billing_account_id,
        } = values;

        const payload = {
          name: packageName,
          product_id: productId,
          user_id: userId,
          customer_id: customerId,
          signed_by: USER_TYPE.ADMIN + " " + email,
          price: price,
          available_credits: availableCredits,
          total_credits: totalCredits,
          email_notification,
          renewals: Number(renewal),
          status,
          renewal_date: renewalDate,
          activation_date: activationDate,
          billing_account_id,
        };

        await createSubscription(payload);

        showSuccessToast(messages.success_message);

        handleClose();
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [
      email,
      handleClose,
      messages.exception_error_message,
      messages.success_message,
      showErrorToast,
      showSuccessToast,
    ]
  );

  const handleSubmit = useCallback(
    values => {
      createSubscriptionByAdmin(values);
    },
    [createSubscriptionByAdmin]
  );

  const formik = useFormik({
    validationSchema: CreateCouponSchema,
    initialValues: INITIAL_VALUES,
    onSubmit: handleSubmit,
  });

  const getCustomers = useCallback(
    async query => {
      try {
        let queryString = "include=subscription";

        if (query) {
          queryString += `&filter[search][columns]=customer_name,id,&filter[search][value]=${query}`;
        }

        const { data: { data: customersData = [] } = {} } =
          await customerService(queryString);
        const formattedData = customersData.map(customer => ({
          ...customer,
          label: `${customer.customer_name} - #${customer.id}`,
        }));

        setCustomers(formattedData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      }
    },
    [messages.exception_error_message, showErrorToast]
  );

  const getUsers = useCallback(
    async customerId => {
      try {
        const queryString = `per_page=1000&filter[customer.id]=${customerId}`;
        const { data: { data: usersData = [] } = {} } =
          await userService(queryString);

        setUsers(usersData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      }
    },
    [messages.exception_error_message, showErrorToast]
  );

  const getSubscriptionPackages = useCallback(async () => {
    try {
      const { data: { data: subscriptionPackagesData = [] } = {} } =
        await fetchAllProducts({ type: PRODUCT_TYPE.SUBSCRIPTION });

      setSubscriptionPackages(subscriptionPackagesData);
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    }
  }, [messages.exception_error_message, showErrorToast]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setIsLoading(true);

        await Promise.all([
          getCustomers(),
          getUsers(),
          getSubscriptionPackages(),
        ]);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [
    getCustomers,
    getSubscriptionPackages,
    getUsers,
    messages.exception_error_message,
    showErrorToast,
  ]);

  const handleSearchCustomer = async event => {
    const { query = "" } = event;

    getCustomers(query);
  };

  const handleChangeCustomer = event => {
    formik.handleChange(event);

    const { value: customer } = event;

    if (!customer?.id) {
      return;
    }

    getUsers(customer.id);
  };

  const handleChangePackage = event => {
    const { value: subscriptionPackage = {} } = event;

    if (subscriptionPackage?.name === SPECIAL_PACKAGE) {
      formik.setFieldValue("price", null);
      formik.setFieldValue("availableCredits", null);
      formik.setFieldValue("totalCredits", null);
    }

    formik.setFieldValue("price", subscriptionPackage.price);
    formik.setFieldValue("availableCredits", subscriptionPackage.credits);
    formik.setFieldValue("totalCredits", subscriptionPackage.credits);

    formik.handleChange(event);
  };

  const handleOnStatusChange = event => {
    const { value } = event;
    const { renewal } = formik.values;

    if (value === SUBSCRIPTION_STATUS.ACTIVE) {
      formik.setFieldValue("activationDate", new Date());
      if (renewal) {
        const renewalDate = new Date();
        renewalDate.setFullYear(renewalDate.getFullYear() + 1);
        formik.setFieldValue("renewalDate", renewalDate);
      }
    } else {
      formik.setFieldValue("activationDate", null);
      formik.setFieldValue("renewalDate", null);
    }

    formik.handleChange(event);
    setTimeout(() => {
      formik.handleBlur({ target: { name: "status" } });
    }, 0);
  };

  const handleOnActivationDateChange = event => {
    const { value } = event;
    const { renewal } = formik.values;
    if (renewal && value) {
      const renewalDate = value;
      renewalDate.setFullYear(renewalDate.getFullYear() + 1);
      formik.setFieldValue("renewalDate", renewalDate);
    }

    formik.handleChange(event);
  };


  const handleOnRenewalChange = event => {
    const { value } = event;
    const { activationDate } = formik.values;
    if (activationDate && value) {
      const renewalDate = activationDate;
      renewalDate.setFullYear(renewalDate.getFullYear() + 1);
      formik.setFieldValue("renewalDate", renewalDate);
    }

    formik.handleChange(event);
    setTimeout(() => {
      formik.handleBlur({ target: { name: "renewal" } });
    }, 0);

  };

  const handleSelectBillingAccount = billingAccountId => {
    formik.setFieldValue("billing_account_id", billingAccountId);
  };

  const shouldDisableUser = !formik.values.customer || formik.errors.customer;
  const shouldDisableSubscriptionFields =
    !formik.values.package || formik.errors.package;
  const shouldDisableTotalCredits =
    formik.values.package?.name !== SPECIAL_PACKAGE ||
    shouldDisableSubscriptionFields;
  const shouldDisableRenewalDate =
    !formik.values.renewal ||
    formik.errors.renewal ||
    !formik.values.activationDate ||
    formik.errors.activationDate;

  return (
    <FullSizeDialog
      title={messages.title_create_subscription}
      onClose={handleClose}
    >
      {isLoading && <ProgressSpinner />}

      <CardWrapper title={messages.title_customer}>
        <Div
          width={1}
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
          gridGap={"24px"}
        >
          <InputAutocomplete
            flex="1 1 40%"
            delay={500}
            name="customer"
            optionLabel="label"
            label={messages.label_customer}
            placeholder={messages.placeholder_autocomplete}
            options={customers}
            onComplete={handleSearchCustomer}
            onChange={handleChangeCustomer}
            value={formik.values.customer}
            formikProps={formik}
          />
          <InputDropdown
            flex="1 1 40%"
            name="user"
            options={users}
            optionLabel="name"
            label={messages.user_label}
            placeholder={`${messages.user_label}`}
            disabled={shouldDisableUser}
            onChange={formik.handleChange}
            value={formik.values.user}
          />
        </Div>
      </CardWrapper>

      <CardWrapper title={messages.subscription}>
        <Div
          width={1}
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
          gridGap={"24px"}
        >
          <InputDropdown
            flex="1 1 40%"
            name="package"
            options={subscriptionPackages}
            optionLabel="name"
            label={messages.package_label}
            placeholder={`${messages.selected_package}`}
            onChange={handleChangePackage}
            value={formik.values.package}
          />

          <Div flex="1 1 40%">
            <InputText
              width={1}
              name="price"
              label={messages.watchlist_label_price}
              placeholder={messages.watchlist_label_price}
              formikProps={formik}
              value={formik.values.price}
              disabled={shouldDisableSubscriptionFields}
            />
          </Div>

          <Div flex="1 1 40%">
            <InputText
              width={1}
              name="availableCredits"
              label={messages.subscription_available_credits}
              placeholder={`${messages.subscription_available_credits}`}
              formikProps={formik}
              value={formik.values.availableCredits}
              type="number"
              disabled={shouldDisableSubscriptionFields}
            />
          </Div>

          <Div flex="1 1 40%">
            <InputText
              width={1}
              name="totalCredits"
              label={messages.text_total_credits}
              placeholder={`${messages.text_total_credits}`}
              formikProps={formik}
              value={formik.values.totalCredits}
              type="number"
              disabled={shouldDisableTotalCredits}
            />
          </Div>
        </Div>
      </CardWrapper>

      <CardWrapper title={messages.Settings}>
        <Div
          width={1}
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
          gridGap={"24px"}
        >
          <InputDropdown
            flex="1 1 40%"
            name="status"
            options={SUBSCRIPTION_STATUS_OPTIONS}
            optionLabel="label"
            label={messages.label_status}
            placeholder={`${messages.placeholder_choose}`}
            onChange={handleOnStatusChange}
            value={formik.values.status}
          />

          {formik.values.status === SUBSCRIPTION_STATUS.ACTIVE ||
          !formik.values.status ? (
            <InputDate
              flex="1 1 40%"
              formikProps={formik}
              name="activationDate"
              label={messages.activation_date}
              placeholder={`${messages.label_date}`}
              value={formik.values.activationDate}
              onChange={handleOnActivationDateChange}
              maxDate={
                formik.values.status === SUBSCRIPTION_STATUS.ACTIVE
                  ? new Date()
                  : null
              }
              minDate={!formik.values.status ? new Date() : null}
            />
          ) : (
            <Div
              width={1}
              flex="1 1 40%"
              display={["none", "none", "block", "block"]}
            />
          )}

          <InputDropdown
            flex="1 1 40%"
            name="renewal"
            options={RENEWAL_OPTIONS}
            optionLabel="label"
            label={messages.renewal_label}
            placeholder={`${messages.placeholder_choose}`}
            onChange={handleOnRenewalChange}
            value={formik.values.renewal}
          />

          <InputDate
            flex="1 1 40%"
            formikProps={formik}
            name="renewalDate"
            label={messages.renewal_date}
            placeholder={`${messages.label_date}`}
            value={formik.values.renewalDate}
            onChange={formik.handleChange}
            disabled={shouldDisableRenewalDate}
            minDate={
              formik.values.activationDate ? formik.values.renewalDate : null
            }
          />
        </Div>
      </CardWrapper>

      <BillingInformation
        customerId={formik.values.customer?.id}
        onSelectBillingAccount={handleSelectBillingAccount}
      />

      <Div mt={4}>
        <TextMediumWeight>
          {messages.message_send_order_confirmation}
        </TextMediumWeight>
        <Div my={2} display="flex" alignItems="center">
          <Checkbox
            checked={formik.values.email_notification}
            onChange={formik.handleChange}
            name={"email_notification"}
          />
          <H5 mx={2}>{messages.send_email_confirmation}</H5>
        </Div>
      </Div>

      <Div
        width={1}
        mt={4}
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        gridGap={"24px"}
      >
        <PrimaryButton
          type="submit"
          width={[1, 1, "150px", "150px"]}
          label={messages.label_create}
          disabled={!formik.isValid || !formik.dirty}
          onClick={formik.handleSubmit}
        />
        <PrimaryButtonOutlined
          type="button"
          width={[1, 1, "150px", "150px"]}
          label={messages.label_cancel}
          onClick={handleClose}
        />
      </Div>
    </FullSizeDialog>
  );
};

CreateSubscription.propTypes = {
  onClose: PropTypes.func,
};

export default CreateSubscription;
