import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import { Text } from "@components/Text";

const MyProfileDisableBankIdDialog = ({ onClose, onSubmit }) => {
  const { messages } = useIntl();

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={1}
      maxWidth="450px"
      onHide={onClose}
      m={[3, 3, 3, "auto"]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
      >
        <Icon name="bankID" fontSize="var(--fs-icon-xl)" />
        <H3>{messages.title_disable_bank_id}</H3>
        <Text textAlign="center">{messages.message_disable_bank_id_login}</Text>
        <Div
          width={1}
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          alignItems="center"
          justifyContent="center"
          gridGap={[3, 3, 4, 4]}
        >
          <PrimaryButtonOutlined
            width={[1, 1, "150px", "150px"]}
            height="40px"
            label={messages.label_cancel}
            onClick={onClose}
          />

          <PrimaryButton
            type="submit"
            width={[1, 1, "150px", "150px"]}
            height="40px"
            label={messages.label_deactivate}
            onClick={handleSubmit}
          />
        </Div>
      </Div>
    </Dialog>
  );
};

MyProfileDisableBankIdDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default MyProfileDisableBankIdDialog;
