import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import Breadcrumb from "@components/Breadcrumb";
import Div from "@components/Div";
import ProgressSpinner from "@components/ProgressSpinner";

import AdminContainer from "@layout/AdminContainer";

import { ROUTES } from "@utils/constant";

import Form from "./Form";
import useCreateService from "./useCreateService";

const CreateService = () => {
  const { isLoading, dataSourceTypes, handleCreate } = useCreateService();
  const history = useHistory();
  const { messages } = useIntl();

  const headerConfig = {
    title: messages.title_create_service,
  };

  const breadCrumbItems = [
    {
      label: messages.label_products,
      url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    },
    {
      label: messages.title_create_service,
    },
  ];

  const handleCreateService = values => {
    const redirectToServicesList = () => {
      history.push(ROUTES.ADMIN_PRODUCTS_LIST.URL);
    };

    handleCreate(values, redirectToServicesList);
  };

  return (
    <AdminContainer config={headerConfig}>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <Div px={2} pb={3}>
          <Breadcrumb items={breadCrumbItems} p={0} pb={[1, 1, 1, 10]} />

          {!isLoading && (
            <Div
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width={[1, 1, 1, 800]}
            >
              <Form
                onSubmit={handleCreateService}
                dataSourceTypes={dataSourceTypes}
              />
            </Div>
          )}
        </Div>
      )}
    </AdminContainer>
  );
};

export default CreateService;
