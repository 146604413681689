import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import * as Yup from "yup";

import { authActions } from "@app/reducers/authReducer";

import putUserService from "@app/services/users/putUserService";

import { default as Div } from "@components/Div";
import JsonFormCard from "@components/JsonForm/JsonFormCard";

import { useToast } from "@hooks/useToast";

const MyProfileUserInfoCard = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { firstname, lastname, email, phone, id } = useSelector(
    state => state.authReducer.userInfo
  );
  const { showErrorToast, showSuccessToast } = useToast();

  const handleUserInfoUpdate = async values => {
    try {
      const { data: user } = await putUserService(id, values);
      dispatch(authActions.storeUserInfo({ ...user }));

      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const contact = [
    {
      label: <FormattedMessage id="label_firstname" />,
      fieldType: "text",
      name: "firstname",
      value: firstname,
      placeholder: <FormattedMessage id="label_firstname" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_name" />
      ),
    },
    {
      label: <FormattedMessage id="label_last_name" />,
      fieldType: "text",
      name: "lastname",
      value: lastname,
      placeholder: <FormattedMessage id="label_last_name" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_last_name" />
      ),
    },
    {
      label: <FormattedMessage id="label_email" />,
      fieldType: "text",
      name: "email",
      value: email,
      placeholder: <FormattedMessage id="label_email" />,
      validation: Yup.string().required(
        <FormattedMessage id="label_required" />
      ),
    },
    {
      label: <FormattedMessage id="label_phone" />,
      fieldType: "text",
      name: "phone",
      value: phone,
      placeholder: <FormattedMessage id="label_phone" />,
      validation: Yup.string().required(
        <FormattedMessage id="validation_empty_phone_number" />
      ),
    },
  ];

  return (
    <Div display="flex" flexDirection="column" width={1} my={-3}>
      <JsonFormCard
        title={messages.title_user_info}
        config={contact}
        onSubmit={handleUserInfoUpdate}
        isEditPermission="true"
      />
    </Div>
  );
};

export default MyProfileUserInfoCard;
