import React from "react";
import { FormattedMessage } from "react-intl";

import { COURT_PACKAGE_OPTIONS } from "@utils/constant";
import { SERVICE_TYPE } from "@utils/enum";

const SERVICE_TYPE_OPTIONS = [
  {
    value: SERVICE_TYPE.ANALYSIS,
    label: <FormattedMessage id={SERVICE_TYPE.ANALYSIS} />,
  },
  {
    value: SERVICE_TYPE.ASSIGNMENT,
    label: <FormattedMessage id={SERVICE_TYPE.ASSIGNMENT} />,
  },
  {
    value: SERVICE_TYPE.BUNDLE,
    label: <FormattedMessage id="servicetype_bundle" />,
  },
];

const ANALYSIS_TYPES = [
  {
    label: <FormattedMessage id="label_general_analysis" />,
    value: "general-analysis",
  },
  {
    label: <FormattedMessage id="label_role_analysis" />,
    value: "role-specific-analysis",
  },
  {
    label: <FormattedMessage id="label_general_analysis_actapublic" />,
    value: "general-analysis-actapublica",
  },
];
const COURT_PACKAGE_TYPES_OPTIONS = [
  {
    label: <FormattedMessage id="datasource_court_small" />,
    value: COURT_PACKAGE_OPTIONS.SMALL,
  },
  {
    label: <FormattedMessage id="datasource_court_medium" />,
    value: COURT_PACKAGE_OPTIONS.MEDIUM,
  },
  {
    label: <FormattedMessage id="datasource_court_large" />,
    value: COURT_PACKAGE_OPTIONS.LARGE,
  },
];
const PROCESS_TYPES_OPTIONS = [
  {
    label: <FormattedMessage id="label_automatic" />,
    value: "automatic",
  },
  {
    label: <FormattedMessage id="label_manual" />,
    value: "manual",
  },
];
const FORTNOX_OPTIONS = [
  {
    label: <FormattedMessage id="yes_label" />,
    value: 1,
  },
  {
    label: <FormattedMessage id="no_label" />,
    value: 0,
  },
];
const CASE_OPTIONS = [
  {
    label: <FormattedMessage id="yes_label" />,
    value: 1,
  },
  {
    label: <FormattedMessage id="no_label" />,
    value: 0,
  },
];
const SELECT_OPTIONS = [
  {
    label: <FormattedMessage id="yes_label" />,
    value: 1,
  },
  {
    label: <FormattedMessage id="no_label" />,
    value: 0,
  },
];
const AVAILABLE_OPTIONS = [
  {
    label: <FormattedMessage id="yes_label" />,
    value: 1,
  },
  {
    label: <FormattedMessage id="no_label" />,
    value: 0,
  },
];

export {
  SERVICE_TYPE_OPTIONS,
  ANALYSIS_TYPES,
  COURT_PACKAGE_TYPES_OPTIONS,
  PROCESS_TYPES_OPTIONS,
  FORTNOX_OPTIONS,
  CASE_OPTIONS,
  SELECT_OPTIONS,
  AVAILABLE_OPTIONS,
};
