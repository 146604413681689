import React from "react";
import { useIntl } from "react-intl";

import AdminContainer from "@layout/AdminContainer";

import MyProfile from "@pages/shared/MyProfile";

const AdminMyProfile = () => {
  const { messages } = useIntl();

  const headerConfig = {
    title: messages.personal_setting,
  };

  return (
    <AdminContainer config={headerConfig}>
      <MyProfile />
    </AdminContainer>
  );
};

export default AdminMyProfile;
