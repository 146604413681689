import Routes from "@shared/Routes";
import messages_sv from "@translation/sv.json";
import Config from "@src/config";
import React from "react";
import { default as Div } from "@components/Div";
import Icon from "@components/Icon";
import { useIntl } from "react-intl";
import { CustomerPermissions, AdminPermissions } from "@src/enum/Permissions";

export const SVLANG = messages_sv;

export const HEADER_NAV = [
  {
    label: SVLANG.om_oss,
    isDropDown: true,
    dropDownOptions: [
      {
        label: SVLANG.om_oss,
        isWpPage: true,
        redirectTo: `${Config.WP_URL}${Routes.WP_ABOUT_US.URL}`,
      },
      {
        label: SVLANG.title_feature_services,
        isWpPage: true,
        redirectTo: `${Config.WP_URL}${Routes.WP_SERVICES.URL}`,
      },
      {
        label: SVLANG.label_news,
        isWpPage: true,
        redirectTo: `${Config.WP_URL}${Routes.WP_BLOG.URL}`,
      },
    ],
  },
  {
    label: SVLANG.subscription,
    redirectTo: `${Config.WP_URL}${Routes.WP_SUBSCRIPTION.URL}`,
    isWpPage: true,
  },
  {
    label: SVLANG.contact,
    redirectTo: `${Config.WP_URL}/kontakta-oss/`,
    isWpPage: true,
  },
];

const PersonalSettingsLabel = () => {
  const { messages } = useIntl();

  return (
    <Div alignItems={"center"} display="flex">
      <Icon color="var(--turquoise)" mr={"8px"} name={"profile"} />
      {messages.personal_setting}
    </Div>
  );
};

export const HEADER_NAV_MAIN = [
  {
    label: <PersonalSettingsLabel />,
    value: "personal-setting",
    redirectTo: Routes.USER_PROFILE.URL,
    isWpPage: true,
    tabIcon: "profile",
  },
];

export const ADMIN_HEADER_NAV_MAIN = [
  {
    label: <PersonalSettingsLabel />,
    value: "personal-setting",
    redirectTo: `/admin/anvandarprofil`,
    isWpPage: true,
  },
];

export const ADMIN_NAV = [
  {
    label: SVLANG.title_customers,
    redirectTo: "/admin/kunder",
    tabIcon: "customersa",
    permissions: [AdminPermissions.AdminReadCustomers],
  },
  {
    label: SVLANG.label_order_page,
    redirectTo: "/admin/bestallningar",
    tabIcon: "ordersA",
    permissions: [AdminPermissions.AdminReadOrders],
  },
  {
    label: SVLANG.title_cases,
    tabIcon: "cases",
    redirectTo: "/admin/arenden",
    permissions: [AdminPermissions.AdminReadCases],
  },
  {
    label: SVLANG.title_reports,
    redirectTo: "/admin/rapporter",
    tabIcon: "allAnalysisA",
    permissions: [AdminPermissions.AdminReadReportAnalysis],
  },
  {
    label: SVLANG.title_assignments,
    redirectTo: "/admin/uppdrag",
    tabIcon: "AssignmentsA",
    permissions: [AdminPermissions.AdminReadAssignments],
  },
  {
    label: SVLANG.subscriptions_title,
    redirectTo: "/admin/subscription-list",
    tabIcon: "subscriptionsA",
    permissions: [AdminPermissions.AdminReadSubscription],
  },
  {
    label: SVLANG.label_products,
    tabIcon: "productsA",
    isDropDown: true,
    permissions: [AdminPermissions.AdminReadProducts],
    dropDownOptions: [
      {
        label: SVLANG.label_services,
        permissions: [AdminPermissions.AdminReadProducts],
        redirectTo: Routes.ADMIN_PRODUCTS_LIST.URL,
      },
      {
        label: SVLANG.label_subscription_packages,
        permissions: [AdminPermissions.AdminReadProducts],
        redirectTo: Routes.ADMIN_PRODUCTS_SUBSCRIPTION_LIST.URL,
      },
    ],
  },
  {
    label: SVLANG.title_billing,
    tabIcon: "allAnalyserA",
    isDropDown: true,
    permissions: [AdminPermissions.AdminReadBilling],
    dropDownOptions: [
      {
        label: SVLANG.label_not_invoiced,
        value: "billing",
        redirectTo: Routes.ADMIN_UN_BILLED_SERVICES.URL,
        permissions: [AdminPermissions.AdminReadBilling],
      },
      {
        label: SVLANG.subscription_order_invoice,
        redirectTo: Routes.ADMIN_INVOICE_ACCOUNTS.URL,
        permissions: [AdminPermissions.AdminReadBilling],
      },
      {
        label: SVLANG.title_billing_accounts,
        redirectTo: Routes.ADMIN_BILLING_ACCOUNTS.URL,
        permissions: [AdminPermissions.AdminReadBilling],
      },
    ],
  },
  {
    label: SVLANG.title_users,
    tabIcon: "usersA",
    isDropDown: true,
    permissions: [
      AdminPermissions.AdminReadUsers,
      AdminPermissions.AdminReadCustomerUsers,
    ],
    dropDownOptions: [
      {
        label: SVLANG.title_admins,
        redirectTo: Routes.ADMIN_ADMINS_LIST.URL,
        permissions: [AdminPermissions.AdminReadUsers],
      },
      {
        label: SVLANG.title_customers,
        redirectTo: Routes.ADMIN_CUSTOMERS_LIST.URL,
        permissions: [AdminPermissions.AdminReadCustomerUsers],
      },
    ],
  },
  {
    label: SVLANG.title_configuration,
    tabIcon: "configuration",
    isDropDown: true,
    permissions: [
      AdminPermissions.AdminReadRules,
      AdminPermissions.AdminReadCrimes,
      AdminPermissions.AdminReadCourts,
      AdminPermissions.AdminReadUserRolesPermissions,
      AdminPermissions.AdminReadEmailAccounts,
      AdminPermissions.AdminWriteBlockedCandidates,
      AdminPermissions.AdminReadBlockedCandidates,
    ],
    dropDownOptions: [
      {
        label: SVLANG.title_cases,
        permissions: [
          AdminPermissions.AdminReadRules,
          AdminPermissions.AdminReadCrimes,
          AdminPermissions.AdminReadCourts,
        ],
        redirectTo: Routes.ADMIN_RULES_LIST.URL,
      },
      {
        label: SVLANG.user_rights_label,
        permissions: [AdminPermissions.AdminReadUserRolesPermissions],
        redirectTo: Routes.ADMIN_ROLES_LIST.URL,
      },
      {
        label: SVLANG.title_e_mails,
        permissions: [AdminPermissions.AdminReadEmailAccounts],
        redirectTo: Routes.ADMIN_EMAIL_ACCOUNTS_LIST.URL,
      },
      {
        label: SVLANG.label_blocked_candidates,
        permissions: [AdminPermissions.AdminReadEmailAccounts],
        redirectTo: Routes.ADMIN_BLOCKED_CANDIDATES_LIST.URL,
      },
    ],
  },
];

export const ADMIN_NAV_MY_PROFILE = [
  {
    label: SVLANG.my_profile,
    value: "",
    redirectTo: "/admin/anvandarprofil",
    tabIcon: "account-circle",
  },
];

export const MYACCOUNTS_NAV = [
  {
    label: SVLANG.search_text,
    permissions: [CustomerPermissions.CustomerWriteOrders],
    redirectTo: "/sok",
    tabIcon: "search",
  },
  {
    label: SVLANG.label_order_page,
    permissions: [
      CustomerPermissions.CustomerReadOrders,
      CustomerPermissions.CustomerReadSelfOrders,
    ],
    redirectTo: "/mittkonto/bestallningar",
    tabIcon: "ordersA",
  },
  {
    label: SVLANG.title_reports,
    permissions: [
      CustomerPermissions.CustomerReadReportAnalysis,
      CustomerPermissions.CustomerGradesReportAnalysis,
      CustomerPermissions.CustomerReadSelfReportsAnalysis,
    ],
    redirectTo: "/mittkonto/rapporter",
    tabIcon: "allAnalysisA",
  },
  {
    label: "Bevakning",
    permissions: [CustomerPermissions.CustomerReadWatchLists],
    redirectTo: "/mittkonto/bevakningslista",
    tabIcon: "watch-list",
  },

  {
    label: SVLANG.subscriptions_title,
    permissions: [CustomerPermissions.CustomerReadSubscription],
    redirectTo: "/mittkonto/abonnemang",
    tabIcon: "subscriptionsA",
  },
  {
    label: SVLANG.title_users,
    permissions: [CustomerPermissions.CustomerReadUsers],
    redirectTo: "/mittkonto/anvandare",
    tabIcon: "usersA",
  },
  {
    label: SVLANG.organisation,
    permissions: [CustomerPermissions.CustomerReadCompanyInformation],
    redirectTo: "/mittkonto/foretagsinformation",
    tabIcon: "account-circle",
  },
];

export const MYACCOUNTS_NAV_SIDEBAR = [
  {
    label: SVLANG.search_text,
    permissions: [CustomerPermissions.CustomerWriteOrders],
    redirectTo: "/sok",
    tabIcon: "search",
  },
  {
    label: SVLANG.label_order_page,
    permissions: [
      CustomerPermissions.CustomerReadOrders,
      CustomerPermissions.CustomerReadSelfOrders,
    ],
    redirectTo: "/mittkonto/bestallningar",
    tabIcon: "ordersA",
  },
  {
    label: SVLANG.title_reports,
    permissions: [
      CustomerPermissions.CustomerReadReportAnalysis,
      CustomerPermissions.CustomerGradesReportAnalysis,
      CustomerPermissions.CustomerReadSelfReportsAnalysis,
    ],
    redirectTo: "/mittkonto/rapporter",
    tabIcon: "allAnalysisA",
  },
  {
    label: "Bevakning",
    permissions: [CustomerPermissions.CustomerReadWatchLists],
    redirectTo: "/mittkonto/bevakningslista",
    tabIcon: "watch-list",
  },

  {
    label: SVLANG.subscriptions_title,
    permissions: [CustomerPermissions.CustomerReadSubscription],
    redirectTo: "/mittkonto/abonnemang",
    tabIcon: "subscriptionsA",
  },
  {
    label: SVLANG.title_users,
    permissions: [CustomerPermissions.CustomerReadUsers],
    redirectTo: "/mittkonto/anvandare",
    tabIcon: "usersA",
  },
  {
    label: SVLANG.title_organisation,
    permissions: [CustomerPermissions.CustomerReadCompanyInformation],
    redirectTo: "/mittkonto/foretagsinformation",
    tabIcon: "organisation",
  },
];

export const HEADER_ADMIN_NAV = [
  {
    label: SVLANG.contact,
    redirectTo: `${Config.WP_URL}/kontakta-oss/`,
    isWpPage: true,
  },
];

export const LANGUAGES = ["Eng", "Swe"];

export const ROUTES = Routes;

export const INPUT = {
  NAME: {
    EMAIL: "email",
    CONFIRM_EMAIL: "confirm_email",
    PASSWORD: "password",
    CONFIRM_PASSWORD: "confirm_password",
    NAME: "firstname",
    LAST_NAME: "lastname",
    PHONE: "phone",
    COMPANY_NAME: "company_name",
    COMPANY_NUMBER: "registration_nr",
    TERM_CONDITION: "terms_and_condition",
    COMPANY_ADDRESS: "company_address",
    CURRENT_PASSWORD: "current_password",
    ROLE: "role",
    SERVICES: "services",
    SUB_USER: "subUser",
    CUSTOMER: "customer",
    PERSONAL_NUMBER: "personal_number",
    CREDITS: "credits",
    LOGIN_PASSWORD: "login_password",
    ORGANISATION_NUMBER: "org_nr",
    AVAILABLE_ANALYSIS: "availableanalysis",
    TOTAL_ANALYSIS: "totalanalysis",
    PRICE: "price",
    USER_ID: "user_id",
    CUSTOMER_ID: "customer_id",
    PACKAGE: "package",
    DISCOUNT: "discount",
    INVOICE: "invoice",
    BILLING_EMAIL: "billing_email",
    ORDER_REFERENCE: "order_reference",
    INVOICE_ATTACHMENT: "invoice_attachment",
  },
  TYPE: {
    TEXT: "text",
    PASSWORD: "password",
    NUMBER: "number",
    CHECKBOX: "checkbox",
    EMAIL: "email",
  },
};

export const APPLICATION_NAME = "SVENSK BAKGRUNDSANALYS";
export const TITLE_DIVIDER = " | ";

export const ORDER_SUMMARY_CONTENT = {
  PERSON: "Person:",
  RAPPORT: "Rapport:",
};

export const SUBSCRIPTION_ORDER_CONTENT = {
  PACKAGE: `${SVLANG.subscription?.toLowerCase()}:`,
  INCLUDE: `${SVLANG.subscription_include}:`,
  ANALYSIS_VALUE: "10",
};

export const PAGE_NOT_FOUND_CODE = 404;

export const FILE_SIZE_LIMIT = 2000000;

export const REPORT_SIZE_LIMIT = 20000000;

export const ACCEPT_FILE_TYPES = [
  ".doc",
  ".docx",
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png",
  "image/jpg",
];

export const SUBSCRIPTION_STATUS = {
  STATUS: "status",
  ACTIVE: "active",
  STOPPED: "stopped",
  TERMINATED: "terminated",
  WRITE: "write",
  READ: "read",
  REGISTERED: "registered",
};
export const ADMIN = "admin";

export const AUTH = "auth";

export const USERS = "users";

export const CUSTOMERS = "customers";

export const CUSTOMER = "customer";

export const INDIVIDUAL = "individual";

export const COMPANY = "company";

export const FORGOTPASSWORD = "forgot-password";
//NIRMAL
export const CANDIDATE = "candidate";

export const RESETPASSWORD = "reset-password";

export const SETPASSWORD = "set-password";

export const REGISTER_ACCOUNT = "REGISTER_ACCOUNT";

export const LOGIN = "LOGIN";

export const REGISTER_WITH_BANKID = "REGISTER_WITH_BANKID";

export const PERSON_INFORMATION = "person-information";

export const SSNNUMBER = "ssnnumber";

export const DASHBOARD = "DASHBOARD";

export const DASHBOARD_COMPANY = "DASHBOARD_COMPANY";

export const REGISTRATION_SUCCESSFUL = "REGISTRATION_SUCCESSFUL";

export const SINGLE = "single";

export const MULTIPLE = "multiple";

export const SERVICES = "services";

export const PRODUCTS = "products";

export const QRCODE = "bank-id/auth";

export const BANKID_AUTH = "bank-id/auth";

export const BANKID_QR_CODE = "bank-id/qr-code";

export const BANKID_COLLECT = "bank-id/collect";

export const BANKID_UNIVERSAL_LINK = {
  MOBILE: "https://app.bankid.com/",
  DESKTOP: "bankid:///",
};

export const SIGN_QRCODE = "bank-id/sign";

export const CANCEL_AUTH = "bank-id/cancel-auth";

export const AUTHORIZE_QRCODE = "bank-id/authorize";

export const BANKID_REGISTER = "bank-id/register";

export const GET_SUBSCRIPTION = "subscriptions";

export const GET_ORDER_ITEMS = "order_items";

export const ORDERS_DETAILS = "ORDERS_DETAILS";

export const CHECKOUT = "CHECKOUT";

export const SUBSCRIPTION_CHECKOUT = "Subscription CHECKOUT";

export const ACCESS_DENIED = "Access Denied";

export const ORDER_CONFIRMATION = "ORDER_CONFIRMATION";

export const SUBSCRIPTION_ORDER_CONFIRMATION =
  "SUBSCRIPTION_ORDER_CONFIRMATION";

export const STATUS_VALUES = [
  "active",
  "deleted",
  "paid",
  "not-paid",
  "cancelled",
  "waiting",
  "in-progress",
  "completed",
  "expired",
  "expired-no-consent",
  "new",
  "not-assigned",
  "custom",
  "owner",
  "admin-custom",
  "admin-superadmin",
  "cv-verification",
  "security-analyst",
  "admin",
  "guest",
  "individual",
  "company",
  "terminated",
];

export const ORDER_RESULTS = "ORDER_RESULTS";

export const HOME = "HOME";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const RESET_PASSWORD = "RESET_PASSWORD";

export const SET_PASSWORD = "SET_PASSWORD";

export const SEARCH = "SEARCH";

export const USER_PROFILE = "USER_PROFILE";

export const CUSTOMER_DETAILS = "CUSTOMER_DETAILS";

export const ASSIGNMENT_DETAILS = "ASSIGNMENT_DETAILS";

export const PAGE_NOT_FOUND = "PAGE_NOT_FOUND";

export const ADMIN_CUSTOMERS = "ADMIN_CUSTOMERS";

export const ADMIN_USERS = "ADMIN_USERS";

export const ADMIN_ADMINS_LIST = "ADMIN_ADMINS_LIST";

export const ADMIN_CUSTOMERS_LIST = "ADMIN_CUSTOMERS_LIST";

export const ADMIN_ORDERS = "ADMIN_ORDERS";

export const ADMIN_ASSIGNMENTS = "ADMIN_ASSIGNMENTS";

export const ADMIN_ORDER_DETAILS = "ADMIN_ORDER_DETAILS";

export const COMPANY_INFO = "COMPANY_INFO";

export const CREATE_BILLING_ACCOUNT = "CREATE_BILLING_ACCOUNT";

export const BILLING_ACCOUNT_INFO = "BILLING_ACCOUNT_INFO";

export const USER_INFO = "USER_INFO";

export const SUB_USERS = "SUB_USERS";

export const ORDERS = "ORDERS";

export const USER_REGISTRATION_COMPLETED = "USER_REGISTRATION_COMPLETED";

export const ADMIN_REGISTRATION_COMPLETED = "ADMIN_REGISTRATION_COMPLETED";

export const USER_FORGOT_PASSWORD_SUCCESSFUL =
  "USER_FORGOT_PASSWORD_SUCCESSFUL";

export const ADMIN_FORGOT_PASSWORD_SUCCESSFUL =
  "ADMIN_FORGOT_PASSWORD_SUCCESSFUL";

export const USER_COMPLETE_REGISTRATION = "USER_COMPLETE_REGISTRATION";

export const ADMIN_COMPLETE_REGISTRATION = "ADMIN_COMPLETE_REGISTRATION";

export const INITIALIZE_KLARNA = "initialize-klarna";

export const CUSTOMER_USER = "customer";

export const GUEST_USER = "guest";

export const KLARNA_CHECKOUT = "klarna";

export const MANUAL_CHECKOUT = "manual";

export const ORDERS_GUEST = "orders-guest";

export const CUSTOMER_INFORMATION = "customers?id=";

export const ADMIN_CREATE_CUSTOMER = "ADMIN_CREATE_CUSTOMER";

export const ASSIGNMENTS_API = "assignments";

export const CUSTOMERS_API = "customers";

export const ORDERS_API = "orders";

export const USERS_API = "users";

export const CUSTOMER_ORDERS_API = "orders?customer_id=";

export const ROLES = "roles";

export const ORDERS_KLARNA = "order-klarna";

export const SORT_KEY_STATUS = "status";

export const SORT_KEY_NAME = "name";

export const SORT_KEY_COMPANY = "company";

export const SORT_KEY_CUSTOMER = "customer";

export const SORT_KEY_SERVICE = "service";

export const SORT_KEY_ORDER = "order";

export const SORT_KEY_USER = "user";

export const SORT_KEY_EMAIL = "email";

export const SORT_KEY_PHONE = "phone";

export const SORT_KEY_IS_ACTIVE = "is_active";

export const SORT_KEY_FIRSTNAME = "firstname";

export const SORT_KEY_COMPANY_NAME = "company_name";

export const SORT_KEY_NUMBER = "number";

export const SORT_KEY_CREATED_AT = "created_at";

export const SORT_KEY_ASSIGNMENT = "assignment";

export const SORT_KEY_ASSIGNEE = "assignee";

export const SORT_KEY_ORDERS_COUNT = "orders_count";

export const SORT_KEY_USERNAME = "username";

export const SORT_KEY_UPDATED_AT = "updated_at";

export const READ = "read";

export const WRITE = "write";

export const USERS_LIST = "USERS_LIST";

export const ORDER_LIST = "ORDER_LIST";

export const STATUS_COMPLETED = "completed";

export const PENDING_CONSENT = "Pending Consent";

export const PENDING_CONSENT_TO_SHARE = "Pending consent to share";

export const STATUS_PAID = "paid";

export const STATUS_NOT_PAID = "not-paid";

export const STATUS_IN_PROGRESS = "in-progress";

export const STATUS_CANCELLED = "cancelled";

export const STATUS_EXPIRED = "expired";

export const STATUS_EXPIRED_NO_CONSENT = "expired-no-consent";

export const STATUS_NEW = "new";

export const STATUS_ERROR = "error";

export const STATUS_FAILED = "failed";

export const REPORT = "report";

export const DELETED = "Deleted";

export const REPORTS = "reports";

export const ADDRESS = "address";

export const MARITIAL_STATUS = "maritial_status";

export const CO_RESIDENTS = "co_residents";

export const ANALYSIS = "analysis";

export const ADMIN_MY_PROFILE = "ADMIN_MY_PROFILE";

export const RESEND_USER_EMAIL = "resend-invitation-email";

export const RESEND_ASSIGNMENT_EMAIL = "resend-assignment-email";

export const RESEND_REPORT_EMAIL = "resend-report-email";

export const PROTECTED = "Protected";

export const RETRY_FAILED_REPORT = "retry-failed-report";

export const ADMIN_CUSTOMER_DETAILS = "detaljer";

export const ADMIN_CUSTOMER_USERS = "anvandare";

export const ADMIN_CUSTOMER_ORDERS = "bestallningar";

export const ADMIN_CUSTOMER_CREDITS = "abonnemang";

export const CREDITS_CHECKOUT = "credits";

export const DISCOUNT_CHECKOUT = "discount";

export const INVOICE_CHECKOUT = "invoice";

export const USER_CREDITS = "credits";

export const GET_SUBSCRIPTION_DETAIL = "subscription";

export const START_YEAR = "00";

export const END_YEAR = "10";

export const NEW_CENTURY = "20";

export const OLD_CENTURY = "19";

export const CUSTOM = "custom";

export const ADMIN_CUSTOM = "admin-custom";

export const CRIMINAL_CASES = "criminal_cases";

export const CONFIDENTIALITY_MARKINGS = "confidentiality_markings";

export const CITIZENSHIP = "citizenship";

export const SUMMARY_IMPOSITION_FINE = "summary_imposition_fine";

export const INDICTMENT = "indictment";

export const JUDGMENTS_CIVIL_LAW = "judgments_civil_law";

export const RULING_RENT_TRIBUNAL = "ruling_rent_tribunal";

export const INVOLMENT_BUSINESSES = "involvment_businesses";

export const BANKRUPTCIES = "bankruptcies";

export const NUMBER_OF_VECHICLES = "number_of_vehicles";

export const ECONOMY = "economy";

export const NUMBER_OF_MOVES = "number_of_moves";

export const SMALL_CLAIM_CASES = "number_of_smail_claim_cases";

export const RULING_ADMIN_COURT = "number_of_ruling_adminstrative_court";

export const RULING_ADMIN_COURT_APPEAL = "number_of_ruling_admin_court_appeal";

export const CIVIL_DISPUTE = "number_of_civil_dispute";

export const NUMBER_OF_BUSINESS = "number_of_business";

export const BAR = "bar";

export const BOTTOM = "bottom";

export const SLIDE = "slide";

export const BOX = "box";

export const ACCEPT_ALL = "accept_all";

export const ACCEPT_NECESSARY = "accept_necessary";

export const PERSONAL_DATA_POLICY = "personuppgiftspolicy";
export const QR_CODE = "qr-code";

export const PERSONAL_NUMBER = "personal-number";

export const PENDING_STATUS = "pending";

export const FAILED_STATUS = "failed";

export const COMPLETE_STATUS = "complete";

export const ERROR_KEY = "errors";

export const SUBSCRIPTION = "subscriptions";

export const UPGRADE_SUBSCRIPTION = "Upgrade Subscription";

export const MEDIUMSMALL = "mediumsmall";

export const INFO = "info";

export const DISCOUNTPRECENT = "10%";

export const ANALYSISPRECENT = "10";

export const PIECESCONSTANT = "895";

export const TAKENCONSTANT = "8 950";

export const PRODUCT_TYPE_SUBSCRIPTION = "subscription";

export const PRODUCT_TYPE_SERVICE = "service";

export const SPECIAL_PACKAGE = "Special";

export const SUBSCRIPTIONS = "Subscription";

export const SUBSCRIPTION_LIST = "Subscriptions";

export const SUBSCRIPTION_LIST_ADMIN = "Subscriptions List Admin";

export const SUBSCRIPTION_TYPE = "SO";

export const SUBSCRIPTION_DETAILS = "Subscription";

export const ANALYS_DETAILS = "analys_details";

export const ANALYS_LIST = "analys_list";

export const BACKGROUND_SE = "https://bakgrundsanalys.se";

export const SORT_KEY_PRODUCT = "product";

export const AVAILABLE_CREDITS = "available_credits";

export const RENEWAL_DATE = "renewal_date";

export const ANALYSIS_LIST = "ANALYSIS_LIST";

export const ANALYSIS_API = "reports";

export const REPORT_AUTH = "reports-auth";

export const ADMIN_ANALYSIS_LIST = "ADMIN_ANALYSIS_LIST";

export const ADMIN_ROLES_LIST = "ADMIN_ROLES_LIST";

export const ADMIN_ANALYSIS_API = "reports";

export const GRADES = "grades";

export const SORT_REPORT_NAME = "user";

export const ORDERS_ID = "order_id";

export const SORT_REPORT_NAMES = "username";

export const SORT_CLIENT_NAME = "client";

export const SORT_CLIENT_NAMES = "name";

export const ANALYS = "analys";

export const BOOK_DEMO = "https://calendly.com/bakgrundsanalys/";

export const UNMARRIED = "unmarried";

export const MARRIED = "married";

export const CREDITS_REPORT = "credits-report";

export const WP_REPORT_MISSING = "rapportsaknas";

export const REPORT_ADDRESS = "Holländargatan 20, 1116 Stockholm";

export const REPORT_CONSULT_NUM = "010-491 12 27";

export const REPORT_CONSULT_EMAIL = "info@bakgrundsanalys.se";

export const REPORT_PDF = "REPORT_PDF";

export const WATCH_LIST = "WATCH_LIST";

export const WATCH_LISTS = "watch-lists";

export const GENERAL_ANALYSIS = "general-analysis";

export const ACTAPUBLICA_ANALYSIS = "general-analysis-actapublica";

export const ROLE_ANALYSIS = "role-specific-analysis";

export const BUNDLE = "bundle";

export const ASSIGNMENT = "assignment";

export const ANALYSIS_TYPE = "analysis_type";

export const CONSENT_OPTIONAL = "Consent Optional";

export const CONSENT_MANDATORY = "Consent Mandatory";

export const EXPIRY_DAYS = 15;

export const DAYS = "days";

export const NO_CONSENT = "No Consent";

export const WATCHLIST_OBJECTS_API = "objects";

export const WATCHLIST_OBJECT_HISTORIES_API = "histories";

export const WATCH_LIST_OBJECTS_API = "watch-list-objects";

export const LAST_REPORT_GENERATED_DATE = "last_report_generated_date";

export const WATCH_LIST_HISTORY = "watch-list-history";

export const PRICE = "price";

export const ADMIN_CREATE_WATCHLIST = "ADMIN_CREATE_WATCHLIST";

export const ADMIN_WATCHLIST = "ADMIN_WATCHLIST";

export const ADMIN_BILLING_ACCOUNT_INFO = "ADMIN_BILLING_ACCOUNT_INFO";

export const ADMIN_NEW_WATCHLIST = "skapa-bevakningslista";

export const ADMIN_WATCHLIST_URL = "bevakningslista";

export const ADMIN_WATCH_LIST_DETAIL = "ADMIN_WATCH_LIST_DETAIL";

export const RECURRING = "recurring";

export const ADMIN_WATCH_LIST_OBJECT_DETAIL = "ADMIN_WATCH_LIST_OBJECT_DETAIL";

export const OBJECT_ORDER_DETAILS = "OBJECT_ORDER_DETAILS";

export const SEE_REPORT = "seeReport";

export const RECURRENT_TAB_URL = "tab=Återkommande";

export const IMPORT_CSV_COLUMN_NAME = "Personnummer";

export const OBJECT = "object";

export const INVOICE_PAYMENT = "invoice checkout";

export const CREDITS_PAYMENT = "Credits checkout";

export const CREDITS_AND_INVOICE_PAYMENT = "Credits and Invoice Checkout";

export const PAYMENT_SUBSCRIPTION_AND_INVOICE = "Credits and Invoice";

export const PAYMENT_INVOICE = "Invoice";

export const PAYMENT_SUBSCRIPTION = "Abonnemang";

export const PAYMENT_KLARNA = "Klarna";

export const PAYMENT_METHOD_CREDITS_AND_INVOICE = "credits_and_invoice";

export const GRADE_BG_COLOR = "gradeBgColor";

export const GRADE_COLOR = "gradeColor";

export const PROMO_CODE_CHECK = "promo-codes/check";

export const ADMIN_CREATE_SERVICE = "ADMIN_CREATE_SERVICE";

export const RULES = "rules";

export const BANKID_MESSAGE_TERM = {
  OUTSTANDING_TRANSACTION: "outstandingTransaction",
  NO_CLIENT: "noClient",
  USER_SIGN: "userSign",
  STARTED: "started",
  USER_MRTD: "userMrtd",
  USER_CANCEL: "userCancel",
  EXPIRED_TRANSACTION: "expiredTransaction",
  CERTIFICATE_ERR: "certificateErr",
  START_FAILED: "startFailed",
  CANCELLED: "cancelled",
  ALREADY_IN_PROGRESS: "alreadyInProgress",
};

export const ADMIN_CREATE_SUBSCRIPTION = "ADMIN_CREATE_SUBSCRIPTION";

export const CURRENCY = "kr";

export const ADMIN_PRODUCTS_LIST = "ADMIN_PRODUCTS_LIST";

export const ADMIN_UPDATE_SUBSCRIPTION = "ADMIN_UPDATE_SUBSCRIPTION";

export const STATUS_IN_ACTIVE = "inactive";

export const ADMIN_PRODUCTS_SUBSCRIPTION_LIST =
  "ADMIN_PRODUCTS_SUBSCRIPTION_LIST";

export const ADMIN_UPDATE_SERVICE_PRODUCT = "ADMIN_UPDATE_SERVICE_PRODUCT";

export const CUSTOMER_ORDERS = "CUSTOMER_ORDERS";

export const CUSTOMER_CREDITS = "CUSTOMER_CREDITS";

export const CUSTOMER_USERS = "CUSTOMER_USERS";

export const CUSTOMER_ANALYSIS = "CUSTOMER_ANALYSIS";

export const CUSTOMER_PRODUCTS = "CUSTOMER_PRODUCTS";

export const serviceTypes = {
  analysis: SVLANG.analysis,
  assignment: SVLANG.assignment,
  bundle: SVLANG.servicetype_bundle,
};

export const STATUS_DONE = "done";

export const BILLING_ACCOUNTS_LIST = "BILLING_ACCOUNTS_LIST";

export const ADMIN_BILLING_ACCOUNTS_LIST = "ADMIN_BILLING_ACCOUNTS_LIST";

export const ADMIN_BILLING_ACCOUNTS = "faktura";

export const ADMIN_CREATE_BILLING_ACCOUNT = "ADMIN_CREATE_BILLING_ACCOUNT";

export const BILLING_ACCOUNTS = "billing-accounts";

export const INTEGRATIONS_API = "integrationer";

export const INTEGRATIONS_API_LIST = "INTEGRATIONS_API_LIST";

export const ADMIN_INTEGRATIONS_API_LIST = "ADMIN_INTEGRATIONS_API_LIST";

export const NOT_INVOICED = "Not invoiced";

export const INVOICED = "Invoiced";

export const INVOICE_CANCELLED = "Cancelled";

export const ADMIN_COUPONS_LIST = "ADMIN_COUPONS_LIST";

export const ADMIN_CREATE_COUPON = "ADMIN_CREATE_COUPON";

export const ADMIN_EDIT_COUPON = "ADMIN_EDIT_COUPON";

export const PROMO_CODES = "/promo-codes";

export const ERROR_404 = "error404";

export const USER_ORDER_REDIRECT = "bestallningar/detaljer";

export const RAPPORT = "rapporter";

export const RAPPORT_URL = "rapport";

export const ADMIN_CASES_LIST = "ADMIN_CASES_LIST";

export const ADMIN_EMAIL_LOG = "ADMIN_EMAIL_LOG";

export const ADMIN_EMAIL_LOG_DETAILS = "ADMIN_EMAIL_LOG_DETAILS";

export const PERMISSIONS = "permissions";

export const OTHERS = "others";

export const ADMIN_CASE_DETAILS = "ADMIN_CASE_DETAILS";

export const CASES = "cases";

export const EMAIL_MESSAGES = "email-messages";

export const ADMIN_CASE_SOURCE_DATA = "ADMIN_CASE_SOURCE_DATA";

export const SOURCE_DATA = "source-data";

export const ADMIN_CASE_REPORT = "ADMIN_CASE_REPORT";

export const EMAIL_ACCOUNTS = "email-accounts";
export const EMAIL_TEMPLATES = "email-templates";

export const COURT_PACKAGE_OPTIONS = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

export const CRIMES = "crimes";

export const COURTS = "courts";

export const ADMIN_BILLING_ACCOUNT_ORDERS = "ADMIN_BILLING_ACCOUNT_ORDERS";
export const ADMIN_CASE_CRIMES = "ADMIN_CASE_CRIMES";

export const ADMIN_CASE_EMAIL_THREAD = "ADMIN_CASE_EMAIL_THREAD";

export const ADMIN_UPDATE_SERVICE_PRODUCT_FORM_CONFIGURATION =
  "ADMIN_UPDATE_SERVICE_PRODUCT_FORM_CONFIGURATION";

export const ADMIN_UPDATE_SERVICE_PRODUCT_RULES =
  "ADMIN_UPDATE_SERVICE_PRODUCT_RULES";

export const ADMIN_CASE_COMMENTS = "ADMIN_CASE_COMMENTS";

export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "danger",
};
export const ADMIN_COURTS_LIST = "ADMIN_COURTS_LIST";

export const ADMIN_PERMISSION_LIST = "ADMIN_PERMISSION_LIST";

export const INTEGRATIONS_API_URL = "/profile/integrations-api";

export const ADMIN_EMAIL_ACCOUNTS_LIST = "ADMIN_EMAIL_ACCOUNTS_LIST";

export const ADMIN_CRIMES_LIST = "ADMIN_CRIMES_LIST";

export const ADMIN_ACTIVITY_LOG_LIST = "ADMIN_ACTIVITY_LOG_LIST";

export const ADMIN_EMAIL_TEMPLATE_LIST = "ADMIN_EMAIL_TEMPLATE_LIST";

export const ADMIN_CUSTOMER_COMMENTS = "ADMIN_CUSTOMER_COMMENTS";

export const WIZARD_SEARCH = "WIZARD_SEARCH";

export const WIZARD_SELECT_SERVICES = "WIZARD_SELECT_SERVICES";

export const WIZARD_CONFIRM_ORDER = "WIZARD_CONFIRM_ORDER";

export const WIZARD_ORDER_COMPLETED = "WIZARD_ORDER_COMPLETED";

export const ADMIN_WIZARD_SELECT_CUSTOMER = "ADMIN_WIZARD_SELECT_CUSTOMER";

export const ADMIN_CASE_ACTIVITY_LOG = "ADMIN_CASE_ACTIVITY_LOG";

export const ACTIVITY_LOG = "activity-log";

export const ADMIN_RULES_LIST = "ADMIN_RULES_LIST";

export const PRODUCT_REPORT = "PRODUCT_REPORT";

export const ADMIN_CUSTOMER_ACTIVITY_LOG = "ADMIN_CUSTOMER_ACTIVITY_LOG";

export const ADMIN_ACTIVITY_LOG_DETAILS = "ADMIN_ACTIVITY_LOG_DETAILS";

export const SELECTED_PRODUCTS = "produkter";

export const TYPE_UPDATED = "updated";

export const SUBSCRIPTION_REQUEST = "subscription-request";

export const SOURCE_DATA_API = "source_data";

export const SUBSCRIPTION_ORDERS = "SUBSCRIPTION_ORDERS";

export const SUBSCRIPTION_ORDERS_MY_ACCOUNTS =
  "SUBSCRIPTION_ORDERS_MY_ACCOUNTS";

export const PRIVACY_POLICY_URL = `${Config.WP_URL}/${PERSONAL_DATA_POLICY}`;

export const BLOCKED_CANDIDATES = "blocked_candidates";

export const ADMIN_BLOCKED_CANDIDATES_LIST = "ADMIN_BLOCKED_CANDIDATES_LIST";

export const ADMIN_UN_BILLED_SERVICES = "ADMIN_UN_BILLED_SERVICES";

export const ADMIN_UN_BILLED_SUBSCRIPTIONS = "ADMIN_UN_BILLED_SUBSCRIPTIONS";

export const ADD_ON = "add-on";

export const ORDER_REPORT_ANCHOR_ID = "order-credit-report";

export const GENERATE_SHARE_REPORT_LINK = `${REPORTS}/generate-link`;

export const CANDIDATE_LOGIN = "CANDIDATE_LOGIN";

export const GENERATE_OTP_API = `/${AUTH}/generate-otp`;
