import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import createSubscriptionRequest from "@app/services/subscription/createSubscriptionRequest";

import Div from "@components/Div";
import EditFormDialog from "@components/EditFormDialog";
import ProgressSpinner from "@components/ProgressSpinner";

import { useToast } from "@hooks/useToast";

import { loadActiveSubscriptionData, loadServicesData } from "@utils/common";
import { SUBSCRIPTION_STATUS } from "@utils/constant";

import { ActiveSubscription, SubscriptionCard } from "../components";

import { ValidationSchema } from "./schema";
import { getSubscriptionConfig } from "./utils";

const SubscriptionTab = () => {
  const { messages } = useIntl();
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const { showSuccessToast, showErrorToast } = useToast();

  const [isFetching, setIsFetching] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [activePackage, setActivePackage] = useState({});
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);
  const [productId, setProductId] = useState("");
  const [showCreateSubscriptionModal, setShowCreateSubscriptionModal] =
    useState(false);
  const [activityLog, setActivityLog] = useState(false);

  const billingAccountIdRef = useRef("");

  const {
    customer: { id: customer_id = "" } = {},
    name: userName = "",
    email: userEmail = "",
    phone: userPhone = "",
  } = userInfo;

  const user = {
    name: userName,
    email: userEmail,
    phone: userPhone,
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setIsFetching(true);

        const [packagesList, activeSubscription] = await Promise.all([
          loadServicesData(customer_id),
          loadActiveSubscriptionData(userInfo),
        ]);

        const {
          status = null,
          product_id,
          orders = [],
        } = activeSubscription ?? {};

        setSubscriptionPackages(packagesList);
        setActivePackage(activeSubscription);
        setProductId(product_id);
        setActivityLog(orders);
        setSubscriptionStatus(status);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsFetching(false);
      }
    };

    fetchDetails();
  }, [customer_id, messages.exception_error_message, showErrorToast, userInfo]);

  const handleShowCreateSubscriptionModal = useCallback(
    ({ id = "", billing_account_id = "" }) => {
      if (id) {
        setProductId(id);
      }

      if (billing_account_id) {
        billingAccountIdRef.current = billing_account_id;
      }
      setShowCreateSubscriptionModal(!showCreateSubscriptionModal);
    },
    [showCreateSubscriptionModal]
  );

  const handleCreateSubscriptionrequest = async values => {
    try {
      setIsFetching(true);

      const payload = {
        ...values,
      };

      if (billingAccountIdRef.current) {
        payload.billing_account_id = billingAccountIdRef.current;
      }

      await createSubscriptionRequest(payload);

      showSuccessToast(messages.message_subscription_request_created);
    } catch (e) {
      showErrorToast(messages.error_try_later);
    } finally {
      setIsFetching(false);
    }
  };

  const { details } = getSubscriptionConfig(
    { ...activePackage, ...user },
    subscriptionPackages,
    productId
  );

  return (
    <Div>
      {showCreateSubscriptionModal && (
        <EditFormDialog
          onHide={handleShowCreateSubscriptionModal}
          onSubmit={handleCreateSubscriptionrequest}
          title={messages.title_subscription_request}
          description={messages.description_subscription_request}
          validationSchema={ValidationSchema}
          config={details}
        />
      )}

      {isFetching ? (
        <ProgressSpinner />
      ) : (
        <>
          {subscriptionStatus === SUBSCRIPTION_STATUS.TERMINATED && (
            <SubscriptionCard
              userInfo={userInfo}
              activePackage={activePackage}
              activityLogs={activityLog}
              terminated={true}
              onCreateSubscription={handleShowCreateSubscriptionModal}
            />
          )}

          {subscriptionStatus &&
            subscriptionStatus !== SUBSCRIPTION_STATUS.TERMINATED && (
              <ActiveSubscription
                activePackage={activePackage}
                subscriptionPackages={subscriptionPackages}
                onCreateSubscription={handleShowCreateSubscriptionModal}
              />
            )}

          {activePackage &&
            activePackage.status === SUBSCRIPTION_STATUS.ACTIVE && (
              <SubscriptionCard
                userInfo={userInfo}
                activePackage={false}
                activityLogs={activityLog}
                onCreateSubscription={handleShowCreateSubscriptionModal}
              />
            )}
        </>
      )}
    </Div>
  );
};

export default SubscriptionTab;
