import React from "react";
import PropTypes from "prop-types";

import OTPInput from "react-otp-input";
import styled from "styled-components";

import Div from "../Div";

const StyledInputCell = styled.input`
  margin: 0px 5px;
  border: 1px solid var(--turquoise);
  width: 40px !important;
  height: 50px;
  border-radius: 10px;
  font-family: inherit;
  font-weight: var(--semibold-weight);
  font-size: var(--fs-h2);
  color: var(--grey);
`;

const InputOtp = ({ name, numInputs, value, onChange, onPaste }) => {
  const renderInput = (inputProps, index) => {
    return (
      <>
        <StyledInputCell mx="10px" width="40px !important" {...inputProps} />

        {numInputs % 2 === 0 && index === numInputs / 2 - 1 && (
          <Div width="10px" height="1px" mx="5px" bg="var(--turquoise)" />
        )}
      </>
    );
  };

  return (
    <OTPInput
      shouldAutoFocus
      name={name}
      numInputs={numInputs}
      value={value}
      onChange={onChange}
      onPaste={onPaste}
      renderInput={renderInput}
    />
  );
};

InputOtp.propTypes = {
  name: PropTypes.string,
  numInputs: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
};

export default InputOtp;
