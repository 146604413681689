import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import CardWrapper from "@pages/admin/users/components/CardWrapper";
import { Formik, Form } from "formik";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import JsonForm from "@components/JsonForm/JsonForm";
import * as Yup from "yup";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import createBlockedCustomerService from "@app/services/auth/createBlockedCustomerService";
import { ValidateSwedishSSN } from "@utils/utils";


const BlockedCustomerAccountDialog = ({ handleClose = () => {} }) => {
  const { messages } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();

  const candidate = [
    {
      label: <FormattedMessage id="label_ssn" />,
      fieldType: "text",
      name: "ssn",
      maxLength:13,
      validation: Yup.string()
        .required(<FormattedMessage id="wrong_number" />)
        .test({
          name: "valid-ssn-number",
          message: <FormattedMessage id="wrong_number" />,
          test: ValidateSwedishSSN,
        }),
    },
    
  ];

  const initialValues = {
    ssn: "",
  };

  const formLayout = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gridColumnGap: 2,
  };

  const handleFormSubmit = async values => {
    setIsLoading(true);
    try {
      const {
        ssn,
      } = values;

      let payload = {
        ssn,
      };

    await createBlockedCustomerService(payload);

      showSuccessToast(messages.text_update_successful);
      handleClose();
    }  catch (e) {
      const errors = e.response?.data?.errors;

      if (errors) {
          const errorField = Object.keys(errors)[0];
          const errorMessage = errors[errorField]?.[0];
          const translatedMessage = messages[`${errorMessage}`];
          showErrorToast(translatedMessage || e.response.data.message);
      } else {
          showErrorToast("An unexpected error occurred.");
      }
    }
    setIsLoading(false);
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog
        title={messages.label_block_customers}
        onClose={handleClose}
      >
        <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
          {({ isSubmitting, dirty, isValid }) => (
            <Div>
              <Form>
                <CardWrapper title={messages.label_block_customers}>
                  <JsonForm config={candidate} formLayout={formLayout} />
                </CardWrapper>

                <Div
                  display="flex"
                  flexDirection={["column", "column", "row", "row"]}
                  alignItems="center"
                  justifyContent="center"
                  gridGap={4}
                  mt={4}
                >
                  <PrimaryButtonOutlined
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_cancel}
                    onClick={handleClose}
                  />
                  <PrimaryButton
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_create}
                    type="submit"
                    disabled={!dirty || isSubmitting || !isValid}
                  />
                </Div>
              </Form>
            </Div>
          )}
        </Formik>
      </FullSizeDialog>
    </Div>
  );
};

BlockedCustomerAccountDialog.propTypes = {
  handleClose: PropTypes.func,
};

export default BlockedCustomerAccountDialog;
