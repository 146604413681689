import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { ROUTES } from "@utils/constant";
import { isAuthenticated } from "@utils/utils";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { location, path, exact, computedMatch, isAdmin } = rest;
  const isAuthenticatedFlag = isAuthenticated();
  const publicRoutes = [
    ROUTES.HOME.URL,
    ROUTES.LOGIN.URL,
    ROUTES.REGISTER_ACCOUNT.URL,
    ROUTES.FORGOT_PASSWORD.URL,
    ROUTES.RESET_PASSWORD.URL,
    ROUTES.SET_PASSWORD.URL,
    ROUTES.PAGE_NOT_FOUND.URL,
    ROUTES.CHECKOUT.URL,
    ROUTES.ORDER_CONFIRMATION.URL,
    ROUTES.ORDER_RESULTS.URL,
    ROUTES.REGISTRATION_SUCCESSFUL.URL,
    ROUTES.USER_COMPLETE_REGISTRATION.URL,
    ROUTES.USER_REGISTRATION_COMPLETED.URL,
    ROUTES.USER_FORGOT_PASSWORD_SUCCESSFUL.URL,
    ROUTES.ADMIN_RESET_PASSWORD.URL,
    ROUTES.ADMIN_FORGOT_PASSWORD.URL,
    ROUTES.ADMIN_LOGIN.URL,
    ROUTES.ADMIN_REGISTRATION_COMPLETED.URL,
    ROUTES.ADMIN_FORGOT_PASSWORD_SUCCESSFUL.URL,
    ROUTES.ADMIN_COMPLETE_REGISTRATION.URL,
    ROUTES.REGISTER_WITH_BANKID.URL,
    ROUTES.SUBSCRIPTION_CHECKOUT.URL,
    ROUTES.ACCESSDENIED.URL,
    ROUTES.SUBSCRIPTION_ORDER_CONFIRMATION.URL,
    ROUTES.UPGRADE_SUBSCRIPTION.URL,
    ROUTES.REPORT_PDF.URL,
    ROUTES.CANDIDATE.URL,
    ROUTES.CANDIDATE_LOGIN.URL,
  ];
  const allRoutes = Object.values(ROUTES).map(({ URL }) => URL);

  const isPublicRoute = publicRoutes.includes(path);
  const isRouteMatched = allRoutes.includes(path);

  return (
    <Route
      location={location}
      exact={exact}
      path={path}
      computedMatch={computedMatch}
      isAdmin={isAdmin}
      render={props => (
        <>
          {!isRouteMatched && (
            <Redirect
              to={{
                pathname: ROUTES.PAGE_NOT_FOUND.URL,
              }}
            />
          )}

          {isPublicRoute || isAuthenticatedFlag ? (
            <Component
              history={props.history}
              location={props.location}
              match={props.match}
              staticContext={props.staticContext}
              isAdmin={isAdmin}
            />
          ) : (
            <Redirect
              to={{
                pathname: isAdmin ? ROUTES.ADMIN_LOGIN.URL : ROUTES.LOGIN.URL,
              }}
            />
          )}
        </>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  history: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.string,
  staticContext: PropTypes.string,
};

export default PrivateRoute;
