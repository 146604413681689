import React from "react";
import { useSelector } from "react-redux";

import ProgressSpinner from "@components/ProgressSpinner";

import Login from "@pages/auth/login/Login";

const UserLogin = () => {
  const { isFetching } = useSelector(state => state.authReducer);

  return isFetching ? <ProgressSpinner /> : <Login />;
};

export default UserLogin;
