import React, { useState } from "react";
import { useIntl } from "react-intl";

import PropTypes from "prop-types";

import generateCreditsReport from "@app/services/reports/generateCreditsReport";
import getReportList from "@app/services/reports/getReportList";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Checkbox from "@components/Checkbox";
import Div from "@components/Div";
import { TextMediumWeight, TextLargeSemiBoldWeight } from "@components/Text";

import Config from "@src/config";

import DoneModal from "./DoneModal";
import ErrorModal from "./ErrorModal";

export const OrderReportModal = ({
  reportID = "",
  objectData,
  handleConfirmPopup = {},
  reloadDetails = () => {},
}) => {
  const { messages } = useIntl();

  const [checked, setChecked] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailNotSent, setMailNotSent] = useState(false);

  const handleChange = e => {
    setChecked(e.checked);
  };

  const resendToMail = async () => {
    try {
      const {
        data: { data: [{ order_id = "" } = {}] = [] },
      } = await getReportList(
        `?filter[search][columns]=id,&filter[search][value]=${reportID}&fields=id,order_id`
      );

      const creditReportOrderItem = {
        service_id: Config.CREDIT_REPORT_PRODUCT_ID,
        personal_number: objectData.personal_number,
        person_name: objectData.person_name,
        email: objectData.email,
        phone: objectData.phone,
      };
      const payload = {
        order_items: [creditReportOrderItem],
      };

      await generateCreditsReport(order_id, payload);

      setMailSent(true);
      setMailNotSent(false);

      reloadDetails?.();
    } catch (e) {
      setMailNotSent(true);
      setMailSent(false);
    }
  };

  return (
    <>
      {!mailSent && !mailNotSent && (
        <Div mt={3} pt={1}>
          <Div display="flex" alignItems="center" flexDirection="column">
            <TextLargeSemiBoldWeight mb={2}>
              {messages.finansial_report_header}
            </TextLargeSemiBoldWeight>

            <TextMediumWeight my={3}>
              {messages.finansial_popup_para}
            </TextMediumWeight>

            <TextMediumWeight mb={3}>
              {messages.financial_popup_content}
            </TextMediumWeight>
          </Div>

          <Div display="flex" mt={2} mb={4} alignItems="flex-start">
            <Checkbox
              fontSize="var(--fs-text)"
              inputId="policy_check"
              checked={checked}
              onChange={handleChange}
            />
            <TextMediumWeight
              htmlFor="policy_check"
              ml={10}
              color="var(--grey-dark)"
            >
              {messages.finansial_popup_check_text}
            </TextMediumWeight>
          </Div>

          <Div
            px={3}
            max-width="250px"
            display={["block", "flex"]}
            alignItems="center"
            justifyContent="space-around"
          >
            <PrimaryButtonOutlined
              mr={2}
              mb={[2, 0]}
              minWidth="85px"
              minHeight="40px"
              width={[1, "auto"]}
              onClick={handleConfirmPopup}
            >
              {messages.label_cancel}
            </PrimaryButtonOutlined>

            <PrimaryButton
              py={2}
              px={4}
              width={[1, "200px"]}
              label={messages.label_send}
              onClick={resendToMail}
              disabled={!checked}
            />
          </Div>
        </Div>
      )}

      {mailSent && <DoneModal handleConfirmPopup={handleConfirmPopup} />}

      {mailNotSent && <ErrorModal handleConfirmPopup={handleConfirmPopup} />}
    </>
  );
};

OrderReportModal.propTypes = {
  reportID: PropTypes.string,
  objectData: PropTypes.obj,
  handleConfirmPopup: PropTypes.func,
  reloadDetails: PropTypes.func,
};

export default OrderReportModal;
