import * as Yup from "yup";

import { OTP_LENGTH } from "./constants";

export const OtpValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .required()
    .matches(/^\d{6}$/)
    .length(OTP_LENGTH),
});
