import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import PromptDialog from "@components/PromptDialog";

const GenerateLinkDialog = ({ onCancel, onConfirm }) => {
  const { messages } = useIntl();

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <PromptDialog
      title={messages.title_share_report}
      message={messages.message_share_report}
      confirmLabel={messages.label_generate_link}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  );
};

GenerateLinkDialog.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default GenerateLinkDialog;
