import React,  { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { BLOCKED_CANDIDATES } from "@utils/constant";
import blockedCandidateServices from "@app/services/blockedCandidates/blockedCandidateServices";
import AdminContainer from "@layout/AdminContainer";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";
import BlockedCustomerAccountDialog from "./BlockedCustomerAccountDialog";
import ProgressSpinner from "@components/ProgressSpinner";
import DeleteBlockedCandidates from "@pages/admin/blockedCandidates/Delete";
import Div from "@components/Div";
import ErrorDialog from "@components/ErrorDialog";
import deleteBlockedCandidateService from "./deleteBlockedCandidateService";
import { useToast } from "@hooks/useToast";


const List = () => {
  const { messages } = useIntl();
  const { hasAllPermissions } = useAuthorization();
  const [blockedCandidates, setBlockedCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const searchableColumns = ["id", "ssn"];
  const { showSuccessToast, showErrorToast } = useToast();

  const hasWriteCustomersPermission = hasAllPermissions([
    AdminPermissions.AdminWriteCustomers,
  ]);

  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false);
  const handleAddNewCustomer = () => {
    setShowAddCustomerDialog(!showAddCustomerDialog);
  };

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const blockedCandidateResponse = await blockedCandidateServices();
        const { data: { data: blockedCandidates = [] } = {} } = blockedCandidateResponse;
        const formattedGroups = blockedCandidates
          .map((o) => ({
            name: o?.ssn,
            code: o?.ssn,
          }))
          setBlockedCandidates(formattedGroups);
          setIsLoading(false);
      } catch (error) {
        showErrorToast(messages.exception_error_message);      }
    };
    fetchData();
  }, [showErrorToast,messages]);

  const handleDeleteUser = async () => {
    const ssn = selectedData.ssn || ""
    try {
      setIsLoading(true);

      await deleteBlockedCandidateService({ ssn: ssn });

      handleDeleteModal();
      showSuccessToast(messages.text_deleted_successful);
    } catch (e) {
      setErrorMessage(messages.exception_error_message);
      showErrorToast(messages.error_try_later);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const handleDeleteModal = (data = {}) => {
    setSelectedData(data);

    setShowDeleteModal(!showDeleteModal);
  };

  const dataTableConfig = {
    header: {
      title: "report_permissions",
      actions: [
        {
          id: "new-customer",
          type: "button",
          label: messages.label_add_blocked_candidates,
          onClick: handleAddNewCustomer,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasWriteCustomersPermission,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "blocked_candidates.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_ssn,
        id: "filter[ssn]",
        type: "multiselect",
        options: [
          {
            name: "filter[ssn]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[ssn]",
            options: blockedCandidates,
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${BLOCKED_CANDIDATES}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["fields", ["id", "ssn",]]],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        sortable: true,
        width: "100px",
      },
      {
        title: "label_ssn",
        db_field: "ssn",
        type: "text",
        sortable: true,
      },
      {
        type: "actions",
        width: "100px",
        actions: [
          {
            type: "button",
            icon: "menu-trash",
            onClick: handleDeleteModal,
            style: { marginRight: "30px" },      },
        ],
      },
      
    ],
  };

  let headerConfig = [
    {
      title: messages.label_blocked_candidates,
    },
  ];

  return (
    <AdminContainer config={headerConfig} small >
        {isLoading && <ProgressSpinner />}
    { !isLoading && <DataTable config={dataTableConfig} minWidth={"550px"}/>}
    {showAddCustomerDialog && (
        <BlockedCustomerAccountDialog handleClose={handleAddNewCustomer} />
      )}
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}
      {showDeleteModal && (
        <DeleteBlockedCandidates
          subUser
          onDelete={handleDeleteUser}
          onCancel={handleDeleteModal}
        >
          <Div
            variant="primary"
            justifyContent="center"
            display="flex"
            fontWeight="var(--medium-weight)"
            textAlign="center"
          >
            {messages.label_blocked_candidated_confirmation}
          </Div>
        </DeleteBlockedCandidates>
      )}
    </AdminContainer>
)
};

export default List;
