import config from "@src/config";
import { useIntl } from "react-intl";

const useRuleConfig = () => {
  const { messages } = useIntl(); 

  return {
    "logoPosition": "right",
    "pages": [
      {
        "name": "page1",
        "elements": [
          {
            "type": "panel",
            "name": "question2",
            "questionErrorLocation": "bottom",
            "elements": [
              {
                "type": "text",
                "name": "name",
                "title": messages.rule_name_label, 
                "hideNumber": true,
                "isRequired": true,
                "requiredErrorText": "Name is required.",
                "errorLocation": "bottom",
                "size": 0
              },
              {
                "type": "dropdown",
                "name": "type",
                "startWithNewLine": false,
                "title": messages.type_label,
                "hideNumber": true,
                "isRequired": true,
                "requiredErrorText": "Type is required.",
                "errorLocation": "bottom",
                "choices": [
                {
                  "value": "rule_type_grade_calculation",
                  "text": messages.rule_type_grade_calculation
                },
                {
                  "value": "rule_type_points_calculation",
                  "text": messages.rule_type_points_calculation
                },
                {
                  "value": "rule_type_deviations",
                  "text": messages.rule_type_deviations
                },
                {
                  "value": "rule_type_data",
                  "text": messages.rule_type_data
                },
                {
                  "value": "rule_type_score_calculation",
                  "text": messages.rule_type_score_calculation
                }
              ]
              },
              {
                "type": "tagbox",
                "name": "products",
                "title": messages.label_products,
                "hideNumber": true,
                "errorLocation": "bottom",
                // Using template literals for dynamic URL
                "choicesByUrl": {
                  url: `${config.BASE_URL}products?filter[type]=service&customer_id=&filter[state]=active`,
                  path: "data",
                  valueName: "id",
                  titleName: "name"
                }
              },
              {
                type: 'text',
                name: 'group',
                title: messages.permission_group,
                hideNumber: true
              },
              {
                type: 'text',
                name: 'slug',
                startWithNewLine: false,
                title: messages.label_slug_id,
                hideNumber: true,
                isRequired: true,
                requiredErrorText: 'Slug is required and must be unique'
              },
              {
                type: 'text',
                name: 'step',
                title: messages.step_lable,
                hideNumber: true,
                isRequired: true,
                requiredErrorText: 'Step is required.'
              }
            ]
          },
          {
            type: 'paneldynamic',
            name: 'properties',
            title: messages.label_properties_list,
            hideNumber: true,
            templateElements: [
              {
                type: 'text',
                name: 'property_name',
                title: messages.label_property_name,
                hideNumber: true,
                isRequired: true
              },
              {
                type: 'dropdown',
                name: 'property_type',
                startWithNewLine: false,
                title: messages.type_label,
                isRequired: true,
                "choices": [
                {
                  "value": "rule",
                  "text": messages.type_option_rule_label
                },
                {
                  "value": "value",
                  "text": messages.type_option_value_label
                }
              ]
              },
              {
               type: 'comment',
               name: 'rule',
               visibleIf: '{panel.property_type} = \'rule\'',
               title: messages.title_data,
               isRequired: true
              },
              {
               type: 'comment',
               name: 'value',
               visibleIf: '{panel.property_type} = \'value\'',
               title: messages.title_data,
               isRequired: true
              }
            ],
            panelCount: 1,
            minPanelCount: 1,
            templateErrorLocation: 'bottom',
            panelRemoveButtonLocation: 'right'
          }
        ]
      }
    ]
  };
};

export default useRuleConfig;