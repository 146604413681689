import React from "react";
import { PropTypes } from "prop-types";
import Container from "@components/Container";
import ScrollToTop from "@utils/ScrollToTop";
import Div from "@components/Div";
import AdminHeaderMain from "./AdminHeaderMain";

const AdminContainer = (props) => {
  const { wide, small } = props;

  let width;
  if (small) {
    width = "550px";
  } else if (wide) {
    width = "1320px";
  } else {
    width = "1110px";
  }  
  return (
    <ScrollToTop>
      <Div
        backgroundColor={"var(--white)"}
        borderBottom={"2px solid var(--grey-lightest)"}
        width={[
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw - 192px)",
        ]}
      >
        <AdminHeaderMain {...props} />
      </Div>

      <Div
        mt={["16px", "16px", "16px", "16px"]}
        mx={["16px", "16px", "16px", "32px"]}
        maxWidth={["100%", "100%", "100%", width]}
      >
        <Container m="0 !important">{props.children}</Container>
      </Div>
    </ScrollToTop>
  );
};

AdminContainer.propTypes = {
  config: PropTypes.object,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  wide: PropTypes.bool,
  small: PropTypes.bool,

};

export default AdminContainer;
