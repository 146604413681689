import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import Breadcrumb from "@components/Breadcrumb";
import Tabs from "@components/Tabs";

import UserContainer from "@layout/UserContainer";

import { ROUTES } from "@utils/constant";

import SubscriptionOrders from "./components/SubscriptionOrders";

import SubscriptionTab from "./tabs/SubscriptionTab";

const TAB_CONTENT = [
  {
    title: <FormattedMessage id="subscription" />,
    content: <SubscriptionTab />,
    url: ROUTES.SUBSCRIPTION.URL,
  },
  {
    title: <FormattedMessage id="title_orders" />,
    content: <SubscriptionOrders />,
    url: ROUTES.SUBSCRIPTION_ORDERS_MY_ACCOUNTS.URL,
  },
];

const Subscription = () => {
  const history = useHistory();
  const { messages } = useIntl();

  const _activeTabIndex = Math.max(
    TAB_CONTENT.findIndex(item => item.url === history?.location?.pathname),
    0
  );

  const [activeIndex, setActiveIndex] = useState(_activeTabIndex);

  const headerConfig = {
    title: messages.subscription,
  };

  const breadCrumbItems = [
    {
      label: messages.my_profile,
    },
    { label: messages.subscriptions_title },
  ];

  const handleTabChange = event => {
    const { index } = event;
    const targetUrl = TAB_CONTENT[index]?.url;

    const targetIndex = Math.max(
      TAB_CONTENT.findIndex(({ url }) => url === targetUrl),
      0
    );

    setActiveIndex(targetIndex);

    history.push(targetUrl);
  };

  return (
    <UserContainer config={headerConfig}>
      <Breadcrumb p={0} mb={3} items={breadCrumbItems} />

      <Tabs
        content={TAB_CONTENT}
        onTabChange={handleTabChange}
        activeIndex={activeIndex}
      />
    </UserContainer>
  );
};

export default Subscription;
