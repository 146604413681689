import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { PrimaryButton } from "@components/Button";
import CardV2 from "@components/CardV2";
import Div from "@components/Div";
import { H4 } from "@components/Heading";
import Icon, { Ellipse24Icon } from "@components/Icon";
import { LinkArrow } from "@components/Link";
import { Text } from "@components/Text";

import { useOrder } from "@contexts/OrderContext";

import useAuthorization from "@hooks/useAuthorization";

import { CustomerPermissions } from "@src/enum/Permissions";

import { KLARNA_CHECKOUT } from "@utils/constant";

import BillingInformation from "./orderSummary/BillingInformation";
import CouponCodeInput from "./orderSummary/CouponCodeInput";
import CouponCodeModal from "./orderSummary/CouponCodeModal";
import Footer from "./orderSummary/Footer";
import Header from "./orderSummary/Header";
import OrderSummaryCard from "./orderSummary/OrderSummaryCard";
import TotalOrderSummary from "./orderSummary/TotalOrderSummary";
import { SubscriptionRequestDialog } from "@components/SubscriptionRequest";

const OrderSummary = ({
  orderSummaryProps = [],
  paymentMethod = "",
  isInvoice = false,
  isCreditsExceed = false,
  isCreditOnly = false,
  isPriceOnly = false,
  isCreditsAndPrice = false,
  orderTotalCredit = 0,
  orderTotalVatPrice = 0,
  orderTotalPriceWithoutVat = 0,
  orderTotalPriceWithVat = 0,
  orderTotalDiscount = 0,
  orderTotalDiscountPercentage = 0,
  promoCode = "",
  couponCodeValidationError = "",
  validateCouponCode = () => {},
  isBillingAccountInfoVisible = false,
  billingAccountId = null,
  onBillingAccountIdChange = () => {},
}) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { orderUser } = useOrder();
  const isConsent = useSelector(state => state.authReducer).consent;

  const [isCouponCodeModalVisible, setIsCouponCodeModalVisible] =
    useState(false);
  const [isOrderReadyToProceed, setIsOrderReadyToProceed] = useState(false);
  const [
    isSubscriptionRequestDialogVisible,
    setIsSubscriptionRequestDialogVisible,
  ] = useState(false);

  const hasPricesPermission = hasAllPermissions([
    CustomerPermissions.CustomerReadPrices,
  ]);
  const isTwoStepCheckout = paymentMethod === KLARNA_CHECKOUT;

  const handleCouponCodeValidation = couponCode => {
    validateCouponCode(
      orderSummaryProps,
      couponCode,
      orderTotalPriceWithoutVat,
      paymentMethod,
      billingAccountId
    );
  };

  const handleConfirmPayment = () => {
    setIsOrderReadyToProceed(!isOrderReadyToProceed);
  };

  useEffect(() => {
    setIsCouponCodeModalVisible(couponCodeValidationError !== null);
  }, [couponCodeValidationError]);

  const handleOpenSubscriptionRequestDialog = () => {
    setIsSubscriptionRequestDialogVisible(true);
  };

  const handleCloseSubscriptionRequestDialog = () => {
    setIsSubscriptionRequestDialogVisible(false);
  };

  const renderUpgradePlan = () => (
    <Div
      display="flex"
      alignItems="center"
      mb={4}
      p={3}
      borderColor="var(--turquoise)"
      borderWidth="1px"
      borderStyle="dashed"
      gridGap="10px"
      borderRadius="10px"
    >
      <Ellipse24Icon
        name="sign"
        px={"8px !important"}
        py={"8px !important"}
        backgroundColor="var(--grey-lightest) !important"
        color="var(--blue-dark)"
      />

      <Div
        display="flex"
        alignItems={["flex-start", "flex-start", "center", "center"]}
        justifyContent="space-between"
        flexDirection={["column", "column", "row", "row"]}
        gridGap={2}
        width={1}
      >
        <Text textAlign="left">{messages.search_label_not_enough_credits}</Text>

        <LinkArrow
          label={messages.contact_us}
          direction="right"
          variant="secondary"
          onClick={handleOpenSubscriptionRequestDialog}
          flexWrap="nowrap"
          whiteSpace="nowrap"
        >
          <Icon ml={1} name="headerarrowright" />
        </LinkArrow>
      </Div>
    </Div>
  );

  const handleCloseCouponModal = () => {
    setIsCouponCodeModalVisible(false);
  };

  return (
    <>
      {!(isOrderReadyToProceed && isTwoStepCheckout) && (
        <>
          {isCreditsExceed && renderUpgradePlan()}

          {isSubscriptionRequestDialogVisible && (
            <SubscriptionRequestDialog
              onHide={handleCloseSubscriptionRequestDialog}
            />
          )}

          {orderSummaryProps.length > 1 && (
            <H4 mb={3}>{messages.byline_checkout}</H4>
          )}

          <Div display="flex" flexDirection="column" gridGap={4}>
            {orderSummaryProps.map(list => {
              return (
                <CardV2
                  key={list.name}
                  header={
                    <Header
                      ssn={list.ssn}
                      name={list.name}
                      email={list.email}
                      isConsent={isConsent}
                    />
                  }
                  width={[1]}
                >
                  <OrderSummaryCard
                    selectedServices={list?.services}
                    isCreditOnly={isCreditOnly}
                    isPriceOnly={isPriceOnly}
                  />
                </CardV2>
              );
            })}
          </Div>

          {isBillingAccountInfoVisible && (
            <BillingInformation
              customerId={orderUser?.customer?.id}
              onSelectBillingAccount={onBillingAccountIdChange}
            />
          )}

          {hasPricesPermission && (
            <CardV2
              mt={4}
              mb={3}
              header={
                <Div>
                  {hasPricesPermission && (
                    <Div
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <CouponCodeInput
                        hasError={!!couponCodeValidationError}
                        onCouponCodeSubmitted={handleCouponCodeValidation}
                      />
                    </Div>
                  )}
                </Div>
              }
            >
              <Footer
                isCreditOnly={isCreditOnly}
                isCreditsAndPrice={isCreditsAndPrice}
                orderTotalCredit={orderTotalCredit}
                orderTotalVatPrice={orderTotalVatPrice}
                orderTotalPriceWithoutVat={orderTotalPriceWithoutVat}
                orderTotalPriceWithVat={orderTotalPriceWithVat}
                orderTotalDiscount={orderTotalDiscount}
                orderTotalDiscountPercentage={orderTotalDiscountPercentage}
              />
            </CardV2>
          )}

          {isCouponCodeModalVisible && (
            <CouponCodeModal
              hideModal={handleCloseCouponModal}
              errorKey="code_not_valid"
            />
          )}

          {!isOrderReadyToProceed && isTwoStepCheckout && (
            <PrimaryButton px={30} height={40} onClick={handleConfirmPayment}>
              {messages.label_payment}
            </PrimaryButton>
          )}
        </>
      )}

      {(isOrderReadyToProceed || !isTwoStepCheckout) && (
        <TotalOrderSummary
          paymentMethod={paymentMethod}
          isInvoice={isInvoice}
          isCreditOnly={isCreditOnly}
          isConsent={isConsent}
          promoCode={promoCode}
          billingAccountId={billingAccountId}
        />
      )}
    </>
  );
};

OrderSummary.propTypes = {
  orderSummaryProps: PropTypes.array,
  paymentMethod: PropTypes.string,
  isInvoice: PropTypes.bool,
  isCreditsExceed: PropTypes.bool,
  isCreditOnly: PropTypes.bool,
  isPriceOnly: PropTypes.bool,
  isCreditsAndPrice: PropTypes.bool,
  orderTotalCredit: PropTypes.number,
  orderTotalVatPrice: PropTypes.number,
  orderTotalPriceWithoutVat: PropTypes.number,
  orderTotalPriceWithVat: PropTypes.number,
  orderTotalDiscount: PropTypes.number,
  orderTotalDiscountPercentage: PropTypes.number,
  promoCode: PropTypes.string,
  couponCodeValidationError: PropTypes.string,
  validateCouponCode: PropTypes.func,
  isBillingAccountInfoVisible: PropTypes.bool,
  billingAccountId: PropTypes.string,
  onBillingAccountIdChange: PropTypes.func,
};

export default OrderSummary;
