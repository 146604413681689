import React from "react";
import { useIntl } from "react-intl";

import { default as Div } from "@components/Div";
import { H2 } from "@components/Heading";

import {
  MyProfileAccountSecurityCard,
  MyProfileDeleteAccountCard,
  MyProfileUserInfoCard,
} from "./components";

export const MyProfile = () => {
  const { messages } = useIntl();

  return (
    <Div py={3} display="flex" flexDirection="column" gridGap={3}>
      <H2 display={["flex", "flex", "flex", "none"]}>
        {messages.personal_setting}
      </H2>

      <MyProfileUserInfoCard />

      <MyProfileAccountSecurityCard />

      <MyProfileDeleteAccountCard />
    </Div>
  );
};

export default MyProfile;
