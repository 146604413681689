import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { fetchAllProducts } from "@app/services/services/fetchProductById";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { formatData, getConfig } from "./utils";

const useProductDetails = ({ data, dataSourceTypes,legalFrameworks, onUpdateService }) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { showErrorToast } = useToast();

  const [caseProducts, setCaseProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const hasProductsPermission = hasAllPermissions([
    AdminPermissions.AdminWriteProducts,
  ]);

  useEffect(() => {
    const fetchCaseProducts = async () => {
      try {
        setIsLoading(true);

        const { data: { data: productsData = [] } = {} } =
          await fetchAllProducts("");
        const caseProductsData = productsData.filter(({ is_case }) =>
          Boolean(is_case)
        );

        setCaseProducts(caseProductsData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCaseProducts();
  }, [messages.exception_error_message, showErrorToast]);

  const formattedData = formatData(
    data,
    dataSourceTypes,
    legalFrameworks,
    caseProducts,
    messages
  );

  const { details, description, configuration, addOnProducts } = getConfig(
    formattedData,
    caseProducts
  );

  const handleUpdateService = values => {
    onUpdateService(values);
  };

  const handleUpdateConfiguration = ({
    is_case,
    is_report,
    add_on_products,
    ...values
  }) => {
    const payload = {
      ...values,
      is_case: Number(is_case),
      is_report: Number(is_report),
      add_on_products: add_on_products?.map(({ id }) => id),
    };

    handleUpdateService(payload);
  };

  return {
    config: { details, description, configuration, addOnProducts },
    hasPermission: hasProductsPermission,
    isLoading,
    productData: formattedData,
    handleUpdateService,
    handleUpdateConfiguration,
  };
};

useProductDetails.propTypes = {
  data: PropTypes.object,
  dataSourceTypes: PropTypes.array,
  legalFrameworks: PropTypes.array,
  onUpdateService: PropTypes.func,
};

export default useProductDetails;
