import React from "react";
import { FormattedMessage } from "react-intl";

import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
  email: Yup.string().required(
    <FormattedMessage id="validation_valid_email_input" />
  ),
  phone: Yup.string()
    .typeError(<FormattedMessage id="validation_valid_phone_number" />)
    .required(<FormattedMessage id="validation_empty_phone_number" />),
  name: Yup.string().required(<FormattedMessage id="validation_empty_name" />),
  product_id: Yup.string().required(
    <FormattedMessage id="error_product_name" />
  ),
});
