import Axios from "@app/api/axios";
import { RULES } from "@utils/constant";

const rulesService = (query) =>
  Axios.get(`${RULES}?per_page=1000&${query}`); 

export const getRulesServiceById = (id) => {
  return Axios.get(`${RULES}?filter[id]=${id}&include=products`)
    .then(({ data: { data } }) => { 
      const [ruleData] = data; 
      return ruleData; 
    })
};


export default rulesService;
