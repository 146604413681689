import { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import * as Yup from "yup";

import createNewProduct from "@app/services/services/createNewProduct";
import {fetchAllProducts} from "@app/services/services/fetchProductById";
import getDataSources from "@app/services/products/getDataSources";

import { useToast } from "@hooks/useToast";

import { SERVICE_TYPE } from "@utils/enum";

import {
  SERVICE_TYPE_OPTIONS,
  ANALYSIS_TYPES,
  COURT_PACKAGE_TYPES_OPTIONS,
  PROCESS_TYPES_OPTIONS,
  FORTNOX_OPTIONS,
  CASE_OPTIONS,
  SELECT_OPTIONS,
  AVAILABLE_OPTIONS,
} from "./types";
import { preparePayload } from "./utils";

const useCreateService = () => {
  const { messages } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceTypes, setDataSourceTypes] = useState([]);
  const [servicesInBundle, setServicesInBundle] = useState([]);

  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    const fetchData = async () => {

      try {
        setIsLoading(true);

        const [
          { data: dataSources = [] },
          { data: { data: servicesData = [] } = {} },
        ] = await Promise.all([getDataSources(), fetchAllProducts({type:"service"})]);

        const formattedDataSources = dataSources.map(dataSource => ({
          label: messages[`datasource_${dataSource}`] ?? "",
          code: dataSource,
        }));
        const filteredServices = servicesData
          .filter(({ type }) => type === "service")
          .map(({ id, name }) => ({
            label: name,
            code: id,
          }));
        setServicesInBundle(filteredServices);
        setDataSourceTypes(formattedDataSources);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [messages, showErrorToast]);

  const productInformation = [
    {
      name: "name",
      label: messages.label_service_name,
      fieldType: "text",
      validation: Yup.string().required(messages.error_product_name),
    },
    {
      name: "price",
      label: messages.watchlist_label_price,
      fieldType: "text",
      validation: Yup.string().required(messages.error_price_validation),
    },
    {
      name: "delivery_time",
      label: messages.delivery_time_label,
      fieldType: "text",
      validation: Yup.string().required(messages.error_delivery_time),
    },
    {
      name: "listing_order",
      label: messages.label_listing_order,
      fieldType: "text",
      validation: Yup.number()
        .typeError(messages.error_listing_order)
        .integer(messages.error_listing_order_type)
        .min(1, messages.error_listing_order_min_number)
        .max(999, messages.error_listing_order_max_number),
    },
    {
      name: "sku",
      label: messages.label_sku,
      fieldType: "text",
    },
    {
      name: "default_selected",
      label: messages.label_default_selected,
      fieldType: "dropdown",
      options: SELECT_OPTIONS,
      validation: Yup.boolean(),
    },
    {
      name: "default_available",
      label: messages.label_default_availability,
      fieldType: "dropdown",
      options: AVAILABLE_OPTIONS,
      validation: Yup.boolean(),
    },
  ];

  const descInformation = [
    {
      name: "description",
      label: messages.title_product_description,
      fieldType: "textarea",
      validation: Yup.string().required(messages.error_product_description),
    },
  ];

  const configurationInformation = [
    {
      name: "service_type",
      label: messages.label_product_type_form,
      fieldType: "dropdown",
      options: SERVICE_TYPE_OPTIONS,
      values: "service_type",
      validation: Yup.string().required(messages.error_service_type),
    },
    {
      name: "analysis_type",
      label: messages.label_analysis_type,
      fieldType: "dropdown",
      options: ANALYSIS_TYPES,
      show: values => values.service_type === SERVICE_TYPE.ANALYSIS,
      values: "analysis_type",
      validation: Yup.string().when("service_type", {
        is: serviceType => {
          return serviceType === "analysis";
        },
        then: () => Yup.string().required(messages.error_analysis_type),
        otherwise: () => Yup.string(),
      }),
    },
    {
      name: "data_source",
      label: messages.label_data_source,
      fieldType: "multiselect",
      options: dataSourceTypes || [],
      show: values => values.service_type === SERVICE_TYPE.ANALYSIS,
      onChange: { setDataSourceTypes },
      validation: Yup.array(),
    },
    {
      label: messages.label_add_on_products,
      fieldType: "multiselect",
      name: "add_on_products",
      placeholder: messages.placeholder_choose,
      options: servicesInBundle,
      value: "add_on_products",
      validation: Yup.array(),
    },
    {
      name: "child",
      label: messages.label_services_in_bundle,
      fieldType: "multiselect",
      options: servicesInBundle,
      show: values => values.service_type === SERVICE_TYPE.BUNDLE,
      validation: Yup.array().when("service_type", {
        is: serviceType => serviceType === "bundle",
        then: () =>
          Yup.array()
            .required(messages.error_service_in_bundle)
            .min(1, messages.error_service_in_bundle),
        otherwise: () => Yup.array(),
      }),
    },
    {
      label: messages.court_package,
      fieldType: "dropdown",
      name: "court_package",
      placeholder: messages.court_package,
      options: COURT_PACKAGE_TYPES_OPTIONS,
      show: values =>
        values.data_source?.includes("court") &&
        values.service_type === SERVICE_TYPE.ANALYSIS,
      value: "court_package",
      validation: Yup.string().required(messages.label_required),
    },
    {
      label: messages.label_process_type,
      fieldType: "dropdown",
      name: "process_type",
      placeholder: messages.label_process_type,
      options: PROCESS_TYPES_OPTIONS,
      value: "process_type",
      validation: Yup.string().required(messages.label_required),
    },
    {
      label: messages.label_fortnox_article_number,
      fieldType: "text",
      name: "fortnox_article_number",
      placeholder: messages.label_fortnox_article_number,
      options: FORTNOX_OPTIONS,
      value: "fortnox_article_number",
    },
    {
      label: messages.label_case,
      fieldType: "dropdown",
      name: "is_case",
      placeholder: messages.label_case,
      options: CASE_OPTIONS,
      value: "is_case",
      validation: Yup.string().required(messages.label_required),
    },
    {
      label: messages.label_report,
      fieldType: "dropdown",
      name: "is_report",
      placeholder: messages.label_report,
      options: CASE_OPTIONS,
      value: "is_report",
      validation: Yup.string().required(messages.label_required),
    },
  ];

  const handleCreateService = async (values, onSubmit) => {
    try {
      setIsLoading(true);

      const payload = preparePayload(values);

      await createNewProduct(payload);

      showSuccessToast(messages.text_update_successful);

      onSubmit?.();
    } catch (e) {
      showErrorToast(messages.text_no_data_found);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    config: {
      productInformation,
      descInformation,
      configurationInformation,
    },
    isLoading,
    handleCreate: handleCreateService,
  };
};

export default useCreateService;
