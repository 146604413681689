import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import EditFormDialog from "@components/EditFormDialog";
import ProgressSpinner from "@components/ProgressSpinner";

import { ValidationSchema } from "./schema";
import { useSubscriptionRequest } from "./useSubscriptionRequest";
import { getSubscriptionConfig } from "./utils";

const SubscriptionRequestDialog = ({ onHide }) => {
  const { messages } = useIntl();
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const {
    isLoading,
    activePackage,
    subscriptionPackages,
    createSubscriptionRequest,
  } = useSubscriptionRequest(userInfo);

  const subscriptionConfigData = {
    activePackageId: Number(activePackage?.id),
    userName: userInfo?.name,
    userEmail: userInfo?.email,
    userPhone: userInfo?.phone,
  };

  const { details } = getSubscriptionConfig(
    subscriptionConfigData,
    subscriptionPackages
  );

  const handleCreateSubscriptionRequest = async values => {
    await createSubscriptionRequest(values, onHide);
  };

  return isLoading ? (
    <ProgressSpinner />
  ) : (
    <EditFormDialog
      title={messages.title_subscription_request}
      description={messages.description_subscription_request}
      config={details}
      validationSchema={ValidationSchema}
      onHide={onHide}
      onSubmit={handleCreateSubscriptionRequest}
    />
  );
};

SubscriptionRequestDialog.propTypes = {
  onHide: PropTypes.func,
};

export default SubscriptionRequestDialog;
