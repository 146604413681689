import React from "react";
import { useIntl } from "react-intl";

import BankIDLogin from "@components/BankidComponent/BankIDLogin";
import { PrimaryButtonIconOutlined } from "@components/Button";
import Div from "@components/Div";
import { H2, H4 } from "@components/Heading";
import { TextMediumWeight } from "@components/Text";

import { BANK_ID_SCOPES } from "@utils/enum";

import {
  useCandidateLoginSharedData,
  useCandidateLoginStepper,
} from "../context";

const CandidateLoginOptions = () => {
  const { messages } = useIntl();
  const { sharedData } = useCandidateLoginSharedData();
  const { onNextStep } = useCandidateLoginStepper();

  const { candidateId, onSuccess, onError } = sharedData;

  return (
    <>
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridGap={3}
      >
        <H2 color="var(--blue-dark) !important">{messages.login}</H2>

        <TextMediumWeight textAlign="center" width="90%">
          {messages.message_candidate_portal_login}
        </TextMediumWeight>

        <BankIDLogin
          candidateId={candidateId}
          scope={BANK_ID_SCOPES.LOGIN_CANDIDATE}
          onSuccess={onSuccess}
          onError={onError}
        />
      </Div>

      <Div width={1} height={1} bg="var(--grey-lightest)" />

      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridGap={3}
      >
        <H4>{messages.heading_dont_have_ssn}</H4>

        <PrimaryButtonIconOutlined
          rounded
          height={40}
          icon="icon-headerarrowright"
          iconPos="right"
          label={messages.label_use_email_to_login}
          onClick={onNextStep}
        />
      </Div>
    </>
  );
};

export default CandidateLoginOptions;
