import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import { SurveyForm } from "@components/Survey";
import { useIntl } from "react-intl";
import updateRulesService from "@app/services/rules/updateRulesService";
import useRuleConfig from "./ruleConfig"
import { getRulesServiceById } from "@app/services/rules/rulesService";
import CardWrapper from "@pages/admin/users/components/CardWrapper";
import { H4 } from "@components/Heading";

const StyledCreateBilling = styled.div`
  width: 1110px;
`;

const EditRuleDialog = ({ editRuleId, handleClose = () => {} }) => {
    const ruleConfig = useRuleConfig();

  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const surveyRef = useRef(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const [ruleData, setRuleData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ruleData = await getRulesServiceById(editRuleId);
        const productIdsArray = ruleData.products.map(product => product.id);
        const singleRule = {
          name: ruleData.name,
          type: ruleData.type,
          step: ruleData.step,
          group: ruleData.group,
          products: productIdsArray,
          slug: ruleData.slug,
          properties: JSON.parse(ruleData.properties),
          };
        setRuleData(singleRule);
      } catch (error) {
        setRuleData([]);
      }
    };
    fetchData();
  }, [editRuleId]);


const handleUpdateCase = useCallback(
    async data => {
      try {
        setIsLoading(true);

        const payload = {
          name: data.name,
          type: data.type,
          step: data.step,
          group: data.group,
          products: data.products,
          slug: data.slug,
          properties: JSON.stringify(data.properties),
          };
          await updateRulesService(editRuleId, payload);
          showSuccessToast(messages.label_rule_added);
          handleClose();
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [ messages, showErrorToast, handleClose, showSuccessToast, editRuleId]
  );

const handleCompleteSurvey = useCallback(
    survey => {
      handleUpdateCase(survey);
    },
    [handleUpdateCase]
  );

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    handleUpdateCase(data);
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog
        title={messages.view_edit_rule_lable}
        onClose={handleClose}
      >
        <StyledCreateBilling>
         <Div bgColor="var(--white)">
            <SurveyForm
            data={ruleData}
            formConfig={ruleConfig}
            onComplete={handleCompleteSurvey}
            onRefLoaded={handleLoadSurveyRef}
            />
        </Div>

        <Div width={"1030px"} mx="auto" mb={4}>
        <CardWrapper title={messages.label_ai_generated_content}>
        <Div
          width={1}
        >
          <Div mt={3}>
          <H4>{messages.label_summary}:</H4>
          {ruleData.ai_summary}
          </Div>

          <Div mt={3}>
          <H4>{messages.label_detailed_explanation}:</H4>
          {ruleData.ai_detailed_explanation}
          </Div>
          
        </Div>
        </CardWrapper>
        </Div>
      
      {!isLoading && (
        <Div
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          gridGap={3}
          ml={4}
        >
            <PrimaryButton
              label={messages.label_save}
              onClick={handleSave}
            />

            <PrimaryButtonOutlined
              backgroundColor="transparent !important"
              minWidth="150px"
              label={messages.label_back}
              onClick={handleClose}
            />
        </Div>
      )} 
      </StyledCreateBilling>
      </FullSizeDialog>
    </Div>
  );
};

EditRuleDialog.propTypes = {
  handleClose: PropTypes.func,
  editRuleId: PropTypes.string
};

export default EditRuleDialog;
