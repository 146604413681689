import React from "react";
import PropTypes from "prop-types";

import { Password as PRPassword } from "primereact/password";
import styled from "styled-components";

import Icon from "@components/Icon";

import InputWrapper from "./InputWrapper";

const StyledPassword = styled(PRPassword)`
  height: 48px;
  margin-top: 4px;
`;

const InputPassword = props => {
  const { name, value, placeholder, onChange, disabled } = props;

  return (
    <InputWrapper {...props}>
      <StyledPassword
        toggleMask
        feedback={false}
        showIcon={
          <Icon
            name="showeye"
            color="var(--turquoise)"
            fontSize="var(--fs-icon-m)"
          />
        }
        hideIcon={
          <Icon
            name="hideeye"
            color="var(--turquoise)"
            fontSize="var(--fs-icon-m)"
          />
        }
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
    </InputWrapper>
  );
};

InputPassword.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  optionLabel: PropTypes.string,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  options: PropTypes.array,
  formikProps: PropTypes.object,
  disabled: PropTypes.bool,
  itemTemplate: PropTypes.node,
  delay: PropTypes.number,
};

export default InputPassword;
