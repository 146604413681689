import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import customerDetailsService from "@app/services/customers/customerDetailsService";
import terminateSubscription from "@app/services/subscription/terminateSubscription";
import updateSubscription from "@app/services/subscription/updateSubscription";

import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import SubscriptionUpdateValidation from "@pages/auth/schema/SpecialSubscriptionValidation";
import BillingInformation from "@pages/shared/CreateOrderWizard/orderSummary/BillingInformation";

import { AdminPermissions } from "@src/enum/Permissions";

import { loadActiveSubscriptionData, loadServicesData } from "@utils/common";
import { INPUT, SPECIAL_PACKAGE, SUBSCRIPTION_STATUS } from "@utils/constant";

import { SubscriptionActivityLog } from "./components";
import { buildPayload, getCustomerConfig, getPackageConfig } from "./utils";

const { AVAILABLE_ANALYSIS, TOTAL_ANALYSIS, PRICE } = INPUT.NAME;

const SelectedSubscriptionDetails = ({ customerId, subscriptionId }) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { email = "" } = useSelector(state => state.authReducer.userInfo) ?? {};
  const { showSuccessToast, showErrorToast } = useToast();

  const [subscriptionData, setSubscriptionData] = useState({});
  const [activityLogsData, setActivityLogsData] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState({});

  const isLoaded = useMemo(
    () => Object.keys(subscriptionData).length !== 0,
    [subscriptionData]
  );

  const hasWriteSubscriptionPermission = hasAllPermissions([
    AdminPermissions.AdminWriteSubscription,
  ]);

  if (subscriptionData?.name === SPECIAL_PACKAGE) {
    SubscriptionUpdateValidation.concat(
      SubscriptionUpdateValidation.pick([AVAILABLE_ANALYSIS])
    )
      .concat(SubscriptionUpdateValidation.pick([TOTAL_ANALYSIS]))
      .concat(SubscriptionUpdateValidation.pick([PRICE]));
  }

  const fetchDetails = useCallback(async () => {
    try {
      setIsLoading(true);

      const [
        subscriptionResponse = {},
        packagesListResponse = [],
        customerResponse = {},
      ] = await Promise.all([
        loadActiveSubscriptionData(
          {},
          `&filter[id]=${subscriptionId}&include=auditLogs,customer`
        ),
        loadServicesData(customerId),
        customerDetailsService(customerId),
      ]);

      const { audit_logs: activityLogs = [] } = subscriptionResponse ?? {};
      const { name: activeSubscriptionName } =
        packagesListResponse?.find(
          ({ id }) => id === subscriptionResponse?.id
        ) ?? {};
      const { data: { data: customerData = {} } = {} } = customerResponse;

      setSubscriptionData({
        ...subscriptionResponse,
        name: activeSubscriptionName,
      });
      setActivityLogsData(activityLogs);
      setPackagesData(packagesListResponse);
      setCustomerData(customerData);
    } catch {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  }, [
    customerId,
    messages.exception_error_message,
    showErrorToast,
    subscriptionId,
  ]);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    fetchDetails();
  }, [fetchDetails, customerId]);

  const handleOnStatusChange = (event, formikValues) => {
    const { value } = event;
    const { values, setValues } = formikValues;
    const { email_notification = true } = values;
    setValues({
      ...values,
      status: value,
      email_notification:
        value === SUBSCRIPTION_STATUS.TERMINATED
          ? email_notification
          : undefined,
    });
  };

  const { details } = getCustomerConfig(customerData);
  const { packages, settings } = getPackageConfig(
    subscriptionData,
    packagesData,
    handleOnStatusChange
  );

  const updateSubscriptionPackage = async payload => {
    try {
      setIsLoading(true);

      await updateSubscription(payload, subscriptionData.id);

      fetchDetails();

      showSuccessToast(messages.subscription_updated_success_message);
    } catch (err) {
      showErrorToast(messages.error_try_later);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateSubscriptionPackage = async values => {
    const updatedSubscriptionData = { ...subscriptionData, ...values };
    const subscriptionPayload = buildPayload(
      updatedSubscriptionData,
      customerId,
      email
    );

    await updateSubscriptionPackage(subscriptionPayload);
  };

  const handleUpdateSettings = async values => {
    try {
      setIsLoading(true);

      const shouldTerminateSubscription =
        values?.status === SUBSCRIPTION_STATUS.TERMINATED &&
        subscriptionData?.status !== SUBSCRIPTION_STATUS.TERMINATED;

      if (shouldTerminateSubscription) {
        const params = `status=${subscriptionData.status}&email_notification=${values.email_notification}`;
        await terminateSubscription(subscriptionData.id, params);

        showSuccessToast(messages.subscription_updated_success_message);

        fetchDetails();
      } else {
        await handleUpdateSubscriptionPackage(values);
      }
    } catch (e) {
      showErrorToast(messages.error_try_later);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectBillingAccount = async billingAccountId => {
    if (
      !isLoaded ||
      billingAccountId === subscriptionData?.billing_account_id
    ) {
      return;
    }

    const payload = {
      billing_account_id: billingAccountId,
    };

    await handleUpdateSubscriptionPackage(payload);
  };

  return (
    <>
      {isLoading && <ProgressSpinner />}

      <Div
        maxWidth={["100%", "100%", "100%", "1110px"]}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
      >
        <EditableFieldsCard
          title={messages.title_customer}
          config={details}
          data={customerData}
          isEditPermission={false}
        />

        <EditableFieldsCard
          title={messages.package_label}
          config={packages}
          data={subscriptionData}
          onSubmit={handleUpdateSubscriptionPackage}
          isEditPermission={hasWriteSubscriptionPermission}
        />

        <EditableFieldsCard
          title={messages.Settings}
          config={settings}
          data={subscriptionData}
          onSubmit={handleUpdateSettings}
          isEditPermission={hasWriteSubscriptionPermission}
        />

        <Div width={1}>
          <BillingInformation
            customerId={customerId}
            initialBillingAccountId={subscriptionData?.billing_account_id}
            onSelectBillingAccount={handleSelectBillingAccount}
          />
        </Div>

        <SubscriptionActivityLog activityLogData={activityLogsData} />
      </Div>
    </>
  );
};

SelectedSubscriptionDetails.propTypes = {
  customerId: PropTypes.string,
  subscriptionId: PropTypes.string,
};

export default SelectedSubscriptionDetails;
