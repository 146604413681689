import Axios from "@app/api/axios";
import { CRIMES } from "@utils/constant";

const crimesService = () =>
  Axios.get(`${CRIMES}`);
export default crimesService;

export const getCrimeServiceById = (id) => {
  return Axios.get(`${CRIMES}?filter[id]=${id}`)
    .then(({ data: { data } }) => { 
      const [crimeData] = data; 
      return crimeData; 
    })
};
