import React from "react";
import { useIntl } from "react-intl";

import { useFormik } from "formik";

import { PrimaryButtonIcon, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import InputText from "@components/InputText";
import { TextMediumWeight } from "@components/Text";

import Email from "@pages/auth/schema/Email";

import { INPUT } from "@utils/constant";

import {
  useCandidateLoginSharedData,
  useCandidateLoginStepper,
} from "../context";

const INITIAL_VALUES = {
  email: "",
};

const CandidateLoginEmailStep = () => {
  const { messages } = useIntl();
  const { handleShareCandidateEmail } = useCandidateLoginSharedData();
  const { onNextStep, onPrevStep } = useCandidateLoginStepper();

  const handleSubmit = values => {
    handleShareCandidateEmail(values.email);

    onNextStep();
  };

  const formik = useFormik({
    validationSchema: Email,
    initialValues: INITIAL_VALUES,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <H2 color="var(--blue-dark) !important">
        {messages.title_login_with_email}
      </H2>

      <TextMediumWeight>
        {messages.message_enter_email_to_get_otp}
      </TextMediumWeight>

      <InputText
        width={1}
        name="email"
        placeholder={`${messages.label_email}...`}
        formikProps={formik}
        value={formik.values.email}
        type={INPUT.TYPE.TEXT}
      />

      <Div
        width={1}
        display="flex"
        flexDirection={["column-reverse", "column-reverse", "row", "row"]}
        alignItems="center"
        justifyContent="center"
        gridGap={3}
      >
        <PrimaryButtonOutlined
          width={[1, 1, "150px", "150px"]}
          height={40}
          label={messages.label_back}
          onClick={onPrevStep}
        />

        <PrimaryButtonIcon
          width={[1, 1, "150px", "150px"]}
          height={40}
          icon="icon-headerarrowright"
          iconPos="right"
          label={messages.label_next}
          onClick={formik.handleSubmit}
        />
      </Div>
    </>
  );
};

export default CandidateLoginEmailStep;
