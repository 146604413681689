import React from "react";
import { useIntl } from "react-intl";

import UserContainer from "@layout/UserContainer";

import MyProfile from "@pages/shared/MyProfile";

const CustomerMyProfile = () => {
  const { messages } = useIntl();

  const headerConfig = {
    title: messages.personal_setting,
  };

  return (
    <UserContainer config={headerConfig}>
      <MyProfile />
    </UserContainer>
  );
};

export default CustomerMyProfile;
