import React from "react";

import {
  CandidateLoginOptions,
  CandidateLoginEmailStep,
  CandidateLoginOtpStep,
} from "./components";
import { CandidateLoginProvider } from "./context";

import CandidateLogin from "./CandidateLogin";

const INITIAL_ITEMS = [
  <CandidateLoginOptions key="CandidateLoginOptions" />,
  <CandidateLoginEmailStep key="CandidateLoginEmailStep" />,
  <CandidateLoginOtpStep key="CandidateLoginOtpStep" />,
];

const CandidateLoginWrapper = () => {
  return (
    <CandidateLoginProvider initialItems={INITIAL_ITEMS}>
      <CandidateLogin />
    </CandidateLoginProvider>
  );
};

export default CandidateLoginWrapper;
