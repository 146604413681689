import React from "react";
import { FormattedMessage } from "react-intl";
import { SUBSCRIPTION_STATUS } from "@utils/constant";
import * as Yup from "yup";

export const CreateCouponSchema = Yup.object().shape({
  customer: Yup.object().required(
    <FormattedMessage id="validation_empty_user_id" />
  ),
  user: Yup.object().required(
    <FormattedMessage id="validation_empty_user_id" />
  ),
  package: Yup.object().required(<FormattedMessage id="selected_package" />),
  price: Yup.number().required(
    <FormattedMessage id="error_price_validation" />
  ),
  availableCredits: Yup.number().required(
    <FormattedMessage
      id="validation_enter_available_credits"
      defaultMessage="Enter available credits"
    />
  ),
  totalCredits: Yup.number().required(
    <FormattedMessage
      id="validation_enter_total_credits"
      defaultMessage="Enter total credits"
    />
  ),
  status: Yup.string().required(
    <FormattedMessage id="validation_enter_status" />
  ),
  renewal: Yup.bool().required(
    <FormattedMessage
      id="validation_enter_renewal"
      defaultMessage="Enter renewal"
    />
  ),
  activationDate: Yup.date().when("status", {
    is: status => !status || status === SUBSCRIPTION_STATUS.ACTIVE,
    then: schema =>
      schema.required(
        <FormattedMessage
          id="validation_enter_renewal_date"
          defaultMessage="Enter renewal date"
        />
      ),
    otherwise: schema => schema.nullable(),
  }),
  renewalDate: Yup.date().when(["renewal", "activationDate"], {
    is: (renewal, activationDate) => renewal && activationDate,
    then: schema =>
      schema.required(
        <FormattedMessage
          id="validation_enter_renewal_date"
          defaultMessage="Enter renewal date"
        />
      ),
    otherwise: schema => schema.nullable(),
  }),
  billing_account_id: Yup.string().required(
    <FormattedMessage
      id="validation_enter_billing_account"
      defaultMessage="Enter billing account"
    />
  ),
});
