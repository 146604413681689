import config from "@src/config";
import { useIntl } from "react-intl";

const useCrimeConfig = () => {
  const { messages } = useIntl(); 

  return {
    "title": "Add Crome",
    "logoPosition": "right",
    "pages": [
      {
        "name": "crime",
        "elements": [
          {
            "type": "panel",
            "name": "question1",
            "elements": [
              {
                "type": "text",
                "name": "name",
                "title": messages.label_crime_name,
                "hideNumber": true,
                "isRequired": true
              },
              {
                "type": "dropdown",
                "name": "type",
                "startWithNewLine": false,
                "title": messages.type,
                "hideNumber": true,
                "isRequired": true,
                "choices": [
                  {
                    "value": "B",
                    "text": "B "
                  },
                  {
                    "value": "A",
                    "text": "A"
                  }
                ]
              },
              {
                "type": "dropdown",
                "name": "category",
                "title": messages.category_label,
                "hideNumber": true,
                "isRequired": true,
                "choices": [
                  {
                    "value": "crimes_not_identified",
                    "text": messages.crimes_not_identified
                  },
                  {
                    "value": "crimes_identified",
                    "text": messages.crimes_identified
                  }
                ]
              },
              {
                "type": "text",
                "name": "points",
                "startWithNewLine": false,
                "title": messages.label_points,
                "hideNumber": true,
                "isRequired": true
              },
              {
                "type": "tagbox",
                "name": "product_id",
                "title": messages.label_products,
                "hideNumber": true,
                "errorLocation": "bottom",
                "choicesByUrl": {
                  url: `${config.BASE_URL}products?filter[type]=service&customer_id=&filter[state]=active`,
                  path: "data",
                  valueName: "id",
                  titleName: "name"
                }
              },
              {
                "type": "text",
                "name": "time_relevance",
                "title": messages.label_time_relevance,
                "hideNumber": true,
                "isRequired": true
              },
              {
                "type": "text",
                "name": "points_role_analysis",
                "startWithNewLine": false,
                "title": messages.label_roles_points,
                "hideNumber": true
              },
              {
                "type": "text",
                "name": "time_relevance_role_analysis",
                "title": messages.label_roles_time_relevance,
                "hideNumber": true
              },
              {
                "type": "text",
                "name": "crime_sentence",
                "startWithNewLine": false,
                "title": messages.label_crime_sentence,
                "hideNumber": true
              }
            ]
          }
        ]
      }
    ],
    "showTitle": false
  }
};

export default useCrimeConfig;