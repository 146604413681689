import React, { useState, useEffect, memo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import hash from "object-hash";

import createShareLinkService from "@app/services/reports/createShareLinkService";

import LogoFull from "@assets/logo.png";

import Div from "@components/Div";
import { H1 } from "@components/Heading";
import Icon from "@components/Icon";
import Logo from "@components/Logo";
import ProgressSpinner from "@components/ProgressSpinner";

import { useToast } from "@hooks/useToast";

import Config from "@src/config";

import {
  CopyLinkDialog,
  DownloadGDPRDialog,
  GenerateLinkDialog,
} from "./components";
import { useReportHooks } from "./hooks";
import { ELEMENTS_BY_TYPE, generateReportPDF } from "./utils";

import { PDFLoader } from "../components";
import PrintReportDialog from "../LegacyReport/PrintReportDialog";
import useAuthorization from "@hooks/useAuthorization";
import { CustomerPermissions,AdminPermissions } from "@src/enum/Permissions";

const CaseReport = ({ report }) => {
  const { messages } = useIntl();
  const { pathname } = useLocation();
  const { isPDF } = useReportHooks();
  const { showErrorToast } = useToast();

  const [showGDPRForDownload, setShowGDPRForDownload] = useState(false);
  const [isGenerateLinkDialogVisible, setIsGenerateLinkDialogVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const { hasAnyPermissions } = useAuthorization();

  const hasPDFPermission = hasAnyPermissions([
    CustomerPermissions.CustomerDownloadReport,
    AdminPermissions.AdminReadReportAnalysis
  ]);

  const {
    report_config: config,
    report_data: data = {},
    id: report_id = "",
  } = report;
  const { pages = [] } = config;

  // Generates the PDF
  useEffect(() => {
    if (isPDF) {
      const today = new Date().toLocaleDateString("sv-SE");
      const fileName = `${config?.title}-${data?.name}-${today}.pdf`;

      generateReportPDF(fileName, ".report-page", true);
    }
  }, [isPDF, config?.title, data?.name]);

  const generateShareLink = async () => {
    try {
      setIsLoading(true);

      const { data: { token = "" } = {} } =
        await createShareLinkService(report_id);

      if (!token) {
        throw new Error("No search link token provided");
      }

      const params = new URLSearchParams();
      params.append("id", report_id);
      params.append("token", token);

      const constructedShareLink = `${Config.APP_URL}${pathname}?${params.toString()}`;

      setShareLink(constructedShareLink);
    } catch {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmPopup = () => {
    setShowGDPRForDownload(false);

    const url = `${window.location}&is_pdf=true`;

    window.open(url, "_blank");
  };

  const handleDownloadReport = () => {
    setShowGDPRForDownload(true);
  };

  const handleClose = () => {
    setShowGDPRForDownload(false);
  };

  const handleOpenGenerateLinkDialog = () => {
    setIsGenerateLinkDialogVisible(true);
  };

  const handleCloseGenerateLinkDialog = () => {
    setIsGenerateLinkDialogVisible(false);

    handleOpenCopyLinkDialog();
  };

  const handleOpenCopyLinkDialog = () => {
    generateShareLink();
  };

  const handleCloseCopyLinkDialog = () => {
    setShareLink("");
  };

  const renderPage = page => {
    const { elements: pageElements = [] } = page;

    return (
      <Div display="flex" flexDirection="column" gridGap={4}>
        {pageElements
          ?.filter(({ elements = [] }) =>
            elements.every(({ isHidden }) => !isHidden)
          )
          .map(element => {
            const elementToRender = ELEMENTS_BY_TYPE[element?.type];
            return elementToRender?.(
              {
                ...element,
                key: `${element?.type}_${element.name}${hash(element)}`,
              },
              data
            );
          })}
      </Div>
    );
  };

  const renderHeader = () => (
    <Div
      display="flex"
      flexDirection={["column", "column", "row", "row"]}
      alignItems={["flex-start", "flex-start", "center", "center"]}
      justifyContent="space-between"
      gridGap={[3, 3, 0, 0]}
    >
      <H1>{config.title}</H1>

      <Div className="report-icons" display="flex" gridGap={3}>
        {hasPDFPermission && (
          <Icon
            p={2}
            name="download-assignment"
            color="var(--turquoise)"
            cursor="pointer"
            fontSize="var(--fs-icon-m)"
            backgroundColor="var(--site-background-color)"
            borderRadius="10px"
            onClick={handleDownloadReport}
          />
        )}

        <Icon
          p={2}
          name="share"
          color="var(--turquoise)"
          cursor="pointer"
          fontSize="var(--fs-icon-m)"
          backgroundColor="var(--site-background-color)"
          borderRadius="10px"
          onClick={handleOpenGenerateLinkDialog}
        />
      </Div>

      <Div className="report-logo">
        <Logo logo={LogoFull} width="auto" maxHeight="60px" />
      </Div>
    </Div>
  );

  return (
    <>
      {isPDF && <PDFLoader />}

      <Div
        id="report"
        className={isPDF ? "report-pdf" : ""}
        display="flex"
        flexDirection="column"
        gridGap={4}
        p={[3, 3, 4, 4]}
        mx={[-16, -16, 0, 0]}
      >
        {isLoading && <ProgressSpinner />}

        {showGDPRForDownload && (
          <DownloadGDPRDialog
            onClose={handleClose}
            onConfirm={handleConfirmPopup}
          />
        )}

        {isGenerateLinkDialogVisible && (
          <GenerateLinkDialog
            onCancel={handleCloseGenerateLinkDialog}
            onConfirm={handleCloseGenerateLinkDialog}
          />
        )}

        {shareLink && (
          <CopyLinkDialog
            link={shareLink}
            onClose={handleCloseCopyLinkDialog}
          />
        )}

        {renderHeader()}

        {pages.map(page => (
          <div key={`report-page-${hash(page)}`} className="report-page">
            {renderPage(page)}
          </div>
        ))}

        {showGDPRForDownload && (
          <PrintReportDialog
            onClose={handleClose}
            onConfirm={handleConfirmPopup}
          />
        )}
      </Div>
    </>
  );
};

CaseReport.propTypes = {
  report: PropTypes.object.isRequired,
};

export default memo(CaseReport);
