import React from "react";

import LogoFull from "@assets/logo.png";

import Div from "@components/Div";
import Logo from "@components/Logo";

import Config from "@src/config";

const redirectToHomepage = () => {
  window.location = `${Config.WP_URL}/`;
};

const CandidateLoginHeader = () => {
  return (
    <Div display="flex">
      <Logo
        logo={LogoFull}
        width="auto"
        maxHeight="60px"
        onClick={redirectToHomepage}
      />
    </Div>
  );
};

export default CandidateLoginHeader;
