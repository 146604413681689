import React, { useState } from "react";
import { useIntl } from "react-intl";

import patchAccountStatus from "@app/services/billingAccounts/patchAccountStatus";

import DataTable from "@components/DataTableV2/DataTable";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";
import AdminContainer from "@layout/AdminContainer";
import { ROUTES, ADMIN_CUSTOMER_DETAILS } from "@utils/constant";

const searchableColumns = [
  "name",
  "your_reference",
  "invoice_remark",
  "customer_id",
  "customers",
];

const BillingAccounts = () => {
  const { messages } = useIntl();
  const { hasAllPermissions, hasAnyPermissions } = useAuthorization();

  const [loading, setLoading] = useState(false);

  const hasWriteBillingPermission = hasAllPermissions([
    AdminPermissions.AdminWriteBilling,
  ]);
  const hasReadCustomerPermission = hasAnyPermissions([
    AdminPermissions.AdminReadCustomers,
    AdminPermissions.AdminWriteCustomers,
  ]);

  const checkHasNoWriteAccessToBilling = () => {
    return !hasWriteBillingPermission;
  };

  const getUpdateBillingRoute = (values) => {
    const { id, customer_id } = values;
    return `/admin/kunder/${customer_id}/faktura/${id}/detaljer`;
  };

  const handleDeactivateAccount = async (values) => {
    const { id } = values;
    try {
      setLoading(true);
      await patchAccountStatus({
        billing_account_id: id,
        active: 0,
      });
    } catch (_error) {
      // Show error message when failed to deactivate account
    } finally {
      setLoading(false);
    }
  };

  const dataTableConfig = {
    header: {
      title: "header_billing_accounts",
      actions: [],
    },
    enable_filter: true,
    backend_querying: true,
    no_records_message:
      "datatable_admin_billing_billing_accounts_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: true,
    csv_filename: "billing-accounts.csv",
    api: {
      resource: `/billing-accounts`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["filter[active]", 1],
        ["include", "customer"],
        [
          "fields",
          [
            "id",
            "name",
            "your_reference",
            "invoice_remark",
            "active",
            "customer_id",
            "fortnox_customer_id"
          ],
        ],
      ],
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "link",
        width: "70px",
        href: (values) =>getUpdateBillingRoute(values)
      },
      {
        title: "label_fortnox_id",
        db_field: "fortnox_customer_id",
        type: "text",
        width: "100px",
      },
      {
        title: "title_customer",
        db_field: "customer.customer_name",
        type: "link",
        href: (values) =>
          `${ROUTES.ADMIN_CUSTOMERS.URL}/${values["customer_id"]}/${ADMIN_CUSTOMER_DETAILS}`,
        className: hasReadCustomerPermission ? "text-blue" : "text-grey",
      },
      {
        title: "header_company_name",
        db_field: "name",
        type: "text",
      },
      {
        title: "header_reference_person",
        db_field: "your_reference",
        type: "text",
      },
      {
        title: "heading_invoice_reference",
        db_field: "invoice_remark",
        type: "text",
      },
      {
        type: "actions",
        width: "5%",
        actions: [
          {
            label: messages.view_details,
            icon: "icon-showeye",
            type: "link",
            href: getUpdateBillingRoute,
          },
          {
            label: messages.deactivate_acc,
            icon: "icon-rubber",
            onClick: handleDeactivateAccount,
            type: "button",
            shouldHideAction: checkHasNoWriteAccessToBilling,
          },
        ],
      },
    ],
  };

  const headerConfig = {
    title: messages.title_billing_accounts,
  };

  return (
    <AdminContainer config={headerConfig} wide>
      <DataTable config={dataTableConfig} resetData={!loading} />
    </AdminContainer>
  );
};

export default BillingAccounts;
