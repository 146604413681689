import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";
import { formatDateAndTime } from "@utils/utils";
import Details from "./Details";

export const ActivityList = () => {
  const [selectedActivity, setSelectedActivity] = useState("");
  const [showActivityLog, setShowActivityLog] = useState(false);

  const handleActivityLogDetails = (values = "") => {
    setSelectedActivity(showActivityLog ? "" : values);
    setShowActivityLog(!showActivityLog);
  };

  const searchableColumns = ["id", "user"];
  
  const formatModel = (value) => {
    const model = value?.replace("App\\Models\\", "");
    return <FormattedMessage id={model} />;
  };

  const dataTableConfig = {
    enable_filter: false,
    backend_querying: true,
    no_records_message: "datatable_admin_activity_log_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: false,
    api: {
      resource: `/configuration/cases/activity-log`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "include",
          [
            "id",
            "user",
            "new_values",
            "type",
            "created_at",
            "model",
            "old_values",
          ],
        ],
      ],
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
      },
      {
        title: "label_user",
        db_field: "user.name",
        type: "text",
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
        formatter: value => (value ? formatDateAndTime(value) : "-"),
      },
      {
        title: "type_label",
        db_field: "type",
        type: "enum",
      },
      {
        title: "model",
        db_field: "model",
        type: "text",
        formatter: formatModel,
      },
      {
        type: "actions",
        width: "50px",
        icon: "menu-report",
        actions: [
          {
            icon: "menu-report",
            type: "button",
            onClick: handleActivityLogDetails,
          },
        ],
      },
    ],
  };

  return (
    <>
      <Container m={"0px !important"}>
        <DataTable config={dataTableConfig} />
      </Container>
      {showActivityLog && (
        <Details
          data={selectedActivity}
          handleClose={handleActivityLogDetails}
        />
      )}
    </>
  );
};
export default ActivityList;
