import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButton } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import { Text } from "@components/Text";

const MyProfileBankIdSuccessDialog = ({ isDisableBankIdLogin, onClose }) => {
  const { messages } = useIntl();

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={1}
      maxWidth="450px"
      onHide={onClose}
      m={[3, 3, 3, "auto"]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
      >
        <Icon
          name="bankID"
          fontSize="var(--fs-icon-xl)"
          color="var(--blue-bank-id-logo)"
        />
        <H3 textTransform="none">{messages.label_success}</H3>
        <Text textAlign="center">
          {isDisableBankIdLogin
            ? messages.message_successfully_deactivate_bank_id
            : messages.message_successfully_activate_bank_id}
        </Text>
        <PrimaryButton
          width={[1, 1, "160px", "160px"]}
          label={messages.label_ok}
          onClick={onClose}
        />
      </Div>
    </Dialog>
  );
};

MyProfileBankIdSuccessDialog.propTypes = {
  isDisableBankIdLogin: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MyProfileBankIdSuccessDialog;
