import React from "react";
import PropTypes from "prop-types";

import { Model, ChoicesRestfull } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/i18n/swedish";
import ProgressSpinner from "@components/ProgressSpinner";

import surveyTheme from "@assets/survey_theme.json";

import Config from "@src/config";


function getJwt() {
  return localStorage.getItem("token");
}

ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
  const token = getJwt();
  if (token) {
    options.request.setRequestHeader("Authorization", `Bearer ${token}`);
  }
};

const SurveyForm = ({
  data,
  formConfig,
  mode = "edit",
  onComplete,
  onRefLoaded,
}) => {

  const _survey = new Model(formConfig);
  _survey.applyTheme(surveyTheme);
  _survey.onComplete.add(onComplete);
  _survey.locale = Config.language;
  _survey.showCompleteButton = false;
  _survey.data = { ..._survey.data, ...data };
  _survey.mode = mode;
  const survey = _survey;
  onRefLoaded?.(survey);

  if (!survey && !survey?.data) {
    return <ProgressSpinner />;
  }

  return <Survey model={survey} />;
};

SurveyForm.propTypes = {
  data: PropTypes.object,
  formConfig: PropTypes.any,
  mode: PropTypes.string,
  onComplete: PropTypes.func,
  onRefLoaded: PropTypes.func,
};

export default SurveyForm;
