import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import getCaseDetailsById from "@app/services/cases/getCaseDetailsById";

import Breadcrumb from "@components/Breadcrumb";
import ProgressSpinner from "@components/ProgressSpinner";
import Tabs from "@components/Tabs";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import AdminContainer from "@layout/AdminContainer";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

import ActivityLogsTab from "./tabs/ActivityLogsTab";
import CommentsTab from "./tabs/CommentsTab";
import DetailsTab from "./tabs/DetailsTab";
import ReportTab from "./tabs/ReportTab";
import SourceDataTab from "./tabs/SourceDataTab";

const Details = () => {
  const { hasAllPermissions } = useAuthorization();
  const history = useHistory();
  const { messages } = useIntl();
  const { showErrorToast } = useToast();
  const { case_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [caseDetails, setCaseDetails] = useState({});

  const hasReadDataSourcesPermission = hasAllPermissions([
    AdminPermissions.AdminReadDataSources,
  ]);
  const hasReadReportAnalysisPermission = hasAllPermissions([
    AdminPermissions.AdminReadReportAnalysis,
  ]);

  useEffect(() => {
    const fetchCaseDetails = async () => {
      try {
        setIsLoading(true);

        const { data: caseDetailsData } = await getCaseDetailsById(case_id);

        setCaseDetails(caseDetailsData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCaseDetails();
  }, [case_id, messages.exception_error_message, showErrorToast]);

  const tabContent = useMemo(
    () => [
      {
        title: messages.label_details,
        content: (
          <DetailsTab
            caseDetails={caseDetails}
            onCaseDetailsUpdate={setCaseDetails}
          />
        ),
        url: ROUTES.ADMIN_CASE_DETAILS.URL.replace(":case_id", case_id),
      },
      {
        title: messages.label_source_data,
        content: <SourceDataTab />,
        url: ROUTES.ADMIN_CASE_SOURCE_DATA.URL.replace(":case_id", case_id),
        isHidden: !hasReadDataSourcesPermission,
      },
      {
        title: messages.label_report,
        content: <ReportTab />,
        url: ROUTES.ADMIN_CASE_REPORT.URL.replace(":case_id", case_id),
        isHidden: !hasReadReportAnalysisPermission || !caseDetails.is_report,
      },
      {
        title: messages.label_comments,
        content: <CommentsTab />,
        url: ROUTES.ADMIN_CASE_COMMENTS.URL.replace(":case_id", case_id),
      },
      {
        title: messages.activity_log,
        content: <ActivityLogsTab />,
        url: ROUTES.ADMIN_CASE_ACTIVITY_LOG.URL.replace(":case_id", case_id),
      },
    ],
    [
      caseDetails,
      case_id,
      hasReadDataSourcesPermission,
      hasReadReportAnalysisPermission,
      messages.activity_log,
      messages.label_comments,
      messages.label_details,
      messages.label_report,
      messages.label_source_data,
    ]
  );

  const _activeTabIndex = Math.max(
    tabContent.findIndex(item => item.url === history?.location?.pathname),
    0
  );
  const [activeTabIndex, setActiveTabIndex] = useState(_activeTabIndex);

  const handleTabChange = event => {
    const { index } = event;
    const targetUrl = tabContent[index]?.url;

    const activeTabIndex = Math.max(
      tabContent.findIndex(item => item.url === targetUrl),
      0
    );

    setActiveTabIndex(activeTabIndex);

    window.history.pushState(null, "", targetUrl);
  };

  const formattedId = `#${case_id}`;

  const headerConfig = {
    title: `${messages.label_case} ${formattedId}`,
  };

  const breadcrumbItems = [
    {
      label: messages.title_cases,
      url: ROUTES.ADMIN_CASES_LIST.URL,
    },
    {
      label: `${messages.label_case_details}: ${formattedId}`,
    },
  ];

  return (
    <AdminContainer config={headerConfig}>
      {isLoading && <ProgressSpinner />}

      <Breadcrumb items={breadcrumbItems} pt={0} pl={0} />

      <Tabs
        content={tabContent}
        activeIndex={activeTabIndex}
        onTabChange={handleTabChange}
      />
    </AdminContainer>
  );
};

export default Details;
