import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import AdminContainer from "@layout/AdminContainer";
import DataTable from "@components/DataTableV2/DataTable";
import { ADMIN_ANALYSIS_API, ROUTES } from "@utils/constant";
import customerService from "@app/services/customers/customerService";
import { StatusComponent } from "@components/Report/StatusComponent";
import { ReportStatus, reportStatusArray } from "@src/enum/reportStatus";
import fetchProductById from "@app/services/services/fetchProductById";
import GradeComponent from "@components/Report/GradeComponent";

const searchableColumns = ["id", "customer.customer_name", "order_id"];

const FILTER_KEY_FROM = "filter[created_at][gte]";
const FILTER_KEY_TO = "filter[created_at][lte]";

const List = () => {
  const { messages } = useIntl();
  const [customerData, setCustomerData] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const loadCustomerData = async () => {
      const response = await customerService();
      const { data: { data: customers = [] } = {} } = response;
      const formattedData = customers.map(o => ({
        name: `${o?.customer_name} #${o?.id}`,
        code: o?.id,
      }));
      setCustomerData(formattedData);
    };
    const loadServicesData = async () => {
      const response = await fetchProductById();
      const { data: { data: products = [] } = {} } = response;
      const formattedData = products.map(o => ({
        name: `${o?.name} #${o?.id}`,
        code: o?.id,
      }));
      setServices(formattedData);
    };
    loadServicesData();
    loadCustomerData();
  }, []);

  const dataTableConfig = {
    header: {
      title: "menu_reports",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        options: [
          {
            label: messages.filter_date_from,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.title_customers,
        id: "filter[customer_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[customer_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[customer_id]",
            options: customerData,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_services,
        id: "filter[orderItem.product_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[orderItem.product_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[orderItem.product_id]",
            options: services,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.grades,
        id: "filter[grades]",
        type: "multiselect",
        options: [
          {
            name: "filter[grades]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[grades]",
            options: [
              {
                name: "+3",
                code: "+3",
              },
              {
                name: "+2",
                code: "+2",
              },
              {
                name: "+1",
                code: "+1",
              },
              {
                name: "0",
                code: "0",
              },
              {
                name: "-1",
                code: "-1",
              },
              {
                name: "-2",
                code: "-2",
              },
              {
                name: "-3",
                code: "-3",
              },
            ],
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "multiselect",
        options: [
          {
            name: "filter[status]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[status]",
            options: reportStatusArray.map((singleStatus, index) => {
              return {
                name: messages[ReportStatus[singleStatus]],
                code: singleStatus,
                key: index,
              };
            }),
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_reports_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${ADMIN_ANALYSIS_API}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          [
            "id",
            "created_at",
            "status",
            "order_id",
            "grades",
            "person_initials",
            "order_item_id",
            "customer_id",
            "product_id",
            "analysis_type",
            "grade_data",
            "is_case",
            "case_id"
          ],
        ],
        ["include", ["customer", "orderItem", "product"]],
      ],
    },
    columns: [
      {
        title: "watchlist_title_order_id",
        db_field: "order_id",
        type: "id",
        width: "90px",
        className: "text-blue",
        href: value => {
          const { order_id } = value;
          return `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${order_id}`;
        },
        formatter: value => `#${value}`,
        sortable: true,
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
        width: "16%",
      },
      {
        title: "customer_label",
        db_field: "customer.customer_name",
        type: "link",
        className: "text-blue",
        href: value => {
          return `/admin/kunder/${value["customer.id"]}/detaljer`;
        },
      },
      {
        title: "datasource_candidate",
        db_field: "person_initials",
        type: "text",
        width: "100px",
        sortable: true,
      },

      {
        title: "grade",
        db_field: "grades",
        type: "link",
        width: "90px",
        formatter: (value, report) => {
          return <GradeComponent value={value} report={report} />;
        },
      },

      {
        title: "label_service",
        db_field: "product.name",
        type: "text",
        className: "text-black",
        width: "25%",
        sortable: true,
      },
      {
        title: "label_status",
        db_field: "status",
        type: "text",
        body:(rowData) => <StatusComponent value={rowData.status} db_fields={rowData} />,
        className: "text-grey-dark",
        width: "100px",
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.see_report,
            icon: "icon-showeye",
            type: "link",
            href: value => `/rapport?id=${value.id}`,
          },
          {
            label: messages.report_data_label_admin_report_table,
            icon: "icon-menu-report",
            type: "link",
            href: value => value.is_case?`arenden/${value.case_id}/rapport`:`rapporter/${value.id}`,
          },

        ],
      }, 
    ],
  };

  const headerConfig = [
    {
      title: messages.menu_reports,
    }, 
  ];

  return (
    <AdminContainer config={headerConfig} wide>
      {customerData.length > 0 && services.length > 0 && (
        <DataTable config={dataTableConfig} />
      )}
    </AdminContainer>
  );
};

export default List;
