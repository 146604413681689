import { useCallback, useEffect, useState } from "react";

export const useCountdown = initialCountdown => {
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    if (countdown <= 0) {
      return;
    }

    const interval = setInterval(() => {
      setCountdown(prevValue => prevValue - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdown]);

  const resetCountdown = useCallback(() => {
    setCountdown(initialCountdown);
  }, [initialCountdown]);

  return {
    countdown,
    resetCountdown,
  };
};
