import React from "react";
import { useIntl } from "react-intl";

import ReportPlaceholder from "@assets/report_placeholder.svg";

import Div from "@components/Div";
import { H1 } from "@components/Heading";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import Logo from "@components/Logo";
import { Text } from "@components/Text";

import Config from "@src/config";

const ExpiredReport = () => {
  const { messages } = useIntl();

  return (
    <Div
      mt={[3, 3, 0, 0]}
      display="flex"
      flexDirection={["column", "column", "row-reverse", "row-reverse"]}
      alignItems="center"
      gridGap={4}
    >
      <Logo logo={ReportPlaceholder} width={["50%", "50%", "470px", "470px"]} />
      <Div
        display="flex"
        flexDirection="column"
        alignItems={["center", "center", "flex-start", "flex-start"]}
        gridGap={3}
        pr={[0, 0, "60px", "120px"]}
      >
        <H1>{messages.title_report_no_longer_available}</H1>
        <Text textAlign={["center", "center", "start", "start"]}>
          {messages.message_report_no_longer_available}
        </Text>
        <LinkArrow
          target="_blank"
          href={Config.WP_URL}
          label={messages.label_search_new_report}
        >
          <Icon ml={1} name="headerarrowright" />
        </LinkArrow>
      </Div>
    </Div>
  );
};

export default ExpiredReport;
