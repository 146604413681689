import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Formik } from "formik";

import loginService from "@app/services/auth/loginService";

import { PrimaryButtonIcon } from "@components/Button";
import Div from "@components/Div";
import Form from "@components/Form";
import Icon from "@components/Icon";
import { InputPassword } from "@components/Input";
import InputText from "@components/InputText";
import { LinkArrow } from "@components/Link";

import Email from "@pages/auth/schema/Email";

import Password from "@src/schema/Password";

import { FORGOT_PASSWORD, INPUT } from "@utils/constant";
import useHistory from "@utils/useHistory";

const { EMAIL, LOGIN_PASSWORD } = INPUT.NAME;

const CredentialsSchema = Email.concat(Password);
const LoginSchema = CredentialsSchema.pick([EMAIL, LOGIN_PASSWORD]);

const INITIAL_VALUES = { email: "", password: "" };

const LoginCredentialsForm = ({ onSubmit, onError }) => {
  const history = useHistory();
  const { messages } = useIntl();

  const handleLoginSubmit = async values => {
    try {
      const { data: { token, user } = {} } = await loginService({
        email: values.email,
        password: values.login_password,
      });

      onSubmit({ token, user });
    } catch (e) {
      onError(messages.invalid_data);
    }
  };

  const handleRedirectToForgotPassword = () => history.push(FORGOT_PASSWORD);

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      validationSchema={LoginSchema}
      onSubmit={handleLoginSubmit}
    >
      {formik => {
        const { values, isValid, dirty } = formik;

        return (
          <Form width={1}>
            <Div
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={1}
            >
              <Div width={1}>
                <InputText
                  curved
                  width={1}
                  placeholder={messages.placeholder_email_address}
                  value={values.email}
                  name={EMAIL}
                  formikProps={formik}
                  label={messages.label_email}
                />
              </Div>

              <InputPassword
                name={LOGIN_PASSWORD}
                value={values.login_password}
                placeholder={messages.label_password}
                onChange={formik.handleChange}
                formikProps={formik}
                label={messages.label_password}
                width={1}
              />

              <PrimaryButtonIcon
                rounded
                mt={3}
                width={1}
                maxWidth="200px"
                height="40px"
                label={messages.login}
                disabled={!isValid || !dirty}
                type="submit"
              />

              <LinkArrow
                mt={2}
                label={messages.title_forgot_password}
                onClick={handleRedirectToForgotPassword}
                direction="left"
              >
                <Icon ml={1} name="headerarrowright" />
              </LinkArrow>
            </Div>
          </Form>
        );
      }}
    </Formik>
  );
};

LoginCredentialsForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

export default LoginCredentialsForm;
