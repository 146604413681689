import React from "react";
import { FormattedMessage } from "react-intl";

import Currency from "@components/Currency";

import { ADMIN, SPECIAL_PACKAGE, SUBSCRIPTION_STATUS } from "@utils/constant";
import { FORM_INPUT_TYPE, RENEWAL_MODE_TYPE } from "@utils/enum";
import { formatDate } from "@utils/utils";

export const RENEWAL_OPTIONS = [
  {
    label: <FormattedMessage id="on" />,
    value: RENEWAL_MODE_TYPE.ON,
  },
  {
    label: <FormattedMessage id="off" />,
    value: RENEWAL_MODE_TYPE.OFF,
  },
];

export const getCustomerConfig = data => {
  const configuration = {
    details: [
      {
        key: "company_name",
        translationKey: "title_customer",
        type: FORM_INPUT_TYPE.LINK,
        value: data.company_name,
        href: `/admin/kunder/${data.id}/detaljer`,
      },
      {
        key: "registration_nr",
        translationKey: "label_company_number",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.registration_nr,
      },
      {
        key: "name",
        translationKey: "user",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.name,
      },
    ],
  };

  return configuration;
};

export const getPackageConfig = (data, packages, onStatusChange) => {
  const packageOptions = packages.map(({ id, name, credits, price }) => ({
    label: name,
    value: id,
    available_credits: credits,
    total_credits: credits,
    price: price,
  }));

  const configuration = {
    packages: [
      {
        key: "product_id",
        translationKey: "package_label",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: packageOptions,
        selectorField: "label",
        value: Number(data?.product_id),
        fullWidth: true,
        handleChange: (event, formikValues) => {
          const { value } = event;
          const { values, setValues } = formikValues;
          const product =
            packageOptions.find(option => option?.value == value) ?? {};
          const { available_credits, total_credits, price } = product;
          setValues({
            ...values,
            product_id: value,
            available_credits,
            total_credits,
            price,
            email_notification: values?.email_notification || true,
          });
        },
      },
      {
        key: "credits",
        translationKey: "watchlist_title_credits",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: `${data?.available_credits}/${data?.total_credits}`,
        editable: false,
      },
      {
        key: "total_credits",
        translationKey: "total_credits",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        textType: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: data?.total_credits,
        isHidden: true,
        fullWidth: true,
        editable: values => {
          const { label = "" } =
            packageOptions.find(({ value }) => value === values?.product_id) ??
            {};

          return label !== SPECIAL_PACKAGE;
        },
        isDisabled: values => {
          const { label = "" } = packageOptions.find(
            o => o?.value === values?.product_id
          );
          return label !== SPECIAL_PACKAGE;
        },
      },
      {
        key: "available_credits",
        translationKey: "text_available_credits",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        textType: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: data?.available_credits,
        editable: true,
        isHidden: true,
        fullWidth: true,
      },
      {
        key: "price_in_currency",
        translationKey: "watchlist_label_price",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        textType: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: <Currency value={data?.price} />,
        fullWidth: true,
        editable: false,
      },
      {
        key: "price",
        translationKey: "price",
        type: FORM_INPUT_TYPE.INPUT_NUMBER,
        textType: FORM_INPUT_TYPE.INPUT_NUMBER,
        value: parseInt(data?.price) ?? 0,
        fullWidth: true,
        isHidden: true,
        editable: true,
      },
      {
        key: "email_notification",
        translationKey: "send_email_confirmation",
        type: FORM_INPUT_TYPE.CHECK_BOX,
        value: data?.email_notification,
        fullWidth: true,
        editable: values => values?.email_notification === undefined,
        isHidden: values => values?.email_notification !== undefined,
        header: "message_send_order_confirmation",
      },
    ],
    settings: [
      {
        key: "status",
        translationKey: "label_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="title_registered" />,
            value: SUBSCRIPTION_STATUS.REGISTERED,
          },
          {
            label: <FormattedMessage id="active" />,
            value: SUBSCRIPTION_STATUS.ACTIVE,
          },
          {
            label: <FormattedMessage id="stopped_label" />,
            value: SUBSCRIPTION_STATUS.STOPPED,
          },
          {
            label: <FormattedMessage id="terminated_label" />,
            value: SUBSCRIPTION_STATUS.TERMINATED,
          },
        ],
        selectorField: "label",
        value: data.status,
        fullWidth: true,
        handleChange: onStatusChange,
      },
      {
        key: "renewals",
        translationKey: "renewal",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: RENEWAL_OPTIONS,
        selectorField: "label",
        value: data.renewals,
        fullWidth: true,
        isHidden: data?.status === SUBSCRIPTION_STATUS.TERMINATED,
        show: values => {
          return values?.status !== SUBSCRIPTION_STATUS.TERMINATED;
        },
      },
      {
        key: "renewal_date",
        translationKey: "renewal_date",
        type: FORM_INPUT_TYPE.CALENDAR,
        value: data.renewal_date,
        fullWidth: true,
        isHidden:
          !data?.renewals || data?.status === SUBSCRIPTION_STATUS.TERMINATED,
        show: values => {
          return (
            !!values?.renewals &&
            values?.status !== SUBSCRIPTION_STATUS.TERMINATED
          );
        },
      },
      {
        key: "email_notification",
        translationKey: "send_email_confirmation",
        type: FORM_INPUT_TYPE.CHECK_BOX,
        value: data?.email_notification,
        fullWidth: true,
        editable: values => values?.email_notification === undefined,
        isHidden: values => values?.email_notification !== undefined,
        header: "change_email_notification",
      },
    ],
  };
  return configuration;
};

export const buildPayload = (
  activeSubscriptionData,
  customerId,
  adminEmail
) => {
  const {
    product_id,
    id: subscription_id,
    status,
    price,
    available_credits,
    total_credits,
    email_notification = true,
    renewals,
    renewal_date,
    billing_account_id,
  } = activeSubscriptionData;

  const payload = {
    customer_id: customerId,
    product_id,
    subscription_id,
    status,
    available_credits,
    total_credits,
    price: Number(price),
    signed_by: `${ADMIN} ${adminEmail}`,
    email_notification,
    renewals,
    renewal_date: formatDate(renewal_date),
    billing_account_id: billing_account_id,
  };

  return payload;
};
