export const INVOICE_TYPE = {
  E_INVOICE: "E-invoice",
  EMAIL_INVOICE: "Email invoice",
};

export const FORM_INPUT_TYPE = {
  FILLER: "filler",
  TEXT: "text",
  DROPDOWN: "dropdown",
  AUTOCOMPLETE: "autocomplete",
  CALENDAR: "calendar",
  EDITOR: "editor",
  MULTISELECT: "multiselect",
  FILES: "files",
  TEXTAREA: "textarea",
  INPUT_NUMBER: "number",
  FILE_UPLOAD: "file_upload",
  HTML: "html",
  CHECK_BOX: "checkbox",
  LINK: "link",
  MULTI_FILES: "multifiles",
};

export const INVOICE_STATUS = {
  NOT_INVOICED: "Not invoiced",
  INVOICED: "Invoiced",
  CANCELED: "Cancelled",
  INVOICING: "Invoicing",
};

export const INVOICE_MODAL_TYPES = {
  CONFIRM: "confirm",
  CANCEL: "cancel",
};

export const FORM_TYPE = {
  CUSTOMER: "customer_form",
  REPORT: "report_config",
  CANDIDATE: "candidate_form",
};

export const SURVEY_BUILDER_DEFAULT_TAB = {
  PREVIEW: "test",
  DESIGNER: "designer",
  LOGIC: "logic",
  EDITOR: "editor",
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: "percent",
  FIXED_AMOUNT: "fixed-amount",
};

export const APPLICATION_TYPE = {
  ENTIRE_ORDER: "entire-order",
  SINGLE_ORDER_ITEM: "single-order-item",
  ALL_ITEMS: "all-applicable-order-items",
};

export const COUPON_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const PERMISSIONS = {
  COMPANY_INFORMATION: "company-information",
  ORDERS: "orders",
  SELF_ORDERS: "self-orders",
  ASSIGNMENTS: "assignments",
  REPORT_ANALYSIS: "report-analysis",
  CUSTOMERS: "customers",
  SUBSCRIPTION: "subscription",
  USERS: "users",
  WATCH_LISTS: "watch-lists",
  BILLING: "billing",
  PRODUCTS: "products",
  PRICES: "prices",
  EMAIL_LOG: "email-log",
  CASES: "cases",
  FORM_CONFIG: "form-config",
  REPORT_CONFIG: "report-config",
  RULES: "rules",
  CASES_ACTIVITY_LOG: "cases-activity-log",
  DATA_SOURCES: "data-sources",
  CRIMES: "crimes",
  USER_ROLES_PERMISSIONS: "user-roles-permissions",
  EMAIL_ACCOUNTS: "email-accounts",
  COURTS: "courts",
  CUSTOMERS_ACTIVITY_LOG: "customers-activity-log",
};

export const PERMISSION_TYPE = {
  READ: "read",
  WRITE: "write",
  NONE: "none",
  GRADES: "grades",
};

export const USER_TYPE = {
  ADMIN: "admin",
  CUSTOMER: "customer",
};

export const ROLE_TYPE = {
  ADMIN_SUPERADMIN: "admin-superadmin",
  ADMIN: "admin",
  SECURITY_ANALYST: "security-analyst",
  CV_VERIFICATION: "cv-verification",
  ADMIN_CUSTOM: "admin-custom",
  OWNER: "owner",
  SUPERADMIN: "superadmin",
  USER: "user",
  CUSTOM: "custom",
  SECURITY_OFFICER: "security-officer",
  ADMIN_ADMIN: "admin-admin",
  ADMIN_INVESTIGATOR: "admin-investigator",
  ADMIN_SALES: "admin-sales",
};

export const SERVICE_TYPE = {
  ANALYSIS: "analysis",
  ASSIGNMENT: "assignment",
  BUNDLE: "bundle",
};
export const PRODUCT_TYPE = {
  SERVICE: "service",
  SUBSCRIPTION: "subscription",
};

export const ASSIGNEE_TYPE = {
  UNASSIGNED: "unassigned",
  ASSIGNEE_ID: "assignee_id",
  NAME: "name",
};

export const CASES_STATUS_TYPE = {
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  PENDING: "pending",
  MANUAL: "manual",
};

export const CASES_DATA_STATUS_TYPE = {
  PENDING: "pending",
  COMPLETED: "completed",
  MANUAL: "manual",
};

export const PROCESS_TYPE = {
  MANUAL: "manual",
  AUTOMATIC: "automatic",
};

export const EMAIL_TYPE = {
  OUTGOING: "outgoing",
  INCOMING: "incoming",
};

export const DATA_SOURCE_TYPE = {
  CHECKBIZ_PERSONINFORMATION: "checkbiz_personinformation",
  VERIFERA_PERSON_INFORMATION_ROLE_ANALYSIS:
    "verifiera_person_information_role_analysis",
  INTERNAL_DATA: "internal_data",
  COURT_SMALL: "court_small",
  COURT_MEDIUM: "court_medium",
  COURT_LARGE: "court_large",
  COURT: "court",
  CANDIDATE: "candidate",
  CUSTOMER: "customer",
};

export const EMAIL_CLASSIFICATION_TYPE = {
  AUTOMATIC_REPLY: "automatic_reply",
  NO_CRIMINAL_CASES: "no_criminal_cases",
  CRIMINAL_CASES_WITH_DOCUMENTS: "criminal_cases_with_documents",
  SECURE_EMAIL: "secure_email",
};

export const SURVEY_MODE_TYPE = {
  EDIT: "edit",
  DISPLAY: "display",
};

export const RENEWAL_MODE_TYPE = {
  ON: 1,
  OFF: 0,
};

export const COMPANY_TYPE = {
  PUBLIC: "Public",
  PRIVATE: "Private",
};

export const BANK_ID_SCOPES = {
  LOGIN: "login",
  LOGIN_CANDIDATE: "login_candidate",
  ENABLE_BANKID: "enable_bankid",
  CONSENT_LEGACY: "consent_legacy",
};

export const ORDERS_TYPE = {
  PO: "PO",
  SO: "SO",
};
