import { useIntl } from "react-intl";
const useCourtConfig = () => {
  const { messages } = useIntl(); 
  return {
        "title": "Add Court",
        "logoPosition": "right",
        "pages": [
          {
            "name": "court",
            "elements": [
              {
                "type": "panel",
                "name": "question1",
                "elements": [
                  {
                    "type": "text",
                    "name": "court_name",
                    "title": messages.label_court_name,
                    "hideNumber": true,
                    "isRequired": true
                  },
                  {
                    "type": "text",
                    "name": "verifiera_court_name",
                    "startWithNewLine": false,
                    "title": messages.label_court_verifiera,
                    "hideNumber": true,
                    "isRequired": true
                  },
                  {
                    "type": "text",
                    "name": "court_email",
                    "title": messages.label_court_email,
                    "hideNumber": true,
                    "isRequired": true
                  },
                  {
                    "type": "dropdown",
                    "name": "type",
                    "startWithNewLine": false,
                    "title": messages.type,
                    "hideNumber": true,
                    "isRequired": true,
                    "choices": [
                      {
                        "value": "datasource_court_small",
                        "text": "Court small"
                      },
                      {
                        "value": "datasource_court_medium",
                        "text": "Court medium"
                      },
                      {
                        "value": "datasource_court_large",
                        "text": "Court large"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],
        "showTitle": false
      }
};

export default useCourtConfig;