import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";

const CandidateLoginContext = createContext(null);

export const CandidateLoginProvider = ({ children, initialItems = [] }) => {
  const [items] = useState(initialItems);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [sharedData, setSharedData] = useState({});

  const currentValue = useMemo(
    () => ({
      items,
      sharedData,
      onSharedDataChange: setSharedData,
      currentItem: items[currentItemIndex],
      onCurrentItemChange: setCurrentItemIndex,
    }),
    [currentItemIndex, items, sharedData]
  );

  return (
    <CandidateLoginContext.Provider value={currentValue}>
      {children}
    </CandidateLoginContext.Provider>
  );
};

CandidateLoginProvider.propTypes = {
  children: PropTypes.node,
  initialItems: PropTypes.array,
};

const useCandidateLogin = () => {
  const context = useContext(CandidateLoginContext);

  if (!context) {
    throw new Error(
      "useCandidateLogin should be used within the CandidateLoginProvider"
    );
  }

  return context;
};

export const useCandidateLoginStepper = () => {
  const { items, currentItem, onCurrentItemChange } = useCandidateLogin();

  const onNextStep = useCallback(() => {
    onCurrentItemChange(prevStepIndex =>
      Math.min(prevStepIndex + 1, items.length - 1)
    );
  }, [items.length, onCurrentItemChange]);

  const onPrevStep = useCallback(() => {
    onCurrentItemChange(prevStepIndex => Math.max(prevStepIndex - 1, 0));
  }, [onCurrentItemChange]);

  return {
    currentItem,
    onNextStep,
    onPrevStep,
  };
};

export const useCandidateLoginSharedData = () => {
  const { sharedData, onSharedDataChange } = useCandidateLogin();

  const handleShareCandidateId = useCallback(
    candidateId => {
      onSharedDataChange(prevSharedData => ({
        ...prevSharedData,
        candidateId,
      }));
    },
    [onSharedDataChange]
  );

  const handleShareCandidateEmail = useCallback(
    candidateEmail => {
      onSharedDataChange(prevSharedData => ({
        ...prevSharedData,
        candidateEmail,
      }));
    },
    [onSharedDataChange]
  );

  const handleShareSuccessHandler = useCallback(
    onSuccess => {
      onSharedDataChange(prevSharedData => ({
        ...prevSharedData,
        onSuccess,
      }));
    },
    [onSharedDataChange]
  );

  const handleShareErrorHandler = useCallback(
    onError => {
      onSharedDataChange(prevSharedData => ({
        ...prevSharedData,
        onError,
      }));
    },
    [onSharedDataChange]
  );

  return {
    sharedData,
    handleShareCandidateEmail,
    handleShareCandidateId,
    handleShareSuccessHandler,
    handleShareErrorHandler,
  };
};
