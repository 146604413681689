import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { personInformationActions } from "@app/reducers/personInformationReducer";
import getSubscription from "@app/services/subscription/getSubscription";

import { useUser } from "@contexts/AuthProvider";

import BankIDLogin from "@components/BankidComponent/BankIDLogin";
import Card from "@components/Card";
import Container from "@components/Container";
import Div from "@components/Div";
import { H2, H4 } from "@components/Heading";
import Section from "@components/Section";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import {
  ROUTES,
  ADMIN_NAV,
  ADMIN_NAV_MY_PROFILE,
  COMPANY,
  CUSTOMER,
} from "@utils/constant";
import { BANK_ID_SCOPES } from "@utils/enum";
import { isAdminUser, isAuthenticated } from "@utils/utils";

import {
  LoginCredentialsForm,
  LoginOptionsSeparator,
  LoginPrivacyFooter,
} from "./components";

const isTokenValid = token => {
  return token !== null;
};

export const Login = () => {
  const { hasAnyPermissions } = useAuthorization();
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { userInfo: { type: userType, customer = {} } = {} } = useSelector(
    state => state.authReducer
  );
  const { showErrorToast } = useToast();
  const { initializeUser } = useUser();

  const findPath = () => {
    const [{ redirectTo: fallbackUrl }] = ADMIN_NAV_MY_PROFILE;
    const { redirectTo: targetRedirectUrl } =
      ADMIN_NAV.find(({ permissions = [] }) =>
        hasAnyPermissions(permissions)
      ) ?? {};

    return targetRedirectUrl ?? fallbackUrl;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const redirectToPage = async user => {
    const { customer: { id = "" } = {}, type = "" } = user;

    if (type == CUSTOMER && id) {
      const { data: { data: subscriptionData = [] } = {} } =
        await getSubscription(id);
      const [activeSubscription = false] = subscriptionData;

      dispatch(
        personInformationActions.setHasActiveSubscription(activeSubscription)
      );
    }
  };

  const handleError = useCallback(
    error => {
      showErrorToast(error ?? messages.error_no_users_found_with_ssn);
    },
    [messages.error_no_users_found_with_ssn, showErrorToast]
  );

  const handleLoginSubmit = ({ token, user }) => {
    if (!isTokenValid(token)) {
      handleError(messages.invalid_data);

      return;
    }

    localStorage.setItem("token", token);

    initializeUser();

    redirectToPage(user);
  };

  if (isAuthenticated()) {
    const redirectTarget = { pathname: ROUTES.USER_PROFILE.URL };

    if (isAdminUser()) {
      redirectTarget.pathname = findPath();
    } else if (userType === CUSTOMER && customer.type === COMPANY) {
      redirectTarget.pathname = ROUTES.SEARCH.URL;
    }

    return <Redirect to={redirectTarget} />;
  }

  return (
    <Section bg="var(--site-background-color)">
      <Container>
        <Div
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Div
            display="flex"
            flexGrow={1}
            width={1}
            alignItems="center"
            justifyContent="center"
          >
            <Card width={1} maxWidth="464px">
              <Div
                px={[3, 3, 4, 4]}
                py="24px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gridGap={3}
              >
                <H2 color="var(--blue-dark) !important">{messages.login}</H2>

                <LoginCredentialsForm
                  onSubmit={handleLoginSubmit}
                  onError={handleError}
                />

                <LoginOptionsSeparator />

                <H4
                  fontWeight="var(--semibold-weight) !important"
                  color="var(--blue-dark) !important"
                >{`${messages.title_bankid_login}:`}</H4>

                <Div width={1} maxWidth="200px">
                  <BankIDLogin
                    onSuccess={handleLoginSubmit}
                    onError={handleError}
                    scope={BANK_ID_SCOPES.LOGIN}
                  />
                </Div>
              </Div>
            </Card>
          </Div>

          <LoginPrivacyFooter />
        </Div>
      </Container>
    </Section>
  );
};

export default Login;
