import { FORM_INPUT_TYPE } from "@utils/enum";

export const getSubscriptionConfig = (data, packages) => {
  const { activePackageId, userName, userEmail, userPhone } = data;
  const packageOptions = packages.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const config = {
    details: [
      {
        key: "product_id",
        translationKey: "label_desired_subscription",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: packageOptions,
        selectorField: "label",
        value: activePackageId,
      },
      {
        key: "name",
        translationKey: "contact_person_name",
        type: FORM_INPUT_TYPE.TEXT,
        textType: FORM_INPUT_TYPE.TEXT,
        value: userName,
        required: true,
      },
      {
        key: "email",
        translationKey: "label_email",
        type: FORM_INPUT_TYPE.TEXT,
        textType: FORM_INPUT_TYPE.TEXT,
        value: userEmail,
        required: true,
      },
      {
        key: "phone",
        translationKey: "label_phone",
        type: FORM_INPUT_TYPE.TEXT,
        textType: FORM_INPUT_TYPE.TEXT,
        value: userPhone,
        required: true,
      },
      {
        key: "message",
        translationKey: "title_message",
        type: FORM_INPUT_TYPE.TEXTAREA,
        value: "",
        fullWidth: true,
      },
    ],
  };
  return config;
};
