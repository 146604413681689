import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import fetchOrderDetails from "@app/services/orders/fetchOrderDetails";
import getReportDetails from "@app/services/reports/getReportDetails";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import ProgressSpinner from "@components/ProgressSpinner";

import FullSizeDialog from "@components/FullSizeDialog";
import CaseReport from "@components/Report/CaseReport/CaseReport";
import { CreditsReportDialog } from "@components/Report/components";

import { ORDER_REPORT_ANCHOR_ID, ROUTES } from "@utils/constant";

import AnalysDetails from "./AnalysDetails";

const AdminAnalysDetails = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const { id: reportId = "" } = useParams();

  const [analysData, setAnalysData] = useState({});
  const [orderData, setOrderData] = useState({});
  const [isCaseReportDialogVisible, setIsCaseReportDialogVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowCreditsReportDialog, setShouldShowCreditsReportDialog] =
    useState(false);

  const reportData = analysData?.is_case ? analysData?.report_data : analysData;
  const objectData = {
    personal_number: reportData?.personal_number?.replace("-", ""),
    person_name: reportData?.name,
    email: reportData?.email,
    phone: reportData?.phone,
  };

  useEffect(() => {
    const openCreditsReportDialog = () => {
      setShouldShowCreditsReportDialog(true);
    };

    const checkForElement = () => {
      const element = document.querySelector(`#${ORDER_REPORT_ANCHOR_ID}`);

      if (element) {
        element.addEventListener("click", openCreditsReportDialog);

        observer?.disconnect?.();
      }
    };

    const observer = new MutationObserver(checkForElement);

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      const element = document.querySelector(`#${ORDER_REPORT_ANCHOR_ID}`);

      if (element) {
        element.removeEventListener?.("click", openCreditsReportDialog);
      }

      observer.disconnect();
    };
  }, []);

  const fetchReportDetails = useCallback(() => {
    setIsLoading(true);

    getReportDetails(reportId)
      .then(
        res => {
          const { data: { data = {} } = {} } = res;

          if (data?.is_case) {
            setIsCaseReportDialogVisible(true);
          }

          setAnalysData(data);
        },
        e => {
          throw new Error(e);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [reportId]);

  useEffect(() => {
    fetchReportDetails();
  }, [fetchReportDetails]);

  useEffect(() => {
    const getOrderDetails = () => {
      if (!analysData.order_id) {
        return;
      }

      fetchOrderDetails(analysData.order_id).then(res => {
        setOrderData(res);
      });
    };

    if (analysData.is_case) {
      return;
    }

    getOrderDetails();
  }, [analysData.order_id, analysData.report_status, analysData.is_case]);

  const handleCloseResultsDialog = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(ROUTES.ANALYS_LIST.URL);
    }
  };

  const handleCloseCreditsReportDialog = () => {
    setShouldShowCreditsReportDialog(false);
  };

  return (
    <FullSizeDialog
      title={messages.label_report}
      onClose={handleCloseResultsDialog}
    >
      {isLoading && (
        <Div minHeight="100vh" width={1}>
          <ProgressSpinner />
        </Div>
      )}

      {shouldShowCreditsReportDialog && (
        <CreditsReportDialog
          reportId={reportId}
          objectData={objectData}
          onClose={handleCloseCreditsReportDialog}
        />
      )}

      {isCaseReportDialogVisible && (
        <CaseReport report={analysData} onClose={handleCloseResultsDialog} />
      )}

      {analysData.order_id && (
        <AnalysDetails
          isAdmin
          analysData={analysData}
          cardData={orderData}
          fetchReportDetails={fetchReportDetails}
        />
      )}
    </FullSizeDialog>
  );
};

export default AdminAnalysDetails;
