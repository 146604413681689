import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import ActivityLog from "@components/ActivityLog";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import { TextMediumWeight } from "@components/Text";
import SubscriptionPackages from "@components/SubscriptionPackages";

const SubscriptionCard = ({
  activePackage,
  activityLogs,
  userInfo,
  isAdmin = false,
  onCreateSubscription,
  terminated,
}) => {
  const { messages } = useIntl();

  const [pageSectionShow, setPageSectionShow] = useState(false);

  const subscriptionListLoaded = () => {
    setPageSectionShow(true);
  };

  return (
    <Div>
      <H2 mb={"20px"} display={["flex", "flex", "flex", "none"]}>
        {messages.subscriptions_title}
      </H2>
      {terminated && (
        <TextMediumWeight my={"16px"} display="flex">
          {messages.your_subscription_is_termonated_now}
        </TextMediumWeight>
      )}
      <Div display="flex" flexWrap="wrap">
        {!isAdmin && (
          <SubscriptionPackages
            userInfo={userInfo}
            activePackage={activePackage}
            subscriptionListLoaded={subscriptionListLoaded}
            pageSectionShow={pageSectionShow}
            onCreateSubscription={onCreateSubscription}
          />
        )}
        {isAdmin && <ActivityLog activityLogs={activityLogs} />}
      </Div>
    </Div>
  );
};

SubscriptionCard.propTypes = {
  activePackage: PropTypes.object,
  activityLogs: PropTypes.array,
  userInfo: PropTypes.object,
  isAdmin: PropTypes.bool,
  terminated: PropTypes.bool,
  onCreateSubscription: PropTypes.func,
};

export default SubscriptionCard;
