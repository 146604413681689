import React from "react";

import Div from "@components/Div";
import GDPRInformationMessage from "@components/privacyPolicies/GDPRInformationMessage";
import PrivacyPolicyFooter from "@components/PrivacyPolicyFooter";

const LoginPrivacyFooter = () => (
  <Div display="flex" flexDirection="column">
    <GDPRInformationMessage />
    <PrivacyPolicyFooter />
  </Div>
);

export default LoginPrivacyFooter;
