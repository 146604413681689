import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import JsonForm from "@components/JsonForm/JsonForm";
import ProgressSpinner from "@components/ProgressSpinner";

import CardWrapper from "@pages/admin/users/components/CardWrapper";

import useCreateService from "./useCreateService";

const CreateServiceDialog = ({ onClose }) => {
  const {
    config: { productInformation, descInformation, configurationInformation },
    isLoading,
    handleCreate,
  } = useCreateService();
  const { messages } = useIntl();

  const initialValues = {
    service_type: "",
    analysis_type: "",
    data_source: [],
    child: [],
    name: "",
    price: "",
    delivery_time: "",
    listing_order: null,
    sku: "",
    description: "",
    default_selected: false,
    default_available: false,
    purchase_by_credits: false,
    price_credits: 0,
    state: "active",
    type: "service",
  };

  const formLayout = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gridColumnGap: 2,
  };

  const cardsConfig = [
    {
      title: messages.label_details,
      config: productInformation,
    },
    {
      title: messages.title_product_description,
      config: descInformation,
    },
    {
      title: messages.title_configuration,
      config: configurationInformation,
    },
  ];

  const handleSubmitForm = values => {
    handleCreate(values, onClose);
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog title={messages.title_create_service} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
          enableReinitialize
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Div maxWidth="1110px">
              <Form>
                {cardsConfig.map(({ title, config }) => (
                  <CardWrapper key={title} title={title}>
                    <JsonForm config={config} formLayout={formLayout} />
                  </CardWrapper>
                ))}
 
                <Div
                  display="flex"
                  flexDirection={["column", "column", "row", "row"]}
                  alignItems="center"
                  justifyContent="center"
                  gridGap={4}
                  mt={4}
                >
                  <PrimaryButtonOutlined
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_cancel}
                    onClick={onClose}
                  />
                  <PrimaryButton
                    width={[1, 1, "150px", "150px"]}
                    label={messages.label_save}
                    type="submit"
                    disabled={!dirty || isSubmitting || !isValid}
                  />
                </Div>
              </Form>
            </Div>
          )}
        </Formik>
      </FullSizeDialog>
    </Div>
  );
};

CreateServiceDialog.propTypes = {
  onClose: PropTypes.func,
};

export default CreateServiceDialog;
