import { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import createNewProduct from "@app/services/services/createNewProduct";
import getDataSources from "@app/services/products/getDataSources";

import { useToast } from "@hooks/useToast";

import { ANALYSIS, BUNDLE } from "@utils/constant";

const ANALYSIS_TYPE_MANUAL = "manual";

const prepareCreateServicePayload = values => {
  const { service_type, data_source, analysis_type, child, ...restValues } =
    values;
  const payload = { ...restValues };

  if (service_type === BUNDLE) {
    payload.child = child.map(o => o.code);
  } else if (service_type === ANALYSIS) {
    payload.data_source = data_source.map(o => o.code);
    payload.analysis_type = analysis_type;
  } else {
    payload.analysis_type = ANALYSIS_TYPE_MANUAL;
  }

  return payload;
};

const useCreateService = () => {
  const { messages } = useIntl();
  const { showErrorToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceTypes, setDataSourceTypes] = useState([]);

  useEffect(() => {
    const fetchDataSources = async () => {
      setIsLoading(true);

      try {
        const { data: dataSources = [] } = await getDataSources();
        const formattedDataSources = dataSources.map(dataSource => ({
          name: messages[`datasource_${dataSource}`],
          code: dataSource,
        }));

        setDataSourceTypes(formattedDataSources);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDataSources();
  }, [messages, showErrorToast]);

  const handleCreateService = async (values, onCreate) => {
    try {
      setIsLoading(true);

      const payload = prepareCreateServicePayload(values);

      await createNewProduct(payload);

      onCreate?.();
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    }
    setIsLoading(false);
  };

  return { isLoading, dataSourceTypes, handleCreate: handleCreateService };
};

export default useCreateService;
