export const CustomerPermissions = {
  CustomerWriteCompanyInformation: "write-company-information",
  CustomerReadCompanyInformation: "read-company-information",
  CustomerWriteOrders: "write-orders",
  CustomerReadOrders: "read-orders",
  CustomerWriteUsers: "write-users",
  CustomerReadUsers: "read-users",
  CustomerReadSelfOrders: "read-self-orders",
  CustomerWriteSubscription: "write-subscription",
  CustomerReadSubscription: "read-subscription",
  CustomerWriteWatchLists: "write-watch-lists",
  CustomerReadWatchLists: "read-watch-lists",
  CustomerGradesReportAnalysis: "grades-report-analysis",
  CustomerReadReportAnalysis: "read-report-analysis",
  CustomerReadPrices: "read-prices",
  CustomerReadSelfReportsAnalysis: "read-self-report-analysis",
  CustomerDownloadReport: "download-report-analysis",
};

export const AdminPermissions = {
  AdminWriteCustomers: "admin-write-customers",
  AdminReadCustomers: "admin-read-customers",
  AdminDeleteCustomers: "admin-delete-customers",
  AdminWriteOrders: "admin-write-orders",
  AdminReadOrders: "admin-read-orders",
  AdminDeleteOrders: "admin-delete-orders",
  AdminWriteAssignments: "admin-write-assignments",
  AdminReadAssignments: "admin-read-assignments",
  AdminWriteReportAnalysis: "admin-write-report-analysis",
  AdminReadReportAnalysis: "admin-read-report-analysis",
  AdminWriteUsers: "admin-write-admin-users",
  AdminReadUsers: "admin-read-admin-users",
  AdminWriteSubscription: "admin-write-subscription",
  AdminReadSubscription: "admin-read-subscription",
  AdminWriteWatchLists: "admin-write-watch-lists",
  AdminReadWatchLists: "admin-read-watch-lists",
  AdminReadProducts: "admin-read-products",
  AdminWriteProducts: "admin-write-products",
  AdminDeleteProducts: "admin-delete-products",
  AdminReadBilling: "admin-read-billing",
  AdminWriteBilling: "admin-write-billing",
  AdminReadFormConfig: "admin-read-form-config",
  AdminWriteFormConfig: "admin-write-form-config",
  AdminReadReportConfig: "admin-read-report-config",
  AdminWriteReportConfig: "admin-write-report-config",
  AdminReadRules: "admin-read-rules",
  AdminWriteRules: "admin-write-rules",
  AdminReadCases: "admin-read-cases",
  AdminWriteCases: "admin-write-cases",
  AdminReadCasesActivityLog: "admin-read-cases-activity-log",
  AdminReadEmailLog: "admin-read-email-log",
  AdminReadDataSources: "admin-read-data-sources",
  AdminWriteDataSources: "admin-write-data-sources",
  AdminReadCrimes: "admin-read-crimes",
  AdminWriteCrimes: "admin-write-crimes",
  AdminReadUserRolesPermissions: "admin-read-user-roles-permissions",
  AdminWriteUserRolesPermissions: "admin-write-user-roles-permissions",
  AdminReadEmailAccounts: "admin-read-email-accounts",
  AdminWriteEmailAccounts: "admin-write-email-accounts",
  AdminReadCourts: "admin-read-courts",
  AdminWriteCourts: "admin-write-courts",
  AdminReadCustomersActivityLog: "admin-read-customers-activity-log",
  AdminWriteCustomerUsers: "admin-write-customer-users",
  AdminReadCustomerUsers: "admin-read-customer-users",
  AdminReadBlockedCandidates: "admin-read-blocked-candidates",
  AdminWriteBlockedCandidates: "admin-write-blocked-candidates",
  AdminReadActivityLog: "admin-read-configuration-cases-activity-log",
};
