import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { authActions } from "@app/reducers/authReducer";
import putUserService from "@app/services/users/putUserService";

import { ButtonLinkIcon } from "@components/Button";
import Card from "@components/Card";
import { default as Div } from "@components/Div";
import { H4 } from "@components/Heading";
import Icon from "@components/Icon";
import JsonFormDialog from "@components/JsonForm/JsonFormDialog";
import { Text } from "@components/Text";

import { useToast } from "@hooks/useToast";

import Password from "@src/schema/Password";

import MyProfileBankIdLoginDialog from "./MyProfileBankIdLoginDialog";
import MyProfileBankIdSuccessDialog from "./MyProfileBankIdSuccessDialog";
import MyProfileDisableBankIdDialog from "./MyProfileDisableBankIdDialog";

const INITIAL_VALUES = {
  changePassword: {
    old_password: "",
    password: "",
    confirm_password: "",
  },
};

const MyProfileAccountSecurityCard = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { id, ssn } = useSelector(state => state.authReducer.userInfo);
  const { showSuccessToast, showErrorToast } = useToast();

  const isBankIdLoginEnabled = ssn !== null;

  const [isPasswordDialogVisible, setIsPasswordDialogVisible] = useState(false);
  const [isSsnSubmitDialogVisible, setIsSsnSubmitDialogVisible] =
    useState(false);
  const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false);
  const [isDisableBankIdDialogVisible, setIsDisableBankIdDialogVisible] =
    useState(false);

  const handleOpenPasswordDialog = () => {
    setIsPasswordDialogVisible(true);
  };

  const handleClosePasswordDialog = () => {
    setIsPasswordDialogVisible(false);
  };

  const handleOpenSsnSubmitDialog = () => {
    setIsSsnSubmitDialogVisible(true);
  };

  const handleCloseSsnSubmitDialog = () => {
    setIsSsnSubmitDialogVisible(false);
  };

  const handleOpenSuccessDialog = () => {
    setIsSuccessDialogVisible(true);
  };

  const handleCloseSuccessDialog = () => {
    setIsSuccessDialogVisible(false);
  };

  const handleOpenDisableBankIDLoginDialog = () => {
    setIsDisableBankIdDialogVisible(true);
  };

  const handleCloseDisableBankIDLoginDialog = () => {
    setIsDisableBankIdDialogVisible(false);
  };

  const handleEditBankIdLogin = isBankIdLoginEnabled
    ? handleOpenDisableBankIDLoginDialog
    : handleOpenSsnSubmitDialog;

  const handlePasswordSubmit = async values => {
    try {
      await putUserService(id, values);

      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const handleBankIdLoginSubmit = async ({ ssn }) => {
    try {
      const payload = {
        ssn,
      };

      const { data: user } = await putUserService(id, payload);

      dispatch(authActions.storeUserInfo({ ...user }));

      handleOpenSuccessDialog();
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const handleDisableBankIdLogin = async () => {
    const payload = {
      ssn: null,
    };

    await handleBankIdLoginSubmit(payload);
  };

  const password = [
    {
      label: <FormattedMessage id="label_current_password" />,
      fieldType: "password",
      name: "old_password",
      placeholder: <FormattedMessage id="label_current_password" />,
      validation: Password.fields.current_password,
    },
    {
      label: <FormattedMessage id="label_new_password" />,
      fieldType: "password",
      name: "password",
      placeholder: <FormattedMessage id="label_new_password" />,
      validation: Password.fields.password,
    },
    {
      label: <FormattedMessage id="label_repeat_new_password" />,
      fieldType: "password",
      name: "confirm_password",
      placeholder: <FormattedMessage id="label_repeat_new_password" />,
      validation: Password.fields.confirm_password,
    },
  ];

  return (
    <>
      {isPasswordDialogVisible && (
        <JsonFormDialog
          title={messages.label_change_password}
          config={password}
          onHide={handleClosePasswordDialog}
          onSubmit={handlePasswordSubmit}
          INITIAL_VALUES={INITIAL_VALUES.changePassword}
        />
      )}

      {isSsnSubmitDialogVisible && (
        <MyProfileBankIdLoginDialog
          isBankIdLoginEnabled={isBankIdLoginEnabled}
          onClose={handleCloseSsnSubmitDialog}
          onSubmit={handleBankIdLoginSubmit}
        />
      )}

      {isSuccessDialogVisible && (
        <MyProfileBankIdSuccessDialog
          isDisableBankIdLogin={!isBankIdLoginEnabled}
          onClose={handleCloseSuccessDialog}
        />
      )}

      {isDisableBankIdDialogVisible && (
        <MyProfileDisableBankIdDialog
          onClose={handleCloseDisableBankIDLoginDialog}
          onSubmit={handleDisableBankIdLogin}
        />
      )}

      <Card p={3} borderRadius="20px" width={1}>
        <Div width={1} display="flex" flexDirection="column" gridGap="12px">
          <H4>{messages.title_account_security}</H4>

          <Div width={1} height={1} bg="var(--grey-lightest)" />

          <Div
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Div display="flex" flexDirection="column" gridGap={2}>
              <H4>{messages.label_password}</H4>
              <Text>{messages.message_set_strong_password}</Text>
            </Div>

            <ButtonLinkIcon
              label={messages.label_change_password}
              onClick={handleOpenPasswordDialog}
              icon={
                <Icon
                  name="padlock"
                  fontSize="var(--fs-icon-m)"
                  color="var(--turquoise)"
                  mr={1}
                />
              }
            />
          </Div>

          <Div width={1} height={1} bg="var(--grey-lightest)" />

          <Div
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Div display="flex" flexDirection="column" gridGap={2}>
              <H4 display="flex">
                {`${messages.label_bank_id_login}: `}
                <H4
                  ml={1}
                  color={`var(--${isBankIdLoginEnabled ? "turquoise" : "grey"}) !important`}
                >
                  {isBankIdLoginEnabled ? messages.on : messages.off}
                </H4>
              </H4>

              <Text>{messages.message_bank_id_login}</Text>
            </Div>

            <ButtonLinkIcon
              label={
                isBankIdLoginEnabled
                  ? messages.label_disable_bank_id
                  : messages.label_enable_bank_id
              }
              onClick={handleEditBankIdLogin}
              icon={
                <Icon
                  name="bankID"
                  fontSize="var(--fs-icon-l)"
                  color={`var(--${isBankIdLoginEnabled ? "blue-dark" : "grey"})`}
                  mr={2}
                />
              }
            />
          </Div>
        </Div>
      </Card>
    </>
  );
};

export default MyProfileAccountSecurityCard;
